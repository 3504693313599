export const data = {
  identifier: "shoes",
  subtitle: "BATERIA CALÇADOS",
  form_title: "CALÇADOS",
  number: "(10 SUBSTÂNCIAS)",
  initialShow: false,
   sensitizers: [
    {
      measurement_label: "SH1",
      patch_test_info_identifier:"shoes",      
    },
    {
      measurement_label: "SH2",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH3",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH4",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH5",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH6",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH7",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH8",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH9",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH10",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH11",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH12",
      patch_test_info_identifier:"shoes",
    },
    {
      measurement_label: "SH13",
      patch_test_info_identifier:"shoes",
    },
  ],
};

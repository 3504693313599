import React from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updatePatient } from "../../store/patients-actions";
import Modal from "react-bootstrap/Modal";
import { formatDatefromBr } from "../../util/stringFormaters";
import PacientForm from "./PatientForm";
import { patientsActions } from "../../store/patients-slice";

function PacientFormEdit() {
  const dispatch = useDispatch();
  const showEditForm = useSelector((state) => state.patients.showEditForm);
  const patient_to_edit = useSelector(
    (state) => state.patients.patient_to_edit
  );
  const token = useSelector((state) => state.login.token);

  function handleSubmit(event) {
    event.preventDefault();
    const updated_patient = {
      name: event.target.name.value,
      birthday: formatDatefromBr(event.target.birthday.value),
      cpf: event.target.cpf.value,
      weight: event.target.weight.value,
      height: event.target.height.value,
      gender: event.target.gender.value,
      origin: event.target.origin.value,
      contact: event.target.contact.value,
      responsable_name: event.target.responsable_name.value,
      responsable_degree: event.target.responsable_degree.value,
      obs: event.target.obs.value,
    };
    dispatch(updatePatient(updated_patient, patient_to_edit.id, token));
  }
  const enableEscape = true;
  return (
    <Modal
      animation={true}
      show={showEditForm}
      onHide={() => dispatch(patientsActions.setShowEditForm(false))}
      backdrop={"static"}
      keyboard={enableEscape}
    >
      <Modal.Header>
        <Modal.Title>Atualização de Cadastro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <PacientForm patient={patient_to_edit} />
          <Modal.Footer>
            <Button
              onClick={() => dispatch(patientsActions.setShowEditForm(false))}
            >
              Cancelar
            </Button>
            <Button type="submit">Atualizar Cadastro</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default PacientFormEdit;

import { createSlice } from "@reduxjs/toolkit";
import { prick_data } from "../data/SolicitacaoData/DATA_Solicitacao";
import { lab_options as lab_options_data } from "../data/SolicitacaoData/DATA_Solicitacao";
import {
  patch_data,
  patch_custom,
  tic_data,
} from "../data/SolicitacaoData/DATA_Solicitacao";
import { espirometria_options } from "../data/SolicitacaoData/DATA_Solicitacao";

const solicitacaoSlice = createSlice({
  name: "solicitacao",
  initialState: {
    all_solicitacoes: [],
    name: "",
    lab_options: lab_options_data.map((lab_option_name) => ({
      name: lab_option_name,
      selected: false,
    })),
    lab_search: "",
    lab_new: "",
    others: [],
    others_new: "",
    prick_enable: false,
    prick_option_selected: [],
    prick_data: prick_data,
    patch_enable: false,
    patch_option_selected: [],
    patch_data: patch_data,
    patch_custom: patch_custom,
    espirometria_enable: false,
    espirometria_options: espirometria_options,
    espirometria_new: "",
    tic_enable: false,
    tic_option_selected: [],
    tic_data: tic_data,
    enable_cutaneo_medicamentos: false,
    cutaneo_medicamentos: "",
    enable_oral_medicamentos: false,
    oral_medicamentos: "",
    enable_injetavel_medicamentos: false,
    injetavel_medicamentos: "",
    enable_oral_alimentos: false,
    oral_alimentos: "",
    date: "",
    form_data: null,
    show_report: false,
    need_download: false,
    need_fetch: true,
    selected_order: [],
  },
  reducers: {
    setAllSolicitacoes(state, action) {
      state.all_solicitacoes = action.payload;
      state.need_fetch = false;
    },
    setNeedFetch(state, action) {
      state.need_fetch = action.payload;
    },
    addOneSolicitacao(state, action) {
      state.all_solicitacoes.unshift(action.payload);
    },
    removeOneSolicitacao(state, action) {
      const idx = state.all_solicitacoes.findIndex(
        (solicitacao) => solicitacao.id === action.payload.id
      );
      state.all_solicitacoes.splice(idx, 1);
    },
    setName(state, action) {
      state.name = action.payload;
    },
    deselectLabOption(state, action) {
      state.lab_options.find(
        (option) => option.name === action.payload.name
      ).selected = false;
    },
    selectLabOption(state, action) {
      state.lab_options.find(
        (option) => option.name === action.payload.name
      ).selected = true;
    },
    searchLabOption(state, action) {
      state.lab_search = action.payload;
    },
    typeNewLab(state, action) {
      state.lab_new = action.payload;
    },
    addNewLab(state) {
      state.lab_options.push({
        name: state.lab_new,
        selected: true,
      });
      state.lab_new = "";
    },
    typeNewOthers(state, action) {
      state.others_new = action.payload;
    },
    addNewOther(state) {
      state.others.push(state.others_new);
      state.others_new = "";
    },
    removeOther(state, action) {
      const index = state.others.findIndex(
        (element) => element === action.payload
      );
      state.others.splice(index, 1);
    },
    enablePrick(state, action) {
      state.prick_enable = action.payload;
    },
    setPrickOption(state, action) {
      state.prick_option_selected = action.payload;
      const payload_identifiers = action.payload.prick_elements;

      state.prick_data.forEach((group) =>
        group.elements.forEach((element) => {
          if (payload_identifiers.includes(element.identifier))
            element.active = true;
          else element.active = false;
        })
      );
    },
    togglePrickActive(state, action) {
      state.prick_data.forEach((group) =>
        group.elements.forEach((element) => {
          if (element.identifier === action.payload)
            element.active = !element.active;
        })
      );
    },
    activatePrick(state, action) {
      state.prick_data.forEach((group) =>
        group.elements.forEach((element) => {
          if (element.identifier === action.payload) element.active = true;
        })
      );
    },
    deactivatePrick(state, action) {
      state.prick_data.forEach((group) =>
        group.elements.forEach((element) => {
          if (element.identifier === action.payload) element.active = false;
        })
      );
    },
    enablePatch(state, action) {
      state.patch_enable = action.payload;
      state.patch_data = patch_data;
    },
    setPatchOption(state, action) {
      state.patch_option_selected = action.payload;
    },
    togglePatchActive(state, action) {
      state.patch_data.forEach((data) => {
        if (data.test_name === action.payload.test_name) {
          data.active = !data.active;
        }
      });
    },
    toggleCustomPatchActive(state, action) {
      state.patch_custom.active = !state.patch_custom.active;
    },
    setCustomPatchSubstances(state, action) {
      state.patch_custom.substances = action.payload;
    },
    setCustomPatchName(state, action) {
      state.patch_custom.test_name = action.payload;
    },
    enableEspirometria(state, action) {
      state.espirometria_enable = action.payload;
    },
    toggleEspirometriaActive(state, action) {
      state.espirometria_options.to_patient.forEach((one_option, index) => {
        if (one_option.text === action.payload.text) {
          one_option.active = !action.payload.active;
          if (one_option.fixed !== true && one_option.active === false) {
            state.espirometria_options.to_patient.splice(index, 1);
          }
        }
      });
    },
    typeNewEspirometria(state, action) {
      state.espirometria_new = action.payload;
    },
    addNewEspirometria(state) {
      if (
        state.espirometria_new.slice(-1) === "," ||
        state.espirometria_new.slice(-1) === "." ||
        state.espirometria_new.slice(-1) === ";"
      ) {
        state.espirometria_new = state.espirometria_new.slice(0, -1);
      }
      state.espirometria_new = state.espirometria_new + ".";

      state.espirometria_options.to_patient.push({
        text: state.espirometria_new,
        active: true,
      });
      state.espirometria_new = "";
    },
    enableTIC(state, action) {
      state.tic_enable = action.payload;
      state.tic_option_selected = tic_data[0];
    },
    setTICOption(state, action) {
      state.tic_option_selected = action.payload;
    },
    toggleTICOptionActive(state, action) {
      state.tic_option_selected.options.forEach((option) =>
        option.elements.forEach((element) => {
          if (element.name === action.payload) {
            element.active = !element.active;
          }
        })
      );
    },
    enableCutaneoMedicamentos(state, action) {
      state.enable_cutaneo_medicamentos = action.payload.enable;
      if (state.enable_cutaneo_medicamentos) {
        state.cutaneo_medicamentos = action.payload.cutaneo_medicamentos;
      }
    },
    setCutaneoMedicamentos(state, action) {
      state.cutaneo_medicamentos.drugs = action.payload;
    },
    enableOralMedicamentos(state, action) {
      state.enable_oral_medicamentos = action.payload.enable;
      if (state.enable_oral_medicamentos) {
        state.oral_medicamentos = action.payload.oral_medicamentos;
      }
    },
    setOralMedicamentos(state, action) {
      state.oral_medicamentos.drugs = action.payload;
    },
    enableInjetavelMedicamentos(state, action) {
      state.enable_injetavel_medicamentos = action.payload.enable;
      if (state.enable_injetavel_medicamentos) {
        state.injetavel_medicamentos = action.payload.injetavel_medicamentos;
      }
    },
    setInjetavelMedicamentos(state, action) {
      state.injetavel_medicamentos.drugs = action.payload;
    },
    enableOralAlimentos(state, action) {
      state.enable_oral_alimentos = action.payload.enable;
      if (state.enable_oral_alimentos) {
        state.oral_alimentos = action.payload.oral_alimentos;
      }
    },
    setOralAlimentos(state, action) {
      state.oral_alimentos.drugs = action.payload;
    },
    setDate(state, action) {
      state.date = action.payload;
    },
    submitForm(state) {
      state.form_data = {
        name: state.name,
        labs:
          state.lab_options.filter((element) => element.selected === true)
            .length > 0 &&
          state.lab_options
            .filter((element) => element.selected === true)
            .map((element) => element.name),
        others: state.others.length > 0 && state.others,
        pricks_option: state.prick_enable && state.prick_option_selected.name,
        prick_tests: state.prick_enable
          ? state.prick_data.reduce((acc, curr) => {
              return acc.concat(
                curr.elements
                  .filter((element) => element.active === true)
                  .map((element) => element.identifier)
              );
            }, [])
          : [],
        patch_option: state.patch_enable && state.patch_option_selected,
        patch_tests: state.patch_enable
          ? state.patch_data
              .filter((test) => test.active === true)
              .map((test) => test.identifier)
          : [],
        patch_custom: state.patch_enable &&
          state.patch_custom.active && {
            test_name: state.patch_custom.test_name,
            substances: state.patch_custom.substances,
          },
        espirometria: state.espirometria_enable && {
          test_name: state.espirometria_options.test_name,
          text: state.espirometria_options.text,
          to_patient: state.espirometria_options.to_patient
            .filter((element) => element.active === true)
            .map((element) => element.text),
        },
        tic: state.tic_enable && {
          type: state.tic_option_selected.type,
          groups: state.tic_option_selected.options
            .map((option) => ({
              group_name: option.group_name,
              elements: option.elements
                .filter((element) => element.active === true)
                .map((element) => element.name),
            }))
            .filter((option) => option.elements.length > 0),
        },
        cutaneo_medicamentos: state.enable_cutaneo_medicamentos && {
          text: state.cutaneo_medicamentos.text,
          drugs: state.cutaneo_medicamentos.drugs,
        },
        oral_medicamentos: state.enable_oral_medicamentos && {
          text: state.oral_medicamentos.text,
          drugs: state.oral_medicamentos.drugs,
        },
        injetavel_medicamentos: state.enable_injetavel_medicamentos && {
          text: state.injetavel_medicamentos.text,
          drugs: state.injetavel_medicamentos.drugs,
        },
        oral_alimentos: state.enable_oral_alimentos && {
          text: state.oral_alimentos.text,
          drugs: state.oral_alimentos.drugs,
        },
        date: state.date,
      };
    },
    hideReport(state) {
      state.show_report = false;
    },
    needDownload(state, action) {
      state.need_download = action.payload;
    },
    selectOrder(state, action) {
      state.selected_order = action.payload;
    },
  },
});

export const solicitacaoActions = solicitacaoSlice.actions;

export default solicitacaoSlice;

import { createSlice } from "@reduxjs/toolkit";

const clinicSlice = createSlice({
  name: "clinic",
  initialState: {
    clinics: [],
    selected_clinic: null,
    need_fetch: true,
  },
  reducers: {
    setAllClinics(state, action) {
      state.clinics = action.payload;
      state.need_fetch = false;
    },
    selectClinic(state, action) {
      state.selected_clinic = state.clinics.find(
        (clinic) => clinic.id === action.payload
      );
    },
  },
});

export const clinicActions = clinicSlice.actions;

export default clinicSlice;

import { uiActions } from "./ui-slice";
import { loginActions } from "./login-slice";

const API_URL_LOGIN = process.env.REACT_APP_API_URL + "/login";

export const login = (user) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviando...",
        message: "Realizando Login!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_LOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.status === 401) {
        throw new Error("Não Autorizado. Verifique email e senha.");
      } else if (!response.ok) {
        throw new Error("Falha ao realizar login.");
      }

      localStorage.setItem(
        "token",
        response.headers.get("Authorization").split(" ")[1]
      );
      dispatch(
        loginActions.setToken(
          response.headers.get("Authorization").split(" ")[1]
        )
      );

      const data = await response.json();

      return data;
    };

    try {
      await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Login realizado com sucesso!",
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: error.message,
        })
      );
      dispatch(loginActions.setToken(""));
      localStorage.removeItem("token");
    }
  };
};

export const logout = (token) => {
  return async (dispatch) => {
    dispatch(loginActions.setToken(""));
    localStorage.removeItem("token");
  };
};

export const data = {
  identifier: "pediatrician",
  subtitle: "BATERIA PEDIÁTRICA",
  form_title: "PEDIÁTRICA",
  number: "(20 SUBSTÂNCIAS)",
  initialShow: false,
  sensitizers: [
    {
      measurement_label: "PD1",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD2",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD3",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD4",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD5",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD6",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD7",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD8",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD9",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD10",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD11",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD12",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD13",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD14",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD15",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD16",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD17",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD18",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD19",
      patch_test_info_identifier: "pediatrician",
    },
    {
      measurement_label: "PD20",
      patch_test_info_identifier: "pediatrician",
    },
  ],
};

export const data = {
  identifier: "antiflamatorios",
  subtitle: "BATERIA ANTI-INFLAMATÓRIOS",
  form_title: "ANTI-INFLAMATÓRIOS",
  number: "(10 SUBSTÂNCIAS)",
  initialShow: false,
  sensitizers: [
    {
      measurement_label: "AI1",
      patch_test_info_identifier: "antiflamatorios",
    },
    {
      measurement_label: "AI2",
      patch_test_info_identifier: "antiflamatorios",
    },
    {
      measurement_label: "AI3",
      patch_test_info_identifier: "antiflamatorios",
    },
    {
      measurement_label: "AI4",
      patch_test_info_identifier: "antiflamatorios",
    },
    {
      measurement_label: "AI5",
      patch_test_info_identifier: "antiflamatorios",
    },
    {
      measurement_label: "AI6",
      patch_test_info_identifier: "antiflamatorios",
    },
    {
      measurement_label: "AI7",
      patch_test_info_identifier: "antiflamatorios",
    },
    {
      measurement_label: "AI8",
      patch_test_info_identifier: "antiflamatorios",
    },
    {
      measurement_label: "AI9",
      patch_test_info_identifier: "antiflamatorios",
    },
    {
      measurement_label: "AI10",
      patch_test_info_identifier: "antiflamatorios",
    },
  ],
};

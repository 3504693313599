import classes from "./Forms.module.css";
import HeaderInput from "../header_footer/HeaderInput";
import ExamControl from "./ExamControl";
import OneForm from "./OneForm";
import CommentsInput from "./CommentsInput";
import { useSelector } from "react-redux";
import PrintedInput from "./PrintedInput";

function Forms() {
  const prick_data = useSelector((state) => state.prick.prick_data);
  return (
    <div className={classes.container}>
      <HeaderInput />
      <ExamControl />
      {prick_data.map((data, index) => (
        <OneForm data={data} key={index} />
      ))}
      <PrintedInput />
      <CommentsInput />
    </div>
  );
}

export default Forms;

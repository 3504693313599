import MeasurementsForm from "./MeasurementsForm";
import { useSelector } from "react-redux";
import classes from "./Forms.module.css";

function Forms(props) {
  const patch_selector = useSelector((state) => state.allergens.patch_selector);

  return (
    <div className={classes.container}>
      {patch_selector.map((data, index) => (
        <MeasurementsForm test_data={data} key={index} />
      ))}
    </div>
  );
}

export default Forms;

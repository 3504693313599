import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { patientsActions } from "../../store/patients-slice";
import {
  fetchAllPatients,
  deleteOnePatient,
} from "../../store/patients-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faPenToSquare,
  faUserPlus,
  faRefresh,
  faEraser,
} from "@fortawesome/free-solid-svg-icons";
import { confirm } from "../../util/Confirmation";
import classes from "./SelectPatient.module.css";
import { formatCPF } from "../../util/stringFormaters";

function SelectPatient() {
  const dispatch = useDispatch();
  const all_patients = useSelector((state) => state.patients.all_patients);
  const need_fetch = useSelector((state) => state.patients.need_fetch);
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );
  const token = useSelector((state) => state.login.token);
  const name_typing = useSelector((state) => state.patients.name_typing);
  const cpf_typing = useSelector((state) => state.patients.cpf_typing);

  useEffect(() => {
    if (need_fetch) {
      dispatch(fetchAllPatients(token));
    }
  }, [dispatch, need_fetch, token]);

  function filter_patient_search(array, name, cpf) {
    const query_name = name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    const query_cpf = cpf.replace(/\D/g, "");

    return array.filter(
      (element) =>
        element.name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(query_name) &&
        element.cpf.replace(/\D/g, "").includes(query_cpf)
    );
  }

  const handleDelete = async (patient) => {
    if (await confirm("Tem certeza que deseja excluir esse cadastro?")) {
      dispatch(deleteOnePatient(patient, token));
    }
  };

  return (
    <div className="container w-75 my-3 py-3 border rounded ">
      <div className="d-flex flex-wrap gap-2">
        <FontAwesomeIcon
          className="btn"
          icon={faUserPlus}
          onClick={() => dispatch(patientsActions.setShowNewForm(true))}
        />
        <Form.Label>Pesquisar:</Form.Label>
        <Form.Control
          type="text"
          id="patient_name_search"
          placeholder="Nome"
          className={classes.input_text}
          onChange={(e) => {
            dispatch(patientsActions.typeName(e.target.value));
          }}
          value={name_typing}
        ></Form.Control>
        <Form.Control
          type="text"
          id="patient_cpf_search"
          placeholder="CPF"
          className={classes.input_text}
          onChange={(e) => {
            dispatch(patientsActions.typeCPF(formatCPF(e.target.value)));
          }}
          value={cpf_typing}
        ></Form.Control>
        <div className="d-flex">
          <FontAwesomeIcon
            className="btn"
            icon={faEraser}
            title="Apagar"
            onClick={() => {
              dispatch(patientsActions.typeCPF(""));
              dispatch(patientsActions.typeName(""));
            }}
          />
          <FontAwesomeIcon
            className="btn"
            icon={faRefresh}
            title="Atualizar"
            onClick={() => {
              dispatch(fetchAllPatients(token));
            }}
          />
        </div>
      </div>
      <div className={`my-3 ${classes.scroll}`}>
        <div className="list-group">
          {all_patients.length > 0 &&
            filter_patient_search(all_patients, name_typing, cpf_typing).map(
              (one_patient, index) => (
                <div
                  key={index}
                  className={`list-group-item list-group-item-action btn d-flex ${
                    selected_patient.id === one_patient.id && "active z-0"
                  }`}
                  onClick={() => {
                    dispatch(patientsActions.selectPatient(one_patient));
                  }}
                >
                  <div className="col">{one_patient.name}</div>
                  <div className="col">{one_patient.cpf}</div>
                  <div className="d-flex flex-wrap">
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      title="Editar"
                      onClick={() => {
                        dispatch(patientsActions.setPacientToEdit(one_patient));
                        dispatch(patientsActions.setShowEditForm(true));
                      }}
                      className="btn"
                    />
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      title="Excluir"
                      onClick={() => handleDelete(one_patient)}
                      className="btn"
                    />
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </div>
  );
}

export default SelectPatient;

import React, { useEffect, useState } from "react";
import classes from "./PrintedInput.module.css";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

function PrintedInput() {
  const prick_form_selected = useSelector(
    (state) => state.prick.prick_form_selected
  );
  const [stateEnvironmentalControl, setEnvironmentalControl] = useState(false);
  const [stateEczema, setEczema] = useState(false);
  const [stateDaDac, setDaDac] = useState(false);

  useEffect(() => {
    setEnvironmentalControl(
      prick_form_selected ? prick_form_selected.environmental_control : false
    );
    setEczema(prick_form_selected ? prick_form_selected.eczema : false);
    setDaDac(prick_form_selected ? prick_form_selected.da_dac : false);
  }, [prick_form_selected, setEnvironmentalControl, setEczema, setDaDac]);

  return (
    <div className={classes.printed}>
      <Form.Check
        type="checkbox"
        id="prick_environment_control"
        label="Controle Ambiental"
        checked={stateEnvironmentalControl}
        onChange={(e) => {
          setEnvironmentalControl(e.target.checked);
        }}
      />
      <Form.Check
        type="checkbox"
        id="prick_advices_eczema"
        label="Controle de Eczema"
        checked={stateEczema}
        onChange={(e) => {
          setEczema(e.target.checked);
        }}
      />
      <Form.Check
        type="checkbox"
        id="prick_advices_da_dac"
        label="Orientações para DA e DAC"
        checked={stateDaDac}
        onChange={(e) => {
          setDaDac(e.target.checked);
        }}
      />
    </div>
  );
}

export default PrintedInput;

import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendNewTic, updateTic } from "../../store/tic-actions";
import classes from "./TicForm.module.css";

function TicForm(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const clinics = useSelector((state) => state.clinic.clinics);

  const [formData, setFormData] = useState({
    requester: "",
    application: "",
    reading: "",
    clinic_id: "",
    patient_id: "",
    positive_control1: "",
    positive_control2: "",
    negative_control1: "",
    negative_control2: "",
    cutibacterium_actes1: "",
    cutibacterium_actes2: "",
    micobacterium_bovis1: "",
    micobacterium_bovis2: "",
    candidina1: "",
    candidina2: "",
    tricofitina1: "",
    tricofitina2: "",
    toxoide_estafilococico1: "",
    toxoide_estafilococico2: "",
    toxoide_estreptococico1: "",
    toxoide_estreptococico2: "",
    bncb_005_1: "",
    bncb_005_2: "",
    bncb_2_1: "",
    bncb_2_2: "",
    comments: "",
    signature_date: "",
  });

  useEffect(() => {
    setFormData({
      requester: (props.formData && props.formData.requester) || "",
      application: (props.formData && props.formData.application) || "",
      reading: (props.formData && props.formData.reading) || "",
      clinic_id: (props.formData && props.formData.clinic_id) || "",
      patient_id: (props.formData && props.formData.patient_id) || "",
      positive_control1:
        (props.formData && parseInt(props.formData.positive_control1)) || "",
      positive_control2:
        (props.formData && parseInt(props.formData.positive_control2)) || "",
      negative_control1:
        (props.formData && parseInt(props.formData.negative_control1)) || "",
      negative_control2:
        (props.formData && parseInt(props.formData.negative_control2)) || "",
      cutibacterium_actes1:
        (props.formData && parseInt(props.formData.cutibacterium_actes1)) ||
        "",
      cutibacterium_actes2:
        (props.formData && parseInt(props.formData.cutibacterium_actes2)) ||
        "",
      micobacterium_bovis1:
        (props.formData && parseInt(props.formData.micobacterium_bovis1)) || "",
      micobacterium_bovis2:
        (props.formData && parseInt(props.formData.micobacterium_bovis2)) || "",
      candidina1: (props.formData && parseInt(props.formData.candidina1)) || "",
      candidina2: (props.formData && parseInt(props.formData.candidina2)) || "",
      tricofitina1:
        (props.formData && parseInt(props.formData.tricofitina1)) || "",
      tricofitina2:
        (props.formData && parseInt(props.formData.tricofitina2)) || "",
      toxoide_estafilococico1:
        (props.formData && parseInt(props.formData.toxoide_estafilococico1)) ||
        "",
      toxoide_estafilococico2:
        (props.formData && parseInt(props.formData.toxoide_estafilococico2)) ||
        "",
      toxoide_estreptococico1:
        (props.formData && parseInt(props.formData.toxoide_estreptococico1)) ||
        "",
      toxoide_estreptococico2:
        (props.formData && parseInt(props.formData.toxoide_estreptococico2)) ||
        "",
      bncb_005_1: (props.formData && parseInt(props.formData.bncb_005_1)) || "",
      bncb_005_2: (props.formData && parseInt(props.formData.bncb_005_2)) || "",
      bncb_2_1: (props.formData && parseInt(props.formData.bncb_2_1)) || "",
      bncb_2_2: (props.formData && parseInt(props.formData.bncb_2_2)) || "",
      comments: (props.formData && props.formData.comments) || "",
      signature_date: (props.formData && props.formData.signature_date) || "",
    });
  }, [setFormData, props.formData]);

  useEffect(() => {
    if (formData.application !== "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        reading: new Date(
          new Date(formData.application).getTime() + 2 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .split("T")[0],
      }));
    }
  }, [setFormData, formData.application]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send the form data to your API or perform other actions here
    if (props.formData.id) {
      dispatch(updateTic(formData, props.formData.id, token));
    } else {
      dispatch(sendNewTic(formData, token));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container-sm">
      <div className="row mb-3">
        <div className="col mb-3">
          <label htmlFor="requester" className="form-label">
            Solicitante:
          </label>
          <input
            type="text"
            className="form-control"
            id="requester"
            name="requester"
            value={formData.requester}
            onChange={handleChange}
          />
        </div>
        <div className="col mb-3">
          <label htmlFor="application" className="form-label">
            Data Aplicação:
          </label>
          <input
            type="date"
            className="form-control"
            id="application"
            name="application"
            value={formData.application}
            onChange={handleChange}
          />
        </div>
        <div className="col mb-3">
          <label htmlFor="reading" className="form-label">
            Leitura:
          </label>
          <input
            type="date"
            className="form-control"
            id="reading"
            name="reading"
            value={formData.reading}
            onChange={handleChange}
          />
        </div>
        <div className="col mb-3">
          <label htmlFor="clinic_id" className="form-label">
            Clínica:
          </label>
          <select
            id="clinic_id"
            name="clinic_id"
            className="form-select"
            value={formData.clinic_id}
            onChange={(e) => {
              setFormData({
                ...formData,
                clinic_id: e.target.value, // Update clinic_id in formData
              });
            }}
          >
            <option value="" disabled>
              Selecione a Clínica
            </option>
            {clinics.map((clinic, index) => (
              <option key={index} value={clinic.id}>
                {clinic.acronym + " - " + clinic.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col mb-3">
          <label htmlFor="signature_date" className="form-label">
            Data de Assinatura:
          </label>
          <input
            type="date"
            className="form-control"
            id="signature_date"
            name="signature_date"
            value={formData.signature_date}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label className="col form-label">Controle Positivo:</label>
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="positive_control1"
          name="positive_control1"
          value={formData.positive_control1}
          onChange={handleChange}
        />
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="positive_control2"
          name="positive_control2"
          value={formData.positive_control2}
          onChange={handleChange}
        />
        <label className="col form-label">Controle Negativo:</label>
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="negative_control1"
          name="negative_control1"
          value={formData.negative_control1}
          onChange={handleChange}
        />

        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="negative_control2"
          name="negative_control2"
          value={formData.negative_control2}
          onChange={handleChange}
        />
      </div>
      <div className="row mb-3">
        <label className="col form-label">Cutibacterium actes:</label>
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="cutibacterium_actes1"
          name="cutibacterium_actes1"
          value={formData.cutibacterium_actes1}
          onChange={handleChange}
        />
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="cutibacterium_actes2"
          name="cutibacterium_actes2"
          value={formData.cutibacterium_actes2}
          onChange={handleChange}
        />
        <label className="col form-label">Micobacterium bovis:</label>
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="micobacterium_bovis1"
          name="micobacterium_bovis1"
          value={formData.micobacterium_bovis1}
          onChange={handleChange}
        />
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="micobacterium_bovis2"
          name="micobacterium_bovis2"
          value={formData.micobacterium_bovis2}
          onChange={handleChange}
        />
      </div>
      <div className="row mb-3">
        <label className="col form-label">Candidina:</label>
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="candidina1"
          name="candidina1"
          value={formData.candidina1}
          onChange={handleChange}
        />
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="candidina2"
          name="candidina2"
          value={formData.candidina2}
          onChange={handleChange}
        />

        <label className="col form-label">Tricofitina :</label>
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="tricofitina1"
          name="tricofitina1"
          value={formData.tricofitina1}
          onChange={handleChange}
        />
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="tricofitina2"
          name="tricofitina2"
          value={formData.tricofitina2}
          onChange={handleChange}
        />
      </div>
      <div className="row mb-3">
        <label className="col form-label">Toxoide Estafilococico :</label>
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="toxoide_estafilococico1"
          name="toxoide_estafilococico1"
          value={formData.toxoide_estafilococico1}
          onChange={handleChange}
        />
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="toxoide_estafilococico2"
          name="toxoide_estafilococico2"
          value={formData.toxoide_estafilococico2}
          onChange={handleChange}
        />
        <label className="col form-label">Toxoide Estreptococico:</label>
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="toxoide_estreptococico1"
          name="toxoide_estreptococico1"
          value={formData.toxoide_estreptococico1}
          onChange={handleChange}
        />
        <input
          type="number"
          step="1"
          className={`col form-control ${classes.input_field}`}
          id="toxoide_estreptococico2"
          name="toxoide_estreptococico2"
          value={formData.toxoide_estreptococico2}
          onChange={handleChange}
        />
      </div>
      <div className="row mb-3">
        <div className="col mb-3">
          <label htmlFor="bncb_005_1" className="form-label">
            BNCB (0,05%) 1ªL:
          </label>
          <input
            className="form-control"
            id="bncb_005_1"
            name="bncb_005_1"
            value={formData.bncb_005_1}
            onChange={handleChange}
          />
        </div>

        <div className="col mb-3">
          <label htmlFor="bncb_005_2" className="form-label">
            BNCB (0,05%) 2ªL:
          </label>
          <input
            className="form-control"
            id="bncb_005_2"
            name="bncb_005_2"
            value={formData.bncb_005_2}
            onChange={handleChange}
          />
        </div>

        <div className="col mb-3">
          <label htmlFor="bncb_2_1" className="form-label">
            BNCB (2%) 1ªL:
          </label>
          <input
            className="form-control"
            id="bncb_2_1"
            name="bncb_2_1"
            value={formData.bncb_2_1}
            onChange={handleChange}
          />
        </div>

        <div className="col mb-3">
          <label htmlFor="bncb_2_2" className="form-label">
            BNCB (2%) 2ªL:
          </label>
          <input
            className="form-control"
            id="bncb_2_2"
            name="bncb_2_2"
            value={formData.bncb_2_2}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col mb-3">
        <label htmlFor="comments" className="form-label">
          Comentários:
        </label>
        <textarea
          className="form-control"
          id="comments"
          name="comments"
          value={formData.comments}
          onChange={handleChange}
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Gerar Tic
      </button>
    </form>
  );
}

export default TicForm;

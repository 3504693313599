export const data = {
  identifier: "cosmetic",
  subtitle: "BATERIA COSMÉTICOS E HIGIENE PESSOAL",
  form_title: "COSMÉTICOS",
  number: "(10 SUBSTÂNCIAS)",
  initialShow: false,
  sensitizers: [
    {
      measurement_label: "C1",
      patch_test_info_identifier: "cosmetic",
    },
    {
      measurement_label: "C2",
      patch_test_info_identifier: "cosmetic",
    },
    {
      measurement_label: "C3",
      patch_test_info_identifier: "cosmetic",
    },
    {
      measurement_label: "C4",
      patch_test_info_identifier: "cosmetic",
    },
    {
      measurement_label: "C5",
      patch_test_info_identifier: "cosmetic",
    },
    {
      measurement_label: "C6",
      patch_test_info_identifier: "cosmetic",
    },
    {
      measurement_label: "C7",
      patch_test_info_identifier: "cosmetic",
    },
    {
      measurement_label: "C8",
      patch_test_info_identifier: "cosmetic",
    },
    {
      measurement_label: "C9",
      patch_test_info_identifier: "cosmetic",
    },
    {
      measurement_label: "C10",
      patch_test_info_identifier: "cosmetic",
    },
  ],
};

import classes from "./MeasurementsForm.module.css";
import SubstanceInput from "./SubstanceInput";
import sliceIntoChuncks from "../../../../util/sliceIntoChucks";

function MeasurementsForm({ test_data }) {
  const chunckArray = sliceIntoChuncks(test_data.sensitizers, 10);
  return (
    <div className={classes.card}>
      <div className={classes.title_container}>
        <p className={classes.title}>{test_data.form_title}</p>
      </div>
      <div className={classes.contensors_container}>
        {chunckArray.map((chunck, index) => (
          <div
            className={`${classes.contensor} ${
              chunck.length === 4 && classes.four_items
            }`}
            key={index}
          >
            {chunck.map((sensitizer_data, index) => (
              <SubstanceInput
                test_identifier={test_data.identifier}
                measurement_label={sensitizer_data.measurement_label}
                key={index}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MeasurementsForm;

import Form from "react-bootstrap/Form";
import DateInput from "../AllergensComponents/PatchTestComponents/header_footer/DateInput";
import classes from "./PatientFormMinimum.module.css";
import { formatDatetoBr, formatCPF } from "../../util/stringFormaters";
import { useDispatch, useSelector } from "react-redux";
import { patientsActions } from "../../store/patients-slice";
import { useEffect } from "react";

function PacientFormMinimum({ patient }) {
  const cpf_typing = useSelector((state) => state.patients.cpf_typing);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(patientsActions.typeCPF(patient.cpf));
    dispatch(patientsActions.typePhone(patient.contact));
  }, [dispatch, patient]);

  return (
    <>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="name">Nome:</Form.Label>
          <Form.Control
            type="text"
            id="name"
            placeholder="Nome"
            defaultValue={patient.name}
            required
          ></Form.Control>
        </div>
      </div>
      <div className={classes.row}>
        <DateInput
          id="birthday"
          label="Data de Nascimento:"
          default_value={formatDatetoBr(
            patient.birthday === null ? "" : patient.birthday
          )}
        />
        <div>
          <Form.Label htmlFor="cpf">CPF:</Form.Label>
          <Form.Control
            className={classes.cpf}
            type="text"
            id="cpf"
            placeholder="CPF"
            value={formatCPF(cpf_typing)}
            onChange={(e) => {
              dispatch(patientsActions.typeCPF(e.target.value));
            }}
            required
          ></Form.Control>
        </div>
      </div>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="weight">Peso (kg):</Form.Label>
          <Form.Control
            className={classes.weight}
            type="number"
            id="weight"
            step={0.01}
            defaultValue={patient.weight}
          />
        </div>
        <div>
          <Form.Label htmlFor="height">Altura (m):</Form.Label>
          <Form.Control
            className={classes.height}
            type="number"
            id="height"
            step={0.01}
            defaultValue={patient.height}
          />
        </div>
      </div>
    </>
  );
}

export default PacientFormMinimum;

import { useEffect, useState } from "react";
import classes from "./InputCheckDiv.module.css";
import Form from "react-bootstrap/Form";

function InputCheckDiv({ element, label, identifier, defaultValue = false }) {
  const [stateCheckDiv, setCheckDiv] = useState(defaultValue);

  useEffect(() => {
    if (!element.active) {
      setCheckDiv(false);
    }
    if (defaultValue) {
      setCheckDiv(defaultValue);
    }
  }, [element.active, defaultValue]);

  function checkCheckDiv() {
    if (element.active) {
      setCheckDiv((oldState) => {
        return !oldState;
      });
    }
  }

  return (
    <div className={`${classes.container} ${stateCheckDiv && classes.checked}`}>
      <div onClick={checkCheckDiv}>{label}</div>
      <Form.Check
        className={classes.checkbox}
        type="checkbox"
        label={label}
        id={`prick_${element.identifier}_${identifier}`}
        checked={stateCheckDiv}
        onChange={() => false}
      />
    </div>
  );
}

export default InputCheckDiv;

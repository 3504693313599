import Form from "react-bootstrap/Form";
import classes from "./InputSubstance.module.css";
import { useState, useEffect } from "react";
import InputCheckDiv from "./InputCheckDiv";
import ActivateAllergen from "./ActivateAllergen";
import { useSelector } from "react-redux";

function InputSubstance({ element, defaultValue, title }) {
  const [stateActive, setActive] = useState(element.active);
  const [stateSecondNumber, setSecondNumber] = useState(defaultValue);
  const [stateFirstNumber, setFirstNumber] = useState(defaultValue);

  const prick_measurements = useSelector(
    (state) => state.prick.prick_measurements
  );
  const loaded_measurement = prick_measurements.find(
    (measurement) => measurement.identifier === element.identifier
  );

  function onChangeActiveHandler(activeStatus) {
    element.active = activeStatus;
    setActive(activeStatus);
  }

  function firstNumberHandler(e) {
    setFirstNumber(e.target.value);
    setSecondNumber(e.target.value);
  }

  useEffect(() => {
    setActive(element.active);
    setSecondNumber(
      loaded_measurement
        ? loaded_measurement.second
          ? loaded_measurement.second
          : defaultValue
        : defaultValue
    );
    setFirstNumber(
      loaded_measurement
        ? loaded_measurement.first
          ? loaded_measurement.first
          : defaultValue
        : defaultValue
    );
  }, [element.active, loaded_measurement, defaultValue]);

  return (
    <div className={`${classes.item} ${!stateActive && classes.inactive}`}>
      <ActivateAllergen
        element={element}
        stateActive={stateActive}
        onChangeActiveHandler={onChangeActiveHandler}
      />
      <Form.Control
        className={`${classes.number_input} ${
          !stateActive && classes.inactive
        }`}
        type="number"
        id={`prick_${element.identifier}_1`}
        value={stateFirstNumber}
        disabled={stateActive ? false : true}
        onChange={firstNumberHandler}
      ></Form.Control>
      <div>x</div>
      <Form.Control
        className={`${classes.number_input} ${
          !stateActive && classes.inactive
        }`}
        type="number"
        id={`prick_${element.identifier}_2`}
        value={stateSecondNumber}
        onChange={(e) => setSecondNumber(e.target.value)}
        disabled={stateActive ? false : true}
      ></Form.Control>
      <div>mm</div>
      <InputCheckDiv
        element={element}
        label="PSD"
        identifier="psd"
        defaultValue={loaded_measurement ? loaded_measurement.psd : false}
      />
      {title === "Alimentos" && (
        <InputCheckDiv
          element={element}
          label="POS"
          identifier="checkPositive"
          defaultValue={
            loaded_measurement
              ? loaded_measurement.result === "Positive"
                ? true
                : false
              : false
          }
        />
      )}
    </div>
  );
}

InputSubstance.defaultProps = {
  defaultValue: "",
};

export default InputSubstance;

import React from "react";
import Form from "react-bootstrap/Form";
import DateInput from "../AllergensComponents/PatchTestComponents/header_footer/DateInput";
import { formatDatefromBr, formatDatetoBr } from "../../util/stringFormaters";
import { useDispatch, useSelector } from "react-redux";
import { createGuidelinesPDF } from "../../store/printed-actions";

function PrintedForm() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );
  const selected_clinic = useSelector((state) => state.clinic.selected_clinic);

  function submitHandler(e) {
    e.preventDefault();
    var selected_printed = [];

    if (e.target.environment_control.checked) {
      selected_printed.push("environmental_control");
    }
    if (e.target.advices_rh_aines.checked) {
      selected_printed.push("rh_aines");
    }
    if (e.target.advices_eczema.checked) {
      selected_printed.push("eczema");
    }
    if (e.target.advices_da_dac.checked) {
      selected_printed.push("da_dac");
    }

    const guidelines = {
      name: selected_patient.name ? selected_patient.name : "",
      city: e.target.city.value,
      signature_date: formatDatefromBr(e.target.date.value),
      list: selected_printed,
    };
    dispatch(createGuidelinesPDF(guidelines, token));
  }
  return (
    <div className="container">
      <Form onSubmit={submitHandler}>
        <div className="d-flex flex-wrap gap-2 justify-content-center">
          <Form.Check
            type="checkbox"
            id="environment_control"
            label="Controle Ambiental"
          />
          <Form.Check
            type="checkbox"
            id="advices_rh_aines"
            label="Orientações para RH aos AINEs"
          />
          <Form.Check
            type="checkbox"
            id="advices_eczema"
            label="Controle de Eczema"
          />
          <Form.Check
            type="checkbox"
            id="advices_da_dac"
            label="Orientações para DA e DAC"
          />
        </div>
        <div className="d-flex gap-2 justify-content-center">
          <div className="form-group">
            <Form.Label htmlFor="city">Local:</Form.Label>
            <Form.Control
              type="text"
              id="city"
              label="Local"
              defaultValue={selected_clinic ? selected_clinic.city : ""}
            />
          </div>
          <DateInput
            id="date"
            label="Data:"
            with_date_picker={true}
            default_value={formatDatetoBr(
              new Date().toISOString().split("T")[0]
            )}
          />
        </div>
        <button className="btn btn-primary my-3" type="submit">
          Gerar Impressos
        </button>
      </Form>
    </div>
  );
}

export default PrintedForm;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { solicitacaoActions } from "../../../store/solicitacao-slice";
import classes from "./TICTable.module.css";

function TICTable() {
  const dispatch = useDispatch();
  const tic_option_selected = useSelector(
    (state) => state.solicitacao.tic_option_selected
  );
  function clickHandler(element_name) {
    dispatch(solicitacaoActions.toggleTICOptionActive(element_name));
  }
  return (
    <div className={classes.container}>
      {tic_option_selected.options.map((option, index) => (
        <div key={index}>
          <div className={classes.group_name}>{option.group_name}</div>
          {option.elements.map((element, index) => (
            <div
              className={`${classes.element} ${
                !element.active && classes.inactive
              }`}
              key={index}
              onClick={() => clickHandler(element.name)}
            >
              {element.name}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default TICTable;

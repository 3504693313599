import React, { useEffect, useState } from "react";
import {
  deleteOnePrescriptionOption,
  fetchAllPrescriptionOptions,
  updatePrescriptionOption,
} from "../../../store/prescription_options-actions";
import { useDispatch, useSelector } from "react-redux";
import {
  faPenToSquare,
  faTrashCan,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirm } from "../../../util/Confirmation";
import NewPrescriptionOption from "./NewPrescriptionOption";
import EditPrescriptionOption from "./EditPrescriptionOption";
import { prescriptionActions } from "../../../store/prescription-slice";
import classes from "./PrescriptionOptions.module.css";

function PrescriptionOptions() {
  const need_fetch = useSelector(
    (state) => state.prescription_options.need_fetch
  );
  const all_prescription_options = useSelector(
    (state) => state.prescription_options.all_prescription_options
  );
  const selected_prescription = useSelector(
    (state) => state.prescription.selected_prescription
  );
  const token = useSelector((state) => state.login.token);

  const dispatch = useDispatch();

  const [stateShowNewForm, setShowNewForm] = useState(false);
  const [stateShowEditForm, setShowEditForm] = useState(false);
  const [stateOptionToEdit, setOptionToEdit] = useState([]);
  const [stateSearchText, setSearchText] = useState("");
  const [stateSearchCategory, setSearchCategory] = useState("");

  useEffect(() => {
    if (need_fetch) {
      dispatch(fetchAllPrescriptionOptions(token));
    }
  }, [dispatch, need_fetch, token]);

  const handleDelete = async (prescription_option) => {
    if (await confirm("Tem certeza que deseja excluir esse cadastro?")) {
      dispatch(deleteOnePrescriptionOption(prescription_option, token));
    }
  };
  const handleClick = (prescription_option) => {
    switch (prescription_option.category) {
      case "USO ORAL":
        if (selected_prescription.oral_use.replace(/<[^>]*>/g, "") !== "") {
          dispatch(
            prescriptionActions.setOralUse(
              selected_prescription.oral_use + '<br>' +  prescription_option.body
            )
          );
        } else {
          dispatch(prescriptionActions.setOralUse(prescription_option.body));
        }
        break;
      case "USO EXTERNO":
        if (selected_prescription.external_use.replace(/<[^>]*>/g, "") !== "") {
          dispatch(
            prescriptionActions.setExternalUse(
              selected_prescription.external_use + '<br>' +  prescription_option.body
            )
          );
        } else {
          dispatch(
            prescriptionActions.setExternalUse(prescription_option.body)
          );
        }
        break;
      case "USO INALATÓRIO":
        if (
          selected_prescription.inhalation_use.replace(/<[^>]*>/g, "") !== ""
        ) {
          dispatch(
            prescriptionActions.setInhalationUse(
              selected_prescription.inhalation_use + '<br>' +  prescription_option.body
            )
          );
        } else {
          dispatch(
            prescriptionActions.setInhalationUse(prescription_option.body)
          );
        }
        break;
      case "USO INJETÁVEL":
        if (
          selected_prescription.injectable_use.replace(/<[^>]*>/g, "") !== ""
        ) {
          dispatch(
            prescriptionActions.setInjectableUse(
              selected_prescription.injectable_use + '<br>' +  prescription_option.body
            )
          );
        } else {
          dispatch(
            prescriptionActions.setInjectableUse(prescription_option.body)
          );
        }
        break;
      case "PROTOCOLO":
        dispatch(prescriptionActions.setProtocol(prescription_option.body));
        break;
      default:
        break;
    }
  };

  const moveUp = (prescription_option, order) => {
    dispatch(
      updatePrescriptionOption({ ...prescription_option, order: order }, token)
    );
  };
  const moveDown = (prescription_option, order) => {
    dispatch(
      updatePrescriptionOption(
        { ...prescription_option, order: order + 2 },
        token
      )
    );
  };
  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  function filter_search(array, text) {
    const query = text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    return array.filter(
      (element) =>
        (stateSearchCategory === "" ||
          element.category === stateSearchCategory) &&
        (element.category
          .replace(/<[^>]*>/g, "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(query) ||
          htmlDecode(element.body)
            .replace(/<[^>]*>/g, "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(query) ||
          element.title
            .replace(/<[^>]*>/g, "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(query))
    );
  }

  return (
    <div className="container border rounded">
      <h2>Selecione os textos para Receita:</h2>
      <div className="form-group d-flex my-2">
        <button
          className="btn btn-primary text-nowrap mx-2"
          onClick={() => {
            setShowEditForm(false);
            setShowNewForm(true);
          }}
        >
          Criar novo texto
        </button>
        <select
          className="form-control"
          id="category"
          value={stateSearchCategory}
          onChange={(e) => setSearchCategory(e.target.value)}
        >
          <option value="">TODAS CATEGORIAS</option>
          <option value="USO ORAL">USO ORAL</option>
          <option value="USO EXTERNO">USO EXTERNO</option>
          <option value="USO INALATÓRIO">USO INALATÓRIO</option>
          <option value="USO INJETÁVEL">USO INJETÁVEL</option>
          <option value="PROTOCOLO">PROTOCOLO</option>
        </select>
        <input
          id="search_presciption_option"
          className="form-control"
          placeholder="Digite texto para pesquisar"
          value={stateSearchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        ></input>
      </div>
      <div className={`list-group text-start  ${classes.scroll}`}>
        {filter_search(all_prescription_options, stateSearchText).map(
          (prescription_option, index) => (
            <div
              className="list-group-item list-group-item-action d-flex btn"
              key={index}
            >
              <div
                className="col row"
                onClick={() => handleClick(prescription_option)}
              >
                <div className="col-auto">
                  {prescription_option.order + "."}
                </div>
                <div className="col-auto">{prescription_option.title}</div>
                <div className="col-auto">{prescription_option.category}</div>
                <div
                  className={`col ${classes.one_item}`}
                  dangerouslySetInnerHTML={{ __html: prescription_option.body }}
                ></div>
              </div>
              <FontAwesomeIcon
                icon={faPenToSquare}
                title="Editar"
                onClick={() => {
                  setShowNewForm(false);
                  setShowEditForm(true);
                  setOptionToEdit(prescription_option);
                }}
                className="btn"
              />
              <FontAwesomeIcon
                icon={faTrashCan}
                title="Excluir"
                onClick={() => handleDelete(prescription_option)}
                className="btn"
              />
              <FontAwesomeIcon
                icon={faArrowUp}
                onClick={() => moveUp(prescription_option, index)}
                className="btn"
              />
              <FontAwesomeIcon
                icon={faArrowDown}
                onClick={() => moveDown(prescription_option, index)}
                className="btn"
              />
            </div>
          )
        )}
      </div>
      {stateShowNewForm && (
        <NewPrescriptionOption setShowForm={setShowNewForm} />
      )}
      {stateShowEditForm && (
        <EditPrescriptionOption
          setShowForm={setShowEditForm}
          prescription_option={stateOptionToEdit}
        />
      )}
    </div>
  );
}

export default PrescriptionOptions;

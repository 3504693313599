import { uiActions } from "./ui-slice";
import { prickActions } from "./prick-slice";
import { convertToFileName } from "../util/stringFormaters";

const API_URL_PRICK = process.env.REACT_APP_API_URL + "/pricks";

export const createPrickForm = (newForm, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviando...",
        message: "Enviando Teste de Alérgenos!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PRICK, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newForm),
      });

      if (!response.ok) {
        throw new Error("Falha ao enviar novo prick.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const data = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Teste de prick enviado com sucesso!",
        })
      );

      dispatch(prickActions.addOnePrickForm(data));
      dispatch(prickActions.setPrickFormSelected(data));
      dispatch(prickActions.needDownload(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao enviar teste de prick.",
        })
      );
    }
  };
};

export const deleteOnePrickForm = (prick_form, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Excluindo...",
        message: "Cadastro Excluído!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PRICK + "/" + prick_form.id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: "",
      });

      if (!response.ok) {
        throw new Error("Falha ao excluir cadastro de formulário patch.");
      }

      await response.json();

      return;
    };

    try {
      await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Success!",
          message: "Formulário Prick excluído com sucesso!",
        })
      );
      dispatch(prickActions.removeOnePrickForm(prick_form));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao excluír cadastro de formulário Prick.",
        })
      );
    }
  };
};

export const fetchOnePrickFormMeasurements = (prick_form_id, token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(
        API_URL_PRICK + "/" + prick_form_id + ".json",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Could not fetch prick form data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const one_prick_form_measurements = await fetchData();
      dispatch(prickActions.setPrickMeasurements(one_prick_form_measurements));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao Buscar Formulários Prick!",
        })
      );
    }
  };
};

export const updatePrickForm = (prick_form, prick_form_id, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviando...",
        message: "Atualizando Teste de Prick!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PRICK + "/" + prick_form_id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(prick_form),
      });

      if (!response.ok) {
        throw new Error("Falha ao atualizar teste de prick.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const data = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Teste de Prick atualizado com sucesso!",
        })
      );

      dispatch(prickActions.updateOnePrickForm(data));
      dispatch(prickActions.needDownload(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao atualizar teste de prick.",
        })
      );
    }
  };
};

export const downloadPrickPDF = (prick_form, file_name, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Download...",
        message: "Realizando Download de Prick",
      })
    );
    const fetchData = async () => {
      const response = await fetch(API_URL_PRICK + "/" + prick_form.id + ".pdf", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error("Could not download prick pdf!");
      }
      const blob = await response.blob();

      // Create a temporary URL object
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${convertToFileName(file_name)}.pdf`); // Set the filename for the downloaded file
      // Set appropriate 'as' value for the resource based on its type
      link.setAttribute("as", "document"); // For PDF files, use 'document' as the 'as' value
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up the temporary URL object
      window.URL.revokeObjectURL(url);

      return response;
    };

    try {
      await fetchData();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Download de prick realizado com sucesso!",
        })
      );
      dispatch(prickActions.needDownload(false));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao fazer download de Prick!",
        })
      );
    }
  };
};

import React from "react";
import SolicitacaoForm from "../components/SolicitacaoComponents/SolicitacaoForm/SolicitacaoForm";
import { useSelector } from "react-redux";
import SelectSolicitacao from "../components/SolicitacaoComponents/SolicitacaoForm/SelectSolicitacao";
function SolicitacaoPage() {
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );

  return (
    <div>
      {selected_patient && <SelectSolicitacao />}
      {selected_patient && <SolicitacaoForm />}
    </div>
  );
}

export default SolicitacaoPage;

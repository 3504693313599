import Form from "react-bootstrap/Form";
import DateInput from "../AllergensComponents/PatchTestComponents/header_footer/DateInput";
import classes from "./PatientForm.module.css";
import {
  formatDatetoBr,
  formatCPF,
  formatContact,
} from "../../util/stringFormaters";
import { useDispatch, useSelector } from "react-redux";
import { patientsActions } from "../../store/patients-slice";
import { useEffect, useState } from "react";

function PacientForm({ patient }) {
  const cpf_typing = useSelector((state) => state.patients.cpf_typing);
  const phone_typing = useSelector((state) => state.patients.phone_typing);
  const name_typing = useSelector((state) => state.patients.name_typing);
  const [withoutCPF, setWithoutCPF] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(patientsActions.typeName(patient.name));
    dispatch(patientsActions.typeCPF(patient.cpf));
    dispatch(
      patientsActions.typePhone(patient.contact === null ? "" : patient.contact)
    );
  }, [dispatch, patient]);

  return (
    <>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="name">Nome:</Form.Label>
          <Form.Control
            type="text"
            id="name"
            placeholder="Nome"
            value={name_typing.toUpperCase()}
            onChange={(e) => {
              dispatch(patientsActions.typeName(e.target.value));
            }}
            required
          ></Form.Control>
        </div>
        <DateInput
          id="birthday"
          label="Data de Nascimento:"
          default_value={formatDatetoBr(
            patient.birthday === null ? "" : patient.birthday
          )}
        />
      </div>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="cpf">CPF:</Form.Label>
          <Form.Control
            type="text"
            id="cpf"
            placeholder="CPF"
            disabled={withoutCPF}
            required
            value={cpf_typing}
            onChange={(e) => {
              dispatch(patientsActions.typeCPF(formatCPF(e.target.value)));
            }}
          ></Form.Control>
          <Form.Check
            type="checkbox"
            label="Paciente sem CPF"
            id="no_cpf"
            checked={withoutCPF}
            onChange={(e) => {
              setWithoutCPF(e.target.checked);
              dispatch(patientsActions.typeCPF(""));
            }}
          />
        </div>
        <div>
          <Form.Label htmlFor="contact">Contato:</Form.Label>
          <Form.Control
            type="text"
            id="contact"
            placeholder="Contato"
            value={formatContact(phone_typing)}
            onChange={(e) => {
              dispatch(patientsActions.typePhone(e.target.value));
            }}
          ></Form.Control>
        </div>
      </div>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="weight">Peso (kg):</Form.Label>
          <Form.Control
            type="number"
            id="weight"
            step={0.01}
            defaultValue={patient.weight === null ? "" : patient.weight}
          />
        </div>
        <div>
          <Form.Label htmlFor="height">Altura (m):</Form.Label>
          <Form.Control
            type="number"
            id="height"
            step={0.01}
            defaultValue={patient.height === null ? "" : patient.height}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.gender}>
          <Form.Label htmlFor="gender">Gênero:</Form.Label>
          <Form.Select
            id="gender"
            defaultValue={patient.gender === null ? "" : patient.gender}
          >
            <option value="Ignorado">Ignorado</option>
            <option value="Masculino">Masculino</option>
            <option value="Feminino">Feminino</option>
          </Form.Select>
        </div>
        <div>
          <Form.Label htmlFor="origin">Procedência:</Form.Label>
          <Form.Control
            type="text"
            id="origin"
            placeholder="Procedência"
            defaultValue={patient.origin === null ? "" : patient.origin}
          ></Form.Control>
        </div>
      </div>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="responsable_name">
            Nome do Responsável:
          </Form.Label>
          <Form.Control
            type="text"
            id="responsable_name"
            placeholder="Nome do Responsável"
            defaultValue={
              patient.responsable_name === null ? "" : patient.responsable_name
            }
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="responsable_degree">
            Grau de Parentesco:
          </Form.Label>
          <Form.Control
            type="text"
            id="responsable_degree"
            placeholder="Grau de Parentesco"
            defaultValue={
              patient.responsable_degree === null
                ? ""
                : patient.responsable_degree
            }
          ></Form.Control>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.obs}>
          <Form.Label htmlFor="obs">Observações:</Form.Label>
          <Form.Control
            as="textarea"
            id="obs"
            placeholder="Observações"
            defaultValue={patient.obs === null ? "" : patient.obs}
          ></Form.Control>
        </div>
      </div>
    </>
  );
}

export default PacientForm;

import { configureStore } from "@reduxjs/toolkit";

import uiSlice from "./ui-slice";
import imunoSlice from "./imuno-slice";
import solicitacaoSlice from "./solicitacao-slice";
import patientsSlice from "./patients-slice";
import prickSlice from "./prick-slice";
import printedSlice from "./printed-slice";
import patientRecordsSlice from "./patient_record-slice";

import allergensSlice from "./allergens-slice";
import loginSlice from "./login-slice";
import prescriptionSlice from "./prescription-slice";
import clinicSlice from "./clinics-slice";
import ticSlice from "./tic-slice";
import prescription_optionsSlice from "./prescription_options-slice";

const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    patients: patientsSlice.reducer,
    patient_record: patientRecordsSlice.reducer,
    imuno: imunoSlice.reducer,
    allergens: allergensSlice.reducer,
    prick: prickSlice.reducer,
    solicitacao: solicitacaoSlice.reducer,
    printed: printedSlice.reducer,
    login: loginSlice.reducer,
    prescription: prescriptionSlice.reducer,
    prescription_options: prescription_optionsSlice.reducer,
    clinic: clinicSlice.reducer,
    tic: ticSlice.reducer,
  },
});

export default store;

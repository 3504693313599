import { uiActions } from "./ui-slice";
import { patientsActions } from "./patients-slice";
import { imunoActions } from "./imuno-slice";

const API_URL_PATIENTS = process.env.REACT_APP_API_URL + "/patients";

export const fetchAllPatients = (token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(API_URL_PATIENTS, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Could not fetch imuno data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const all_patients_received = await fetchData();
      dispatch(patientsActions.setAllPatients(all_patients_received));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao buscar dados dos pacientes!",
        })
      );
    }
  };
};

export const sendNewPatient = (newPatient, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Cadastrando...",
        message: "Cadastrando novo Paciente!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PATIENTS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newPatient),
      });

      if (!response.ok) {
        throw new Error("Falha ao cadastrar novo paciente.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const new_patients_received = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Success!",
          message: "Paciente cadastrado com sucesso!",
        })
      );
      dispatch(patientsActions.addOnePatient(new_patients_received));
      dispatch(patientsActions.setShowNewForm(false));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Falha ao cadastrar novo paciente.",
        })
      );
    }
  };
};

export const deleteOnePatient = (patient, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Excluindo...",
        message: "Cadastro sendo excluído.",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PATIENTS + "/" + patient.id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: "",
      });

      if (!response.ok) {
        throw new Error("Falha ao excluir cadastro de paciente.");
      }

      await response.json();

      return;
    };

    try {
      await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Secesso!",
          message: "Paciente excluído com sucesso!",
        })
      );
      dispatch(patientsActions.removeOnePatient(patient));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao excluír cadastro de paciente.",
        })
      );
    }
  };
};

export const updatePatient = (patient, patient_id, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Atualizando...",
        message: "Atualizando Cadastro do Paciente!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PATIENTS + "/" + patient_id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(patient),
      });

      if (!response.ok) {
        throw new Error("Falha ao atualizar cadastro de paciente.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const patient_data_received = await sendRequest();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Secesso!",
          message: "Paciente atualizado com sucesso!",
        })
      );
      dispatch(patientsActions.updateOnePatient(patient_data_received));
      dispatch(patientsActions.setShowEditForm(false));
      dispatch(imunoActions.setNeedFetch(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao atualizar cadastro de  paciente.",
        })
      );
    }
  };
};

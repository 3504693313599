import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendNewPatientRecord,
  updatePatientRecord,
} from "../../store/patient_record-actions";
import DateInput from "../AllergensComponents/PatchTestComponents/header_footer/DateInput";
import { formatDatefromBr, formatDatetoBr } from "../../util/stringFormaters";
import { patientRecordsActions } from "../../store/patient_record-slice";
function PatientRecordForm() {
  // State to store input values
  const selected_patient_record = useSelector(
    (state) => state.patient_record.selected_patient_record
  );
  const [record_text, setRecordText] = useState(
    selected_patient_record.record_text
  );
  const [record_date, setRecordDate] = useState(
    selected_patient_record.record_date
  );
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );
  const [show, setShow] = useState(false);

  const handleClose = () => {
    dispatch(patientRecordsActions.selectPatientRecord(""));
  };

  useEffect(() => {
    selected_patient_record ? setShow(true) : setShow(false);
    setRecordText(selected_patient_record.record_text);
    setRecordDate(selected_patient_record.record_date);
  }, [selected_patient_record]);

  // Handler for form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    const patient_record = {
      patient_id: selected_patient.id,
      record_text,
      record_date,
    };
    if (selected_patient_record.id) {
      dispatch(
        updatePatientRecord(patient_record, selected_patient_record.id, token)
      );
    } else {
      dispatch(sendNewPatientRecord(patient_record, token));
    }
    dispatch(patientRecordsActions.selectPatientRecord(""));
  };

  return (
    <>
      <div
        className={show ? "modal fade show d-block" : "modal fade"}
        tabIndex="-1"
      >
        <div className="modal-dialog text-start">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Registro de Paciente</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="record_text" className="form-label">
                  Texto:
                </label>
                <textarea
                  className="form-control"
                  rows="4"
                  cols="50"
                  value={record_text}
                  id="record_text"
                  onChange={(e) => setRecordText(e.target.value)}
                />
              </div>
              <div className="mb-3 row">
                <label htmlFor="record_date" className="form-label col">
                  Data:
                </label>
                <DateInput
                  id="record_date"
                  with_date_picker={true}
                  onChangeHandler={(value) => {
                    setRecordDate(formatDatefromBr(value));
                  }}
                  default_value={formatDatetoBr(record_date)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Voltar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </div>
      {show && <div className="modal-backdrop fade show"></div>}
    </>
  );
}

export default PatientRecordForm;

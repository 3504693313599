import { createSlice } from "@reduxjs/toolkit";
import { diagnosis_data } from "../data/ImunoterapiaData/DATA_Imunoterapia";
import { formatDatetoBr } from "../util/stringFormaters";

const imunoSlice = createSlice({
  name: "imuno",
  initialState: {
    formData: [],
    diagnosis_data: diagnosis_data,
    search_diagnosis_name: "",
    primaryDiagnosis: "",
    secondaryDiagnosis: "",
    showForm: false,
    show_select_patient: false,
    all_immunotherapies: [],
    selected_immunotherapy: "",
    need_fetch: true,
    signature_date_temp: "",
    immunotherapy_to_send: [],
    need_download: false,
  },
  reducers: {
    setAllImmunotherapies(state, action) {
      state.all_immunotherapies = action.payload;
      state.need_fetch = false;
    },
    addOneImmunotherapy(state, action) {
      state.all_immunotherapies.unshift(action.payload);
      state.selected_immunotherapy = action.payload;
      state.signature_date_temp = formatDatetoBr(action.payload.signature_date);
      state.showForm = true;
      state.show_select_patient = false;
    },
    removeOneImmunotherapy(state, action) {
      const idx = state.all_immunotherapies.findIndex(
        (immunotherapy) => immunotherapy.id === action.payload.id
      );
      state.all_immunotherapies.splice(idx, 1);
    },
    updateOneImmunotherapy(state, action) {
      const idx = state.all_immunotherapies.findIndex(
        (immunotherapy) => immunotherapy.id === action.payload.id
      );
      state.all_immunotherapies.splice(idx, 1, action.payload);
      if (state.selected_immunotherapy.id === action.payload.id) {
        state.selected_immunotherapy = action.payload;
        state.signature_date_temp = formatDatetoBr(
          action.payload.signature_date
        );
      }
    },
    selectImmunotherapy(state, action) {
      state.selected_immunotherapy = action.payload;
      if (action.payload.id) {
        state.signature_date_temp = formatDatetoBr(
          action.payload.signature_date
        );
        state.showForm = true;
      } else {
        state.showForm = false;
      }
    },
    setNeedFetch(state, action) {
      state.need_fetch = action.payload;
    },
    setClinic(state, action) {
      state.selected_immunotherapy.clinic = action.payload;
    },
    setIgeTotal(state, action) {
      state.selected_immunotherapy.ige_total = action.payload;
    },
    setIgeSpecific(state, action) {
      state.selected_immunotherapy.ige_specific = action.payload;
    },
    setEosinofilosPerc(state, action) {
      state.selected_immunotherapy.eosinofilos_perc = action.payload;
    },
    setEosinofilosMm(state, action) {
      state.selected_immunotherapy.eosinofilos_mm = action.payload;
    },
    setOthers(state, action) {
      state.selected_immunotherapy.others = action.payload;
    },
    setPrickSummary(state, action) {
      state.selected_immunotherapy.prick_summary = action.payload;
    },
    setPatchSummary(state, action) {
      state.selected_immunotherapy.patch_summary = action.payload;
    },

    setPrimaryDiagnosis(state, action) {
      state.selected_immunotherapy.primary_diagnosis = action.payload;
    },
    setSecondaryDiagnosis(state, action) {
      state.selected_immunotherapy.secondary_diagnosis = action.payload;
    },
    setImmunotherayComposition(state, action) {
      state.selected_immunotherapy.immunotheray_composition = action.payload;
    },
    setDilutionVolume(state, action) {
      state.selected_immunotherapy.dilution_volume = action.payload;
    },
    setSublingualDrops(state, action) {
      state.selected_immunotherapy.sublingual_drops = action.payload;
    },
    setCity(state, action) {
      state.selected_immunotherapy.city = action.payload;
    },
    setSignatureDate(state, action) {
      state.selected_immunotherapy.signature_date = action.payload;
    },
    setFormData(state, action) {
      state.formData = action.payload.formData;
    },
    setDiagnosis(state, action) {
      if (state.selected_immunotherapy.primary_diagnosis.length === 0) {
        state.selected_immunotherapy.primary_diagnosis = action.payload;
      } else {
        if (state.selected_immunotherapy.secondary_diagnosis === "") {
          state.selected_immunotherapy.secondary_diagnosis =
            action.payload.trim();
        } else {
          state.selected_immunotherapy.secondary_diagnosis =
            state.selected_immunotherapy.secondary_diagnosis.trim() +
            " + " +
            action.payload;
        }
      }
    },
    typeSearchDiagnosisName(state, action) {
      state.search_diagnosis_name = action.payload;
    },
    setSignatureDateTemp(state, action) {
      state.signature_date_temp = action.payload;
    },
    setShowSelectPatient(state, action) {
      state.show_select_patient = action.payload;
    },
    setImmunotherapyToSend(state, action) {
      state.immunotherapy_to_send = action.payload;
    },
    needDownload(state, action) {
      state.need_download = action.payload;
    },
  },
});

export const imunoActions = imunoSlice.actions;

export default imunoSlice;

import React from "react";
import { useDispatch } from "react-redux";
import { patientsActions } from "../store/patients-slice";
import PacientFormNew from "../components/PatientsComponents/PacientFormNew";
import { Button } from "react-bootstrap";
import classes from "./PatientRegister.module.css";
import logo from "../images/logo_report.png";

function PatientRegister() {
  const dispatch = useDispatch();
  dispatch(patientsActions.setShowNewForm(true));
  return (
    <div className={classes.action}>
      <img src={logo} alt="logo" className={classes.logo} />
      <Button onClick={() => dispatch(patientsActions.setShowNewForm(true))}>
        Cadastrar
      </Button>
      <PacientFormNew minimum={true} />
    </div>
  );
}

export default PatientRegister;

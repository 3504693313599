import { createSlice } from "@reduxjs/toolkit";

const ticSlice = createSlice({
  name: "tic",
  initialState: {
    all_tics: [],
    selected_tic: "",
    need_fetch: true,
    need_download: false,
    name: "",
  },
  reducers: {
    setAllTics(state, action) {
      state.all_tics = action.payload;
      state.need_fetch = false;
    },
    selectTic(state, action) {
      state.selected_tic = action.payload;
    },
    addOneTic(state, action) {
      state.all_tics.unshift(action.payload);
      state.selected_tic = action.payload;
    },
    removeOneTic(state, action) {
      const idx = state.all_tics.findIndex(
        (tic) => tic.id === action.payload.id
      );
      state.all_tics.splice(idx, 1);
      state.selected_tic = "";
    },
    updateOneTic(state, action) {
      const idx = state.all_tics.findIndex(
        (tic) => tic.id === action.payload.id
      );
      state.all_tics.splice(idx, 1, action.payload);
      state.selected_tic = action.payload;
    },
    needDownload(state, action) {
      state.need_download = action.payload;
    },
    setClinic(state, action) {
      state.selected_tic.clinic_id = action.payload.id;
    },
    setBody(state, action) {
      state.selected_tic.body = action.payload;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setReceituaraio(state, action) {
      state.selected_tic.recipe = action.payload;
    },
  },
});

export const ticActions = ticSlice.actions;

export default ticSlice;

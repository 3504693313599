import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import classes from "./PrescriptionOptionForm.module.css";

function PrescriptionOptionForm({
  prescription_option,
  setShowForm,
  handleSubmit,
}) {
  const [stateId, setId] = useState(
    prescription_option.id ? prescription_option.id : ""
  );
  const [stateBody, setBody] = useState(prescription_option.body);
  const [stateTitle, setTitle] = useState(prescription_option.title);
  const [stateCategory, setCategory] = useState(prescription_option.category);
  const [stateOrder, setOrder] = useState(prescription_option.order);

  const init_options = {
    plugins:
      "autolink charmap emoticons lists table wordcount checklist casechange textcolor permanentpen powerpaste advtable autocorrect inlinecss",
    toolbar:
      "customInsertButton indent outdent | undo redo | fontsize | bold italic underline strikethrough forecolor backcolor | align lineheight | numlist bullist | charmap | removeformat",
    content_style:
      "body { margin: 0; padding-top:0.2cm; padding-right:0.8cm; padding-left: 0.7cm; font-size: 10pt; font-family: sans-serif; font-stretch: extra-expanded;} p { margin: 0; }",
    promotion: false,
    branding: false,
    width: "14.8cm",
    height: "7cm",
    setup: (editor) => {
      editor.ui.registry.addButton("customInsertButton", {
        text: "...",
        onAction: function (_) {
          editor.insertContent("<p style='display: flex;'># Item&nbsp;&nbsp;<span style='flex-grow: 1; border-bottom: 1pt dotted;'>&nbsp;</span>&nbsp;&nbsp;Qnt</p>");
        },
      });
    },
  };

  useEffect(() => {
    setId(prescription_option.id ? prescription_option.id : "");
    setBody(prescription_option.body);
    setTitle(prescription_option.title);
    setCategory(prescription_option.category);
    setOrder(prescription_option.order);
  }, [prescription_option]);

  return (
    <Modal
      animation={true}
      show={true}
      onHide={() => {}}
      backdrop={"static"}
      keyboard={true}
      dialogClassName={classes.dialog}
    >
      <Modal.Header>
        <Modal.Title>Cadastro texto para Receita</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="">
          <input
            id="id"
            className="d-none"
            value={stateId}
            onChange={(e) => setId(e.target.value)}
          />
          <div className="form-group">
            <label htmlFor="order">Ordem:</label>
            <input
              type="number"
              step={1}
              className="form-control"
              id="order"
              value={stateOrder}
              onChange={(e) => setOrder(e.target.value.replace(/[^\d]/g, ""))}
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="title">Título:</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={stateTitle}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="category">Categoria:</label>
            <select
              className="form-control"
              id="category"
              value={stateCategory}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="USO ORAL">USO ORAL</option>
              <option value="USO EXTERNO">USO EXTERNO</option>
              <option value="USO INALATÓRIO">USO INALATÓRIO</option>
              <option value="USO INJETÁVEL">USO INJETÁVEL</option>
              <option value="PROTOCOLO">PROTOCOLO</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="body">Texto:</label>
            <Editor
              tinymceScriptSrc={
                process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
              }
              init={init_options}
              value={stateBody}
              onEditorChange={(value) => setBody(value)}
            />
            <textarea
              className="form-control d-none"
              id="body"
              value={stateBody}
              onChange={() => {}}
            />
          </div>
          <Modal.Footer>
            <button
              className="btn btn-primary m-2"
              onClick={() => setShowForm(false)}
            >
              Cancelar
            </button>
            <button className="btn btn-primary m-2" type="submit">
              Enviar
            </button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default PrescriptionOptionForm;

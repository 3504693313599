import { createSlice } from "@reduxjs/toolkit";

const allergensSlice = createSlice({
  name: "allergens",
  initialState: {
    patch_selector: [],
    all_patch: [],
    need_fetch: true,
    patch_measurements: [],
    patch_form_selected: [],
    need_download: false,
  },
  reducers: {
    setNeedfetch(state) {
      state.need_fetch = true;
    },
    selectPatch(state, action) {
      state.patch_selector.push(action.payload);
    },
    deselectPatch(state, action) {
      const index = state.patch_selector.findIndex(
        (element) => element.identifier === action.payload.identifier
      );
      if (index >= 0) {
        state.patch_selector.splice(index, 1);
      }
    },
    deselectAllPatch(state) {
      state.patch_selector = [];
    },
    setAllPatchs(state, action) {
      state.all_patch = action.payload;
      state.need_fetch = false;
    },
    removeOnePatchForm(state, action) {
      const idx_all = state.all_patch.findIndex(
        (patch_form) => patch_form.id === action.payload.id
      );
      state.all_patch.splice(idx_all, 1);
    },
    updateOnePatchForm(state, action) {
      const idx = state.all_patch.findIndex(
        (one_pach_form) => one_pach_form.id === action.payload.id
      );
      state.all_patch.splice(idx, 1, action.payload);
      if (state.patch_form_selected.id === action.payload.id) {
        state.patch_form_selected = action.payload;
      }
    },
    addOnePatchForm(state, action) {
      state.all_patch.unshift(action.payload);
    },
    setPatchMeasurements(state, action) {
      state.patch_measurements = action.payload;
    },
    setPatchFormSelected(state, action) {
      state.patch_form_selected = action.payload;
    },
    setRequester(state, action) {
      state.patch_form_selected.requester = action.payload;
    },
    setClinic(state, action) {
      state.patch_form_selected.clinic_id = action.payload;
    },
    setCity(state, action) {
      state.patch_form_selected.city = action.payload;
    },
    needDownload(state, action) {
      state.need_download = action.payload;
    },
  },
});

export const allergensActions = allergensSlice.actions;

export default allergensSlice;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("allergens");
  }, [navigate]);

  return <h1>Página Principal</h1>;
}
export default HomePage;

export const data = {
  identifier: "alimentos",
  subtitle: "BATERIA ALIMENTOS",
  form_title: "ALIMENTOS",
  number: "(10 SUBSTÂNCIAS)",
  initialShow: false,
  sensitizers: [
    {
      measurement_label: "AL1",
      patch_test_info_identifier:"alimentos",
    },
    {
      measurement_label: "AL2",
      patch_test_info_identifier:"alimentos",
    },
    {
      measurement_label: "AL3",
      patch_test_info_identifier:"alimentos",
    },
    {
      measurement_label: "AL4",
      patch_test_info_identifier:"alimentos",
    },
    {
      measurement_label: "AL5",
      patch_test_info_identifier:"alimentos",
    },
    {
      measurement_label: "AL6",
      patch_test_info_identifier:"alimentos",
    },
    {
      measurement_label: "AL7",
      patch_test_info_identifier:"alimentos",
    },
    {
      measurement_label: "AL8",
      patch_test_info_identifier:"alimentos",
    },
    {
      measurement_label: "AL9",
      patch_test_info_identifier:"alimentos",
    },
    {
      measurement_label: "AL10",
      patch_test_info_identifier:"alimentos",
    },
  ],
};

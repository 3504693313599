import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { patientsActions } from "../store/patients-slice";
import { confirm } from "../util/Confirmation";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { deleteOnePatient } from "../store/patients-actions";

function PatientsPage() {
  const dispatch = useDispatch();

  const all_patients = useSelector((state) => state.patients.all_patients);
  const token = useSelector((state) => state.login.token);

  const handleDelete = async (patient) => {
    if (await confirm("Tem certeza que deseja excluir esse cadastro?")) {
      dispatch(deleteOnePatient(patient, token));
    }
  };
  return (
    <table className="table table-striped container">
      <thead>
        <tr>
          <th scope="col">Nome</th>
          <th scope="col">CPF</th>
          <th scope="col">Ação</th>
        </tr>
      </thead>
      <tbody>
        {all_patients.length > 0 &&
          all_patients.map((one_patient, index) => (
            <tr key={index}>
              <td>{one_patient.name}</td>
              <td>{one_patient.cpf}</td>
              <td>
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  title="Editar"
                  onClick={() => {
                    dispatch(patientsActions.setPacientToEdit(one_patient));
                    dispatch(patientsActions.setShowEditForm(true));
                  }}
                  className="btn"
                />
                <FontAwesomeIcon
                  icon={faTrashCan}
                  title="Excluir"
                  onClick={() => handleDelete(one_patient)}
                  className="btn"
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default PatientsPage;

export const diagnosis_data = [
  "DERMATITE ATÓPICA",
  "RINITE ALÉRGICA",
  "ASMA ATÓPICA",
  "ALERGIA ALIMENTAR",
  "ALERGIA RESPIRATÓRIA",
  "ANGIOEDEMA",
  "ASMA BRÔNQUICA ",
  "ASMA TIPO 2",
  "CERATOSE PILAR",
  "CONJUNTIVITE ALÉRGICA",
  "DCEA – DOENÇA CUTÂNEA EXACERBADA POR ASPIRINA/ AINE’S",
  "DERMATITE ALÉRGICA DE CONTATO",
  "DERMATITE ATÓPICA ATÍPICA (FOLICULAR)",
  "DERMATITE ATÓPICA IgE MEDIADA",
  "DERMATITE ATÓPICA LESIONAL",
  "DERMATITE ATÓPICA LEVE",
  "DERMATITE ATÓPICA MODERADA",
  "DERMATITE ATÓPICA SEVERA ",
  "DERMATITE PERIORAL",
  "DERMATOFITOSE",
  "DERMOGRAFISMO BRANCO",
  "DERMOGRAFISMO SINTOMÁTICO",
  "DREA – DOENÇA RESPIRATÓRIA EXACERBADA POR ASPIRINA/ AINE’S",
  "DPOC",
  "DRGE",
  "ECZEMA ATÓPICO",
  "ECZEMA DE COURO CABELUDO",
  "ECZEMA DE FACE",
  "ECZEMA DISIDRÓTICO",
  "ECZEMA DISIDRÓTICO DE MÃOS",
  "ECZEMA DISIDRÓTICO DE MÃOS E PÉS",
  "ECZEMA DISIDRÓTICO DE PÉS",
  "ECZEMA NUMULAR",
  "ECZEMA PERIOCULAR",
  "EII",
  "EoE",
  "ERITRASMA",
  "ERITRODERMIA",
  "ERITEMA MULTIFORME",
  "ERISTRASMA",
  "FCAS",
  "HIPOMELANOSE MACULAR PROGRESSIVA",
  "INFECÇÃO DERMATOLÓGICA SECUNDÁRIA",
  "INTOLERÂNCIA À ASPIRINA E AOS AINE’S",
  "INTOLERÂNCIA À LACTOSE",
  "INTOLERÂNCIA AO ETANOL",
  "INFECÇÕES DE VIAS AÉREAS SUPERIORES DE REPETIÇÃO",
  "PFAPA",
  "PITIRÍASE ALBA",
  "PRURIGO ESTRÓFULO",
  "PRURIGO NODULAR ",
  "QUEILITE",
  "RINITE ALÉRGICA INTERMITENTE LEVE",
  "RINITE ALÉRGICA INTERMITENTE MODERADA",
  "RINITE ALÉRGICA PERSISTENTE SEVERA",
  "RINOCONJUNTIVITE ALÉRGICA",
  "RINOSSINUSITE CRÔNICA",
  "RINOSSINUSITE CRÔNICA + ASMA TIPO 2 + INTOLERÂNCIA À ASPIRINA E AOS AINE’S",
  "RINOSSINUSITE CRÔNICA COM POLIPOSE NASAL",
  "RINOSSINUSITE CRÔNICA COM POLIPOSE NASAL + ASMA TIPO 2 + INTOLERÂNCIA À ASPIRINA E AOS AINE’S",
  "SINDROME CAPS",
  "TOSSE CRÔNICA",
  "URTICÁRIA AGUDA",
  "URTICÁRIA AGUDA RESOLVIDA",
  "URTICÁRIA COLINÉRGICA",
  "XERODERMIA",
];

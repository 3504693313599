import { Outlet } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import classes from "./RootLayout.module.css";
import Notification from "../components/ui/Notification";
import PacientFormEdit from "../components/PatientsComponents/PacientFormEdit";
import PacientFormNew from "../components/PatientsComponents/PacientFormNew";
import { useSelector } from "react-redux";
import Login from "../components/LoginComponents/Login";
import SelectPatient from "../components/PatientsComponents/SelectPatient";

function RootLayout() {
  const token = useSelector((state) => state.login.token);
  return (
    <>
      <Notification />
      <MainNavigation />
      <PacientFormEdit />
      <PacientFormNew />
      <main className={classes.content}>
        {token !== "" && (
          <>
            <SelectPatient />
            <Outlet />
          </>
        )}
        {token === "" && <Login />}
      </main>
    </>
  );
}

export default RootLayout;

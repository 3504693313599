import React from "react";
import { formatIsoDateStringToCustom } from "../../../../util/stringFormaters";
import { confirm } from "../../../../util/Confirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { prickActions } from "../../../../store/prick-slice";
import { useEffect } from "react";
import {
  fetchOnePrickFormMeasurements,
  deleteOnePrickForm,
  downloadPrickPDF,
} from "../../../../store/prick-actions";
import classes from "./SelectPrickForm.module.css";

function SelectPrickForm() {
  const dispatch = useDispatch();
  const all_prick = useSelector((state) => state.prick.all_prick);
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );
  const prick_data = useSelector((state) => state.prick.prick_data);

  const prick_form_selected = useSelector(
    (state) => state.prick.prick_form_selected
  );
  const selected_clinic = useSelector((state) => state.clinic.selected_clinic);
  const clinics = useSelector((state) => state.clinic.clinics);
  const token = useSelector((state) => state.login.token);

  useEffect(() => {
    dispatch(prickActions.setPrickFormSelected([]));
    dispatch(prickActions.setPrickMeasurements([]));
  }, [dispatch, selected_patient]);

  const handleDelete = async (one_prick_form) => {
    if (await confirm("Tem certeza que deseja excluir esse formulário?")) {
      dispatch(deleteOnePrickForm(one_prick_form, token));
    }
  };

  function newPrickFormHandler() {
    dispatch(
      prickActions.setPrickFormSelected({
        patient_id: selected_patient.id,
        clinic_id:
          selected_clinic && selected_clinic.id
            ? selected_clinic.id
            : clinics[0].id,
        city: selected_clinic.city,
        signature_date: new Date().toISOString().split("T")[0],
        puncture_time: "8:00",
        reading_time: "8:20",
        comments: "",
        requester: "DR. CLÉRISTON DOMINGUES",
        positive_control1: 0,
        positive_control2: 0,
        negative_control1: 0,
        negative_control2: 0,
        environmental_control: false,
        eczema: false,
        da_dac: false,
        name: selected_patient.name,
        birthday: selected_patient.birthday,
      })
    );
    dispatch(prickActions.setPrickMeasurements([]));
    dispatch(prickActions.selectPrick(prick_data[0].title));
    dispatch(prickActions.selectPrick(prick_data[1].title));
    dispatch(prickActions.selectPrick(prick_data[2].title));
    dispatch(prickActions.deselectPrick(prick_data[3].title));
  }
  function cancelPrickFormHandler() {
    dispatch(prickActions.deselectAllPrick());
    dispatch(prickActions.setPrickFormSelected([]));
    dispatch(prickActions.setPrickMeasurements([]));
  }
  return (
    <div className={classes.container}>
      <div className={classes.list_border}>
        <div className={classes.prick_form_list}>
          {all_prick
            .filter(
              (prick_form) => prick_form.patient_id === selected_patient.id
            )
            .map((one_prick_form, index) => (
              <div
                className={`${classes.one_prick_form} ${
                  prick_form_selected.id === one_prick_form.id &&
                  classes.selected
                }`}
                key={index}
              >
                <div
                  onClick={(e) => {
                    dispatch(
                      fetchOnePrickFormMeasurements(one_prick_form.id, token)
                    );
                    dispatch(prickActions.setPrickFormSelected(one_prick_form));
                  }}
                  className={classes.item_text}
                >
                  {"Prick de "}
                  {one_prick_form.name}
                  {" em "}
                  {formatIsoDateStringToCustom(one_prick_form.updated_at)}
                </div>
                {one_prick_form.pdf && (
                  <FontAwesomeIcon
                    icon={faFile}
                    title="Fazer Download"
                    onClick={() => {
                      dispatch(
                        downloadPrickPDF(
                          one_prick_form,
                          selected_patient.name,
                          token
                        )
                      );
                    }}
                    className="btn"
                  />
                )}
                <FontAwesomeIcon
                  icon={faTrashCan}
                  title="Excluir"
                  onClick={() => handleDelete(one_prick_form)}
                  className={classes.action}
                />
              </div>
            ))}
        </div>
      </div>
      {!prick_form_selected.patient_id && (
        <Button onClick={newPrickFormHandler}>Criar Novo Prick</Button>
      )}
      {prick_form_selected.patient_id && (
        <Button onClick={cancelPrickFormHandler}>Cancelar Prick</Button>
      )}
    </div>
  );
}

export default SelectPrickForm;

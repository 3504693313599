export const data = {
  identifier: "regional",
  subtitle: "BATERIA REGIONAL AMÉRICA",
  form_title: "REGIONAL",
  number: "(10 SUBSTÂNCIAS)",
  initialShow: false,
  sensitizers: [
    {
      measurement_label: "R1",
      patch_test_info_identifier:"regional",
    },
    {
      measurement_label: "R2",
      patch_test_info_identifier:"regional",
    },
    {
      measurement_label: "R3",
      patch_test_info_identifier:"regional",
    },
    {
      measurement_label: "R4",
      patch_test_info_identifier:"regional",
    },
    {
      measurement_label: "R5",
      patch_test_info_identifier:"regional",
    },
    {
      measurement_label: "R6",
      patch_test_info_identifier:"regional",
    },
    {
      measurement_label: "R7",
      patch_test_info_identifier:"regional",
    },
    {
      measurement_label: "R8",
      patch_test_info_identifier:"regional",
    },
    {
      measurement_label: "R9",
      patch_test_info_identifier:"regional",
    },
    {
      measurement_label: "R10",
      patch_test_info_identifier:"regional",
    },
  ],
};

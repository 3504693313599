export const data = {
  identifier: "apt10",
  subtitle: "BATERIA ATÓPICA",
  form_title: "ATÓPICA-10",
  number: "(10 SUBSTÂNCIAS)",
  initialShow: false,
  sensitizers: [
    {
      measurement_label: "AT1",
      patch_test_info_identifier: "apt10",
    },
    {
      measurement_label: "AT2",
      patch_test_info_identifier: "apt10",
    },
    {
      measurement_label: "AT3",
      patch_test_info_identifier: "apt10",
    },
    {
      measurement_label: "AT4",
      patch_test_info_identifier: "apt10",
    },
    {
      measurement_label: "AT5",
      patch_test_info_identifier: "apt10",
    },
    {
      measurement_label: "AT6",
      patch_test_info_identifier: "apt10",
    },
    {
      measurement_label: "AT7",
      patch_test_info_identifier: "apt10",
    },
    {
      measurement_label: "AT8",
      patch_test_info_identifier: "apt10",
    },
    {
      measurement_label: "AT9",
      patch_test_info_identifier: "apt10",
    },
    {
      measurement_label: "AT10",
      patch_test_info_identifier: "apt10",
    },
  ],
};

import { createSlice } from "@reduxjs/toolkit";

const printedSlice = createSlice({
  name: "printed",
  initialState: {
    date: "",
    city: "",
    selected_printed: [],
  },
  reducers: {
    setToAllergensReport(state, action) {
      state.date = action.payload.date;
      state.city = action.payload.city;
      state.selected_printed = action.payload.selected_printed;
    },
  },
});

export const printedActions = printedSlice.actions;

export default printedSlice;

import { data as DATA_Antiflamatorios } from "./DATA_Antiflamatorios";
import { data as DATA_Alimentos } from "./DATA_Alimentos";
import { data as DATA_APT4 } from "./DATA_APT4";
import { data as DATA_APT10 } from "./DATA_APT10";
import { data as DATA_Calcados } from "./DATA_Calcados";
import { data as DATA_Capilar } from "./DATA_Capilar";
import { data as DATA_Cosmeticos_Higiene_Pessoal } from "./DATA_Cosmeticos_Higiene_Pessoal";
import { data as DATA_Padrao_Brasileiro } from "./DATA_Padrao_Brasileiro";
import { data as DATA_Pediatrica } from "./DATA_Pediatrica";
import { data as DATA_Regional } from "./DATA_Regional";
import { data as DATA_Unhas } from "./DATA_Unhas";

const data_array = [
  DATA_Padrao_Brasileiro,
  DATA_Cosmeticos_Higiene_Pessoal,
  DATA_Pediatrica,
  DATA_Capilar,
  DATA_Unhas,
  DATA_Regional,
  DATA_Calcados,
  DATA_APT4,
  DATA_APT10,
  DATA_Alimentos,
  DATA_Antiflamatorios,
];

export default data_array;

import React from "react";
import classes from "./SelectImmunotherapy.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { imunoActions } from "../../../store/imuno-slice";
import {
  deleteOneImmunotherapy,
  downloadImmunotherapyPDF,
  fetchAllImmunotherapies,
  sendNewImmunotherapy,
} from "../../../store/imuno-actions";
import { formatIsoDateStringToCustom } from "../../../util/stringFormaters";
import { confirm } from "../../../util/Confirmation";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faFile } from "@fortawesome/free-solid-svg-icons";

function SelectImmunotherapy() {
  const dispatch = useDispatch();
  const all_immunotherapies = useSelector(
    (state) => state.imuno.all_immunotherapies
  );
  const selected_immunotherapy = useSelector(
    (state) => state.imuno.selected_immunotherapy
  );
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );

  const need_fetch = useSelector((state) => state.imuno.need_fetch);
  const need_download = useSelector((state) => state.imuno.need_download);
  const token = useSelector((state) => state.login.token);
  const selected_clinic = useSelector((state) => state.clinic.selected_clinic);

  useEffect(() => {
    if (need_fetch) {
      dispatch(fetchAllImmunotherapies(token));
    }
  }, [dispatch, need_fetch, token]);

  useEffect(() => {
    if (need_download !== false) {
      dispatch(downloadImmunotherapyPDF(selected_immunotherapy, token));
    }
  }, [dispatch, need_download, selected_immunotherapy, token]);

  useEffect(() => {
    dispatch(imunoActions.selectImmunotherapy([]));
  }, [dispatch, selected_patient]);

  const handleDelete = async (one_immunotherapy) => {
    if (await confirm("Tem certeza que deseja excluir esse formulário?")) {
      dispatch(deleteOneImmunotherapy(one_immunotherapy, token));
    }
  };

  function new_form_handler() {
    const immunotherapy = {
      patient_id: selected_patient.id,
      sublingual_drops: 3,
      clinic_id: selected_clinic && selected_clinic.id,
      city: selected_clinic.city,
      signature_date: new Date().toISOString().split("T")[0],
    };

    dispatch(sendNewImmunotherapy(immunotherapy, token));
  }

  return (
    <div className={classes.container}>
      <div className={classes.list_border}>
        <div className={classes.immunotherapy_list}>
          {all_immunotherapies.length > 0 &&
            all_immunotherapies
              .filter(
                (one_immuno) => one_immuno.patient_id === selected_patient.id
              )
              .map((one_immunotherapy, index) => (
                <div
                  onClick={() =>
                    dispatch(
                      imunoActions.selectImmunotherapy(one_immunotherapy)
                    )
                  }
                  className={`${classes.one_immunotherapy} ${
                    selected_immunotherapy.id === one_immunotherapy.id
                      ? classes.selected
                      : ""
                  }`}
                  key={index}
                >
                  <div className={classes.item_text}>
                    {"Imunoterapia de "}
                    {one_immunotherapy.name}
                    {" em "}
                    {formatIsoDateStringToCustom(one_immunotherapy.updated_at)}
                  </div>
                  {one_immunotherapy.pdf && (
                    <FontAwesomeIcon
                      icon={faFile}
                      title="Fazer Download"
                      onClick={() =>
                        dispatch(
                          downloadImmunotherapyPDF(one_immunotherapy, token)
                        )
                      }
                      className={classes.action}
                    />
                  )}
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    title="Excluir"
                    onClick={() => handleDelete(one_immunotherapy)}
                    className={classes.action}
                  />
                </div>
              ))}
        </div>
      </div>
      {selected_patient.id && (
        <Button onClick={new_form_handler}>Criar Novo Formulário</Button>
      )}
    </div>
  );
}
export default SelectImmunotherapy;

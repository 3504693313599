import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import classes from "./Notification.module.css";
import gear_img from "../../images/gear.png";

function Notification() {
  const notification = useSelector((state) => state.ui.notification);
  const [stateShow, setShow] = useState(false);

  var delayMs = 3000;

  if (notification) {
    var variant;
    switch (notification.status) {
      case "success":
        variant = "success";
        delayMs = 3000;
        break;
      case "pending":
        variant = "info";
        delayMs = -1;
        break;
      case "error":
        variant = "danger";
        delayMs = 10000;
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    setShow(true);
    if (delayMs > 0) {
      const timeoutId = setTimeout(() => {
        setShow(false);
      }, delayMs);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [notification, delayMs]);

  return (
    <div className={classes.container}>
      {notification && stateShow && (
        <Alert variant={variant}>
          <strong>{notification.title}</strong> {notification.message}
        </Alert>
      )}
      {notification && notification.status === "pending" && (
        <div className={classes.modal_background}>
          <img src={gear_img} alt="loading" className={classes.rotate} />
        </div>
      )}
    </div>
  );
}

export default Notification;

import { createSlice } from "@reduxjs/toolkit";

const prescriptionSlice = createSlice({
  name: "prescription",
  initialState: {
    all_prescriptions: [],
    selected_prescription: "",
    need_fetch: true,
    need_download: false,
    name: "",
  },
  reducers: {
    setAllPrescriptions(state, action) {
      state.all_prescriptions = action.payload;
      state.need_fetch = false;
    },
    selectPrescription(state, action) {
      state.selected_prescription = action.payload;
    },
    addOnePrescription(state, action) {
      state.all_prescriptions.unshift(action.payload);
      state.selected_prescription = action.payload;
    },
    removeOnePrescription(state, action) {
      const idx = state.all_prescriptions.findIndex(
        (prescription) => prescription.id === action.payload.id
      );
      state.all_prescriptions.splice(idx, 1);
      state.selected_prescription = "";
    },
    updateOnePrescription(state, action) {
      const idx = state.all_prescriptions.findIndex(
        (prescription) => prescription.id === action.payload.id
      );
      state.all_prescriptions.splice(idx, 1, action.payload);
      state.selected_prescription = action.payload;
    },
    needDownload(state, action) {
      state.need_download = action.payload;
    },
    setClinicId(state, action) {
      state.selected_prescription.clinic_id = action.payload;
    },
    setOralUse(state, action) {
      state.selected_prescription.oral_use = action.payload;
    },
    setExternalUse(state, action) {
      state.selected_prescription.external_use = action.payload;
    },
    setInhalationUse(state, action) {
      state.selected_prescription.inhalation_use = action.payload;
    },
    setInjectableUse(state, action) {
      state.selected_prescription.injectable_use = action.payload;
    },
    setProtocol(state, action) {
      state.selected_prescription.protocol = action.payload;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setReceituaraio(state, action) {
      state.selected_prescription.recipe = action.payload;
    },
  },
});

export const prescriptionActions = prescriptionSlice.actions;

export default prescriptionSlice;

function sliceIntoChuncks(array, chunckSize) {
  const chunckArray = [];
  for (let i = 0; i < array.length; i += chunckSize) {
    const chunck = array.slice(i, i + chunckSize);
    chunckArray.push(chunck);
  }
  return chunckArray;
}

export default sliceIntoChuncks;

import classes from "./HeaderInput.module.css";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import DateInput from "./DateInput";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dateFormater, formatDatetoBr } from "../../../../util/stringFormaters";
import { allergensActions } from "../../../../store/allergens-slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faForward, faPlay } from "@fortawesome/free-solid-svg-icons";

function HeaderInput() {
  const dispatch = useDispatch();

  const patch_form_selected = useSelector(
    (state) => state.allergens.patch_form_selected
  );
  const clinics = useSelector((state) => state.clinic.clinics);

  const [stateDefault1Read, setDefault1Read] = useState("");
  const [stateDefault2Read, setDefault2Read] = useState("");
  const [stateApplication, setApplication] = useState("");
  const [stateSignatureDate, setSignatureDate] = useState("");

  useEffect(() => {
    setDefault1Read(
      patch_form_selected.first_measurement != null
        ? formatDatetoBr(patch_form_selected.first_measurement)
        : ""
    );
    setDefault2Read(
      patch_form_selected.second_measurement !== null
        ? formatDatetoBr(patch_form_selected.second_measurement)
        : ""
    );
    setApplication(
      patch_form_selected.application !== null
        ? formatDatetoBr(patch_form_selected.application)
        : ""
    );
    setSignatureDate(
      patch_form_selected.signature_date !== null
        ? formatDatetoBr(patch_form_selected.signature_date)
        : ""
    );
  }, [patch_form_selected]);

  function dateApplicationHandler(formatedDate) {
    setApplication(formatedDate);

    const first = addDaysToBrazilianDate(formatedDate, 2);
    first && setDefault1Read(first);
  }

  function addDaysToBrazilianDate(BrDate, daysToAdd) {
    const day = parseInt(BrDate.slice(0, 2));
    const month = parseInt(BrDate.slice(3, 5)) - 1; // Months start at 0!
    const year = parseInt(BrDate.slice(6));
    const dateD2 = new Date(year, month, day + daysToAdd);
    const yyyy = dateD2.getFullYear();
    let mm = dateD2.getMonth() + 1; // Months start at 0!
    let dd = dateD2.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const input_formated = dd + "/" + mm + "/" + yyyy;
    if (dd && BrDate.length === 10) {
      return input_formated;
    } else {
      return "";
    }
  }

  return (
    <div className={classes.header_input_container}>
      <div className={classes.row}>
        <div className={classes.item}>
          <Form.Label htmlFor="patch_patient">Paciente:</Form.Label>
          <Form.Control
            type="text"
            id="patch_patient"
            placeholder="Paciente"
            value={patch_form_selected.name}
            onChange={() => {}}
            className={classes.text_input}
            disabled
          ></Form.Control>
        </div>
        <div className={classes.item}>
          <Form.Label htmlFor="patch_dn">DN:</Form.Label>
          <Form.Control
            type="text"
            id="patch_dn"
            placeholder="Data de Nascimento"
            value={formatDatetoBr(
              patch_form_selected.birthday === null
                ? ""
                : patch_form_selected.birthday
            )}
            onChange={() => {}}
            className={classes.text_input}
            disabled
          ></Form.Control>{" "}
        </div>
        <div className={classes.item}>
          <Form.Label htmlFor="patch_requester">Solicitante:</Form.Label>
          <Form.Control
            type="text"
            id="patch_requester"
            placeholder="Solicitante"
            value={patch_form_selected.requester}
            className={classes.text_input}
            onChange={(e) =>
              dispatch(allergensActions.setRequester(e.target.value))
            }
          ></Form.Control>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.item}>
          <Form.Label htmlFor="patch_clinic">Clinica:</Form.Label>
          <Form.Select
            id="patch_clinic"
            onChange={(e) => {
              dispatch(
                allergensActions.setCity(
                  clinics.find(
                    (clinic) => clinic.id === parseInt(e.target.value)
                  ).city
                )
              );
            }}
            defaultValue={patch_form_selected.clinic_id}
          >
            {clinics.map((clinic, index) => (
              <option key={index} value={clinic.id}>
                {clinic.acronym + " - " + clinic.name}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className={classes.item}>
          <Form.Label>Aplicação:</Form.Label>
          <FontAwesomeIcon
            className={classes.action}
            icon={faClock}
            title="Atualizar Data"
            onClick={() => {
              dateApplicationHandler(dateFormater(new Date()));
            }}
          />
          <DateInput
            id="patch_application"
            with_date_picker={true}
            default_value={stateApplication}
            onChangeHandler={dateApplicationHandler}
          />
        </div>
        <div className={classes.item}>
          <DateInput
            id="patch_batch1"
            label="1ª Leitura:"
            with_date_picker={true}
            default_value={stateDefault1Read}
          />
        </div>
        <div className={classes.item}>
          <Form.Label>2ª Leitura:</Form.Label>
          <FontAwesomeIcon
            className={classes.action}
            icon={faPlay}
            title="Avançar 1 dia"
            onClick={() => {
              const second = addDaysToBrazilianDate(stateDefault1Read, 1);
              second && setDefault2Read(second);
            }}
          />
          <FontAwesomeIcon
            className={classes.action}
            icon={faForward}
            title="Avançar 2 dias"
            onClick={() => {
              const second = addDaysToBrazilianDate(stateDefault1Read, 2);
              second && setDefault2Read(second);
            }}
          />
          <DateInput
            id="patch_batch2"
            with_date_picker={true}
            default_value={stateDefault2Read}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.item}>
          <Form.Label htmlFor="patch_city">Local:</Form.Label>
          <Form.Control
            type="text"
            id="patch_city"
            onChange={(e) => dispatch(allergensActions.setCity(e.target.value))}
            value={patch_form_selected.city}
            disabled
          ></Form.Control>
        </div>
        <div className={classes.item}>
          <Form.Label>Data:</Form.Label>
          <FontAwesomeIcon
            className={classes.action}
            icon={faClock}
            title="Atualizar Data"
            onClick={() => {
              setSignatureDate(dateFormater(new Date()));
            }}
          />
          <DateInput
            id="patch_signature_date"
            with_date_picker={true}
            default_value={stateSignatureDate}
            onChangeHandler={(value) => setSignatureDate(value)}
          />
        </div>
      </div>
    </div>
  );
}

export default HeaderInput;

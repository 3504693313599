import { uiActions } from "./ui-slice";
import { convertToFileName } from "../util/stringFormaters";
import { solicitacaoActions } from "./solicitacao-slice";

const API_URL_ORDERS = process.env.REACT_APP_API_URL + "/orders";

export const fetchAllSolicitacoes = (token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(API_URL_ORDERS, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Could not fetch solicitacao data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const all_solicitacoes_received = await fetchData();
      dispatch(
        solicitacaoActions.setAllSolicitacoes(all_solicitacoes_received)
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao buscar dados das solicitações!",
        })
      );
    }
  };
};

export const sendNewOrder = (orderToSend, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Cadastrando...",
        message: "Registrando nova solicitação!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_ORDERS + ".json", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(orderToSend),
      });

      if (!response.ok) {
        throw new Error("Falha ao registrar nova solicitação.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const data = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Success!",
          message: "Solicitação registrada com sucesso!",
        })
      );
      dispatch(solicitacaoActions.addOneSolicitacao(data));
      dispatch(solicitacaoActions.selectOrder(data));
      dispatch(solicitacaoActions.needDownload(true));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Falha ao registrar nova solicitação.",
        })
      );
    }
  };
};

export const downloadSolicitacaoPDF = (order, fileName, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Download...",
        message: "Realizando Download de Solicitação",
      })
    );
    const fetchData = async () => {
      const response = await fetch(API_URL_ORDERS + "/" + order.id + ".pdf", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error("Could not download order pdf!");
      }

      const blob = await response.blob();

      // Create a temporary URL object
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${convertToFileName(fileName)}.pdf`); // Set the filename for the downloaded file
      // Set appropriate 'as' value for the resource based on its type
      link.setAttribute("as", "document"); // For PDF files, use 'document' as the 'as' value
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up the temporary URL object
      window.URL.revokeObjectURL(url);

      return response;
    };

    try {
      await fetchData();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Download de solicitação realizado com sucesso!",
        })
      );
      dispatch(solicitacaoActions.needDownload(false));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao fazer download de Solicitação!",
        })
      );
    }
  };
};

export const deleteOneSolicitacao = (order, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Excluindo...",
        message: "Solicitação sendo excluída.",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_ORDERS + "/" + order.id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: "",
      });

      if (!response.ok) {
        throw new Error("Falha ao excluir solicitação.");
      }

      await response.json();

      return;
    };

    try {
      await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Secesso!",
          message: "Solicitação excluída com sucesso!",
        })
      );
      dispatch(solicitacaoActions.removeOneSolicitacao(order));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao excluír solicitação.",
        })
      );
    }
  };
};

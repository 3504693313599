import React from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sendNewPatient } from "../../store/patients-actions";
import { patientsActions } from "../../store/patients-slice";
import Modal from "react-bootstrap/Modal";
import { formatDatefromBr } from "../../util/stringFormaters";
import PacientForm from "./PatientForm";
import PacientFormMinimum from "./PatientFormMinimum";

function PacientFormNew({ minimum = false }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const name_typing = useSelector((state) => state.patients.name_typing);
  const cpf_typing = useSelector((state) => state.patients.cpf_typing);

  const patient = {
    name: name_typing,
    birthday: "",
    cpf: cpf_typing,
    gender: "",
    contact: "",
    weight: "",
    height: "",
    responsable_name: "",
    responsable_degree: "",
  };

  function handleSubmit(event) {
    event.preventDefault();
    const updated_patient = {
      name: event.target.name.value,
      birthday: formatDatefromBr(event.target.birthday.value),
      cpf: event.target.cpf.value,
      weight: event.target.weight.value,
      height: event.target.height.value,
      gender: event.target.gender ? event.target.gender.value : "",
      origin: event.target.origin ? event.target.origin.value : "",
      contact: event.target.contact ? event.target.contact.value : "",
      responsable_name: event.target.responsable_name
        ? event.target.responsable_name.value
        : "",
      responsable_degree: event.target.responsable_degree
        ? event.target.responsable_degree.value
        : "",
      obs: event.target.obs ? event.target.obs.value : "",
    };
    dispatch(sendNewPatient(updated_patient, token));
  }

  const enableEscape = true;
  const showNewForm = useSelector((state) => state.patients.showNewForm);

  return (
    <Modal
      animation={true}
      show={showNewForm}
      onHide={() => dispatch(patientsActions.setShowNewForm(false))}
      backdrop={"static"}
      keyboard={enableEscape}
    >
      <Modal.Header>
        <Modal.Title>Novo Cadastro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {minimum ? (
            <PacientFormMinimum patient={patient} />
          ) : (
            <PacientForm patient={patient} />
          )}
          <Modal.Footer>
            <Button
              onClick={() => dispatch(patientsActions.setShowNewForm(false))}
            >
              Cancelar
            </Button>
            <Button type="submit">Cadastrar Novo Paciente</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default PacientFormNew;

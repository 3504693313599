export const lab_options = [
  "Hemograma",
  "IgE total",
  "PCR",
  "VHS",
  "IgE específico para D.pteronyssinus (d1)",
  "IgE específico para D.farinae (d2)",
  "IgE específico para B.tropicalis (d201)",
  "Hemoglobina glicada (HbA1c)",
  "Anti-TPO",
  "FAN",
  "Fator Reumatoide",
  "IgA total",
  "IgM total",
  "IgG total",
  "IgG1, IgG2, IgG3 e IgG4",
  "Proteína amiloide A",
  "Procalcitonina",
  "CD3+ - Linfócitos T",
  "CD19 - Linfócitos B",
  "CD4+ - Linfócitos T Helper",
  "CD8+ - Linfócitos T Citotóxicos",
  "CD20 - Linfócitos B Totais",
  "CD16+/CD56+ - Células NK",
  "T4 livre",
  "TSH",
  "ALT",
  "AST",
  "Bilirrubinas",
  "GGT",
  "Fosfatase alcalina",
  "Lipidograma",
  "Eletroforese de proteínas",
  "25-Hidroxivitamina D",
  "Ureia",
  "Creatinina",
  "Glicemia em jejum",
  "VDRL",
  "FTA-Abs IgM e IgG",
  "Anti-HAV total",
  "Anti-HBc total",
  "HBsAg",
  "Anti-HCV",
  "Anti-HIV",
  "Teste de tolerância oral à lactose",
  "Complemento - C3",
  "Complemento - C4",
  "Inibidor de C1 funcional",
  "Inibidor de C1 quantitativo",
  "Antitransglutaminase IgA e IgG",
  "Antiendomísio IgA e IgG",
  "HLA-DQ2 DQ8",
  "Crioglobulinas",
  "Crioaglutininas",
  "Criofibrinogênio",
  "HLA-A*3101",
  "Albumina",
  "Ferro sérico",
  "Ferritina",
  "Saturação da transferrina",
  "Ácido fólico",
  "Vitamina B12",
  "Coagulograma",
  "Sódio",
  "Potássio",
  "Magnésio",
  "IgE múltiplo para Fungos (mx1)",
  "IgE específico para Epitélio de Cão (e5)",
  "IgE específico para Epitélio de Gato (e1)",
  "IgE múltiplo para Gramíneas (gx2)",
  "IgE específico para B.germanica (i6)",
  "IgE específico para Formiga (i70)",
  "IgE específico para Mosquito (i71)",
  "IgE específico para Leite (f2)",
  "IgE específico para Ovo (f245)",
  "IgE específico para Soja (f14)",
  "IgE específico para Trigo (f4)",
  "IgE específico para Amendoim (f13)",
  "IgE específico para Castanha de caju (f202)",
  "IgE específico para Caranguejo (f23)",
  "IgE específico para Camarão (f24)",
  "IgE específico para Peixe (f3)",
  "IgE específico para Látex (K82)",
  "IgE específico para Alfa-lactoalbumina (f76)",
  "IgE específico para Beta-lactoglobulina (f77)",
  "IgE específico para Caseína (f78)",
  "IgE específico para Clara de ovo (f1)",
  "IgE específico para Gema de ovo (f75)",
  "IgE específico para Ovoalbumina (f232)",
  "IgE específico para Ovomucoide (f233)",
  "IgE específico para Gergelim (f10)",
  "IgE específico para Der p 1 (d202)",
  "IgE específico para Der p 2 (d203)",
  "IgE específico para Der p 10 (d205)",
  "IgE específico para Tropomiosina do D.pteronyssinus (d205)",
  "IgE específico para Der p 23 (d209)",
  "IgE específico para P.notatum (m1)",
  "IgE específico para C.herbarum (m2)",
  "IgE específico para A.fumigatus (m3)",
  "IgE específico para C.albicans (m5)",
  "IgE específico para A.alternata (m6)",
  "IgE específico para Can f 1 (e101)",
  "IgE específico para Can f 2 (e102)",
  "IgE específico para Can f 3 (e221)",
  "IgE específico para Can f 5 (e226)",
  "IgE específico para Fel d 1 (e94)",
  "IgE específico para Fel d 2 (e220)",
  "IgE específico para Fel d 4 (e228)",
  "IgE específico para L.perenne (g5)",
  "IgE específico para P.pratense (g6)",
  "IgE específico para P.americana (i206)",
  "IgE específico para Abacate (f96)",
  "IgE específico para Abacaxi (f210)",
  "IgE específico para Banana (f92)",
  "IgE específico para Batata (f35)",
  "IgE específico para Cereja (f242)",
  "IgE específico para Espinafre (f214)",
  "IgE específico para Kiwi (f84)",
  "IgE específico para Melão (f87)",
  "IgE específico para Maracujá (f21)",
  "IgE específico para Manga (f91)",
  "IgE específico para Maçã (f49)",
  "IgE específico para Mamão (f293)",
  "IgE específico para Pera (f94)",
  "IgE específico para Pêssego (f95)",
  "IgE específico para Tomate (f25)",
  " IgE específico para Uva (f259)",
  "IgE específico para pimenta preta (f280)",
  "IgE específico para pimenta vermelha (f263)",
  "IgE específico para pimenta malagueta (f279)",
  "IgE específico para Ácido acetilsalicílico (c207)",
  "IgE específico para Paracetamol (c209)",
  "IgE específico para Diclofenaco (c281)",
  "IgE específico para Dipirona (c294)",
  "IgE específico para Lisozima (k208)",
  "IgE específico para Conalbumina (f323)",
  "IgE espec. para Beta-conglicinina da soja (f431)",
  "IgE específico para PR-10 da soja (f353)",
  "IgE específico para Glicinina da soja (f432)",
  "IgE específico para Ômega-5-gliadina (f416)",
  "IgE específico para Glúten (f79)",
  "IgE específico Paragliadina (f98)",
  "IgE específico para Tri a 14 (f433)",
  "IgE específico para Ara h 1 (f422)",
  "IgE específico para Ara h 2 (f423)",
  "IgE específico para Ara h 3 (f424)",
  "IgE específico para Ara h 8, PR-10 (f352)",
  "IgE específico para Ara h 9, LTP (f427)",
  "IgE específico para Ana o 3 (f443)",
  "IgE específico para Castanha do Pará (f18)",
  "IgE específico para Ber e 1 (f354)",
  "IgE específico para Avelã (f17)",
  "IgE específico para Cor a 1, PR-10 (f428)",
  "IgE específico para Cor a 14 (f439)",
  "IgE específico para Cor a 8, LTP (f425)",
  "IgE específico para Cor a 9 (f440)",
  "IgE específico para Nozes (f256)",
  "IgE específico para Jug r 1 (f441)",
  "IgE específico para Jug r 3, LTP (f442)",
  "IgE específico para Tropomiosina do Camarão (f351)",
  "IgE específico para Tilápia (f414)",
  "IgE específico para Parvalbumina da carpa (f355)",
  "IgE específico para Parvalbumina do bacalhau (f426)",
  "IgE específico para Carne (porco) (f26)",
  "IgE específico para Albumina sérica do porco (e222)",
  "IgE específico para Carne (bovina) (f27)",
  "IgE específico para Alfa-gal (o215)",
  "IgE específico para Carne (frango) (f83)",
  "IgE específico para Milho (f8)",
  "IgE específico para Cacau (f93)",
  "IgE específico para Hev b 1 (k215)",
  "IgE específico para Hev b 3 (k217)",
  "IgE específico para Hev b 5 (k218)",
  "IgE específico para Hev b 6.02 (k220)",
  "IgE específico para Hev b 8 (k221)",
  "IgE específico para Hev b 11 (k224)",
  "MUXF3 CCD (o214)",
  "IgE específico para Enterotoxina A (m80)",
  "IgE específico para Enterotoxina B (m81)",
  "IgE específico para Enterotoxina C (m223)",
  "IgE específico para Enterotoxina TSST (m226)",
  "IgE específico para Abelha (i1)",
  "IgE específico para Api m1 - FosfA2 (i208)",
  "IgE específico para Vespa (i3)",
  " IgE específico para Ves v 1 - FosfA1 (i211)",
  "IgE específico para Ves v 5 (i209)",
  "IgE específico para Marimbondo (i4)",
  "IgE específico para Pol d 5 (i210)",
  "IgE específico para Penicilina G (c1)",
  "IgE específico para Penicilina V (c2)",
  "IgE específico para Ampicilina (c5)",
  "IgE específico para Amoxicilina (c6)",
  "IgE específico para Cefalexina (c309)",
];

export const prick_data = [
  {
    group_name: "Teste da Histamina (duas áreas testadas): 4.14.01.08-5",
    elements: [
      { identifier: "Cpcn", label: "Controle Positivo e Controle Negativo" },
    ],
  },
  {
    group_name:
      "Testes cutâneo-alérgicos para alérgenos da poeira: 4.14.01.36-0",
    elements: [
      { identifier: "Dp", label: "D.pteronyssinus" },
      { identifier: "Df", label: "D.farinae" },
      { identifier: "Bt", label: "B.tropicalis" },
    ],
  },
  {
    group_name: "Testes cutâneo-alérgicos para fungos: 4.14.01.38-7",
    elements: [
      {
        identifier: "Fg",
        label:
          "Fungos do Ar Mix III (A.alternata, C.herbarum, P.notatum e A.fumigatus)",
      },
    ],
  },
  {
    group_name:
      "Testes cutâneo-alérgicos para epitélios de animais: 4.14.01.53-0",
    elements: [
      { identifier: "Ec", label: "Epitélio de Cão (C.familiaris)" },
      { identifier: "Eg", label: "Epitélio de Gato (F.domesticus)" },
      { identifier: "Ba", label: "Baratas Mix (B.germanica e P.americana)" },
    ],
  },
  {
    group_name: "Testes cutâneo-alérgicos para pólens: 4.14.01.40-9",
    elements: [
      {
        identifier: "Po",
        label:
          "Pólens de Gramíneas Mix II (D.glomerata, L.perene e P.pratense)",
      },
    ],
  },
  {
    group_name: "Testes cutâneo-alérgicos para alimentos: 4.14.01.37-9",
    elements: [
      { identifier: "Lv", label: "Leite" },
      { identifier: "Ov", label: "Ovo" },
      { identifier: "Sj", label: "Soja" },
      { identifier: "Tg", label: "Trigo" },
      { identifier: "Am", label: "Amendoim" },
      { identifier: "Ct", label: "Castanhas" },
      { identifier: "Ca", label: "Camarão" },
      { identifier: "Pe", label: "Peixes" },
    ],
  },
  {
    group_name: "Testes cutâneo-alérgicos para insetos: 4.14.01.39-5",
    elements: [
      { identifier: "Cp", label: "Mosquito (C.pipiens)" },
      { identifier: "Si", label: "Formiga (S.invicta)" },
    ],
  },
  {
    group_name: "Teste cutâneo-alérgico para látex: 4.14.01.52-2",
    elements: [{ identifier: "Hb", label: "H.brasiliensis" }],
  },
];
export const prick_titles = [
  {
    title: "Aeroalérgenos",
    elements: ["Dp", "Df", "Bt", "Fg", "Po", "Ba", "Ec", "Eg"],
  },
  {
    title: "Alimentos",
    elements: ["Lv", "Ov", "Sj", "Tg", "Am", "Ct", "Ca", "Pe"],
  },
  {
    title: "Insetos",
    elements: ["Cp", "Si"],
  },
  {
    title: "Látex",
    elements: ["Hb"],
  },
];
export const prick_options = [
  {
    name: "PA101 - Prick Test Tipo 1",
    prick_elements: [
      "Cpcn",
      "Dp",
      "Df",
      "Bt",
      "Fg",
      "Po",
      "Ba",
      "Ec",
      "Eg",
      "Lv",
      "Ov",
      "Sj",
      "Tg",
      "Am",
      "Ct",
      "Ca",
      "Pe",
      "Cp",
      "Si",
    ],
  },
  {
    name: "PA101 - Prick Test Tipo 1 Kids",
    prick_elements: [
      "Cpcn",
      "Dp",
      "Df",
      "Bt",
      "Fg",
      "Ec",
      "Eg",
      "Lv",
      "Ov",
      "Sj",
      "Tg",
      "Am",
      "Ct",
      "Ca",
      "Pe",
      "Cp",
      "Si",
    ],
  },
  {
    name: "PA102 - Prick Test Tipo 2",
    prick_elements: [
      "Cpcn",
      "Dp",
      "Df",
      "Bt",
      "Fg",
      "Po",
      "Ba",
      "Ec",
      "Eg",
      "Lv",
      "Ov",
      "Sj",
      "Tg",
      "Am",
      "Ct",
      "Ca",
      "Pe",
      "Cp",
      "Si",
    ],
  },
  {
    name: "PA102 - Prick Test Tipo 2 Kids",
    prick_elements: [
      "Cpcn",
      "Dp",
      "Df",
      "Bt",
      "Fg",
      "Ec",
      "Eg",
      "Lv",
      "Ov",
      "Sj",
      "Tg",
      "Am",
      "Ct",
      "Ca",
      "Pe",
      "Cp",
      "Si",
    ],
  },
  {
    name: "PA103 - Prick Test Tipo 3",
    prick_elements: [
      "Cpcn",
      "Dp",
      "Df",
      "Bt",
      "Fg",
      "Po",
      "Ba",
      "Ec",
      "Eg",
      "Lv",
      "Ov",
      "Sj",
      "Tg",
      "Am",
      "Ct",
      "Ca",
      "Pe",
      "Cp",
      "Si",
    ],
  },
  {
    name: "PA103 - Prick Test Tipo 3 Kids",
    prick_elements: [
      "Cpcn",
      "Dp",
      "Df",
      "Bt",
      "Fg",
      "Ec",
      "Eg",
      "Lv",
      "Ov",
      "Sj",
      "Tg",
      "Am",
      "Ct",
      "Ca",
      "Pe",
      "Cp",
      "Si",
    ],
  },
];

export const patch_data = [
  {
    test_name: "Bateria Padrão Brasileiro (30 substâncias) 4.14.01.42-5",
    substances:
      "P1 – Antraquinona; P2 – Bálsamo Do Peru; P3 – PPD Mix; P4 – Hidroquinona; P5 – Bicromato de Potássio; P6 – Propilenoglicol; P7 – Butilfenol-P-Terciário; P8 – Neomicina; P9 – Irgasan; P10 – Kathon CG; P11 – Cloreto de Cobalto; P12 – Lanolina; P13 – Tiuram Mix; P14 – Etilenodiamina; P15 – Perfume Mix; P16 – Mercapto Mix; P17 – Benzocaína; P18 – Quaternium 15; P19 – Quinolina Mix; P20 – Nitrofurazona; P21 – Paraben Mix; P22 – Resina Epoxi; P23 – Timerosal; P24 – Terebintina; P25 – Carba Mix; P26 – Prometazina; P27 – Sulfato de Níquel; P28 – Colofônio; P29 – Parafenilenodiamina; P30 – Formaldeído.",
    active: true,
    identifier: "standard",
  },
  {
    test_name:
      "Bateria Cosméticos e Higiene Pessoal (10 substâncias) 4.14.01.75-1",
    substances:
      "C1 – Germal 115; C2 – BHT; C3 – Resina; C4 – Trietanolamina; C5 – Bronopol; C6 – Cloracetamida; C7 – Ácido Sórbico; C8 – Tioglicolato de Amônio; C9 – Amerchol; C10 – Clorexidina.",
    active: true,
    identifier: "cosmetic",
  },
  {
    test_name: "Bateria Pediátrica (20 substâncias)",
    substances:
      "PD1 - Clorexidina; PD2 - Kathon CG; PD3 - Álcool de Lanolina; PD4 - Azul Disperse; PD5 - Bicromato de Potássio; PD6 - Budesonida; PD7 - Butilfenol-P-Formaldeído; PD8 - Colofônio; PD9 - Fito Mix; PD10 - Fragrâncias Mix II; PD11 - Acetato de Hidrocortisona; PD12 - Linalol; PD13 - Mercapto Mix; PD14 - Metildibromo Glutaronitrilo; PD15 - Neomicina; PD16 - Parafenilenodiamina; PD17 - Perfume Mix; PD18 - Sesquiterpeno Lactona Mix; PD19 - Sulfato de Níquel; PD20 - Tiuram Mix",
    active: false,
    identifier: "pediatrician",
  },
  {
    test_name: "Bateria Capilar (15 substâncias) 4.14.01.77-8",
    substances:
      "CA1 – 2-Nitro-Parafenilenodiamina; CA2 – Captan; CA3 – Cloroxilenol (PCMX); CA4 – Cocamidopropilbetaína; CA5 – Decil Glucosídeo; CA6 – Lauril Poliglucose; CA7 – M-Aminofenol; CA8 – P-Aminofenol; CA9 – P-Cloro-M-Cresol; CA10 – Peróxido de Hidrogênio; CA11 – Persulfato de Amônia; CA12 – Piritionato de Zinco; CA13 – Resorcinol; CA14 – Sulfato de Tolueno; CA15 – Tioglicolato de Glicerila.",
    active: false,
    identifier: "capilar",
  },
  {
    test_name: "Bateria Unhas (20 substâncias) 4.14.01.78-6",
    substances:
      "U1 – Acetato de Butila; U2 – Acetato de Etila; U3 – Acrilato de Butila; U4 – Acrilato de Etila; U5 – Acrilato de Hidroxietila; U6 – Cânfora; U7 – Diacrilato de 1,6-Hexanodiol; U8 – Diacrilato de Trietilenoglicol; U9 – Dimetacrilato de Etilenoglicol; U10 – Dimetacrilato de Trietilenoglicol; U11 – Fosfato de Tricresila; U12 – Dibutilftalato; U13 – Metacrilato de 2- Hidroxietila; U14 – Metacrilato de Butila; U15 – Metacrilato de Etila; U16 – Metacrilato de Hidroxipropila; U17 – Metacrilato de Tetrahidrofurfurila; U18 – Mica; U19 – Tolueno; U20 – Triacrilato de Trimetilolpropano.",
    active: false,
    identifier: "nails",
  },
  {
    test_name: "Bateria Regional América (10 substâncias) 4.14.01.76-0",
    substances:
      "R1 – Azul Disperse; R2 – Caína Mix; R3 – Cloreto de Paládio; R4 – Dialquil Tioureia Mix; R5 – Diazolidinil Ureia; R6 – Fragrâncias Mix; R7 – Galato de Octila; R8 – Metildibromo Glutaronitrilo; R9 – Metilisotiazolinona; R10 – Paraformaldeído.",
    active: false,
    identifier: "regional",
  },
  {
    test_name: "Bateria Calçados (13 substâncias)",
    substances:
      "SH1 – Glutaraldeído; SH2 – Laranja Disperso 1; SH3 – Amarelo Ácido 36; SH4 – Monobenzil Éter de Hidroquinona; SH5 – Tetraetiltiuram Dissulfeto; SH6 – Dipentametilenotiuram Dissulfeto; SH7 – N,N’-Difeniltiouréia; SH8 – Dietiltiouréia (DETU); SH9 – N’-Dibutiltiouréia; SH10 – N, Dodecil Mercaptano; SH11 – 4-Aminoazobenzeno; SH12 – N-Octil-4-Isotiazolinona; SH13 – 4-4’-Ditiodimorfolina.",
    active: false,
    identifier: "shoes",
  },
  {
    test_name: "Bateria Atópica Inalantes (4 substâncias)",
    substances:
      "AT1 - <i>D. pteronyssinus + D. farinae</i> (HDM); AT2 - B. tropicalis (SM); AT3 - Fungos do ar (Mix II); AT4 - Controle Negativo",
    active: false,
    identifier: "apt4",
  },
  {
    test_name: "Bateria Atópica (10 substâncias)",
    substances:
      "AT1 - <i>Dermatophagoides pteronyssinus</i>; AT2 - <i>Dermatophagoides farinae</i>; AT3 - <i>Blomia tropicalis</i>; AT4 - Fungos do ar (Mix II) ; AT5 - Leite; AT6 - Amendoim ; AT7 - Caseína; AT8 - Gliadina; AT9 - Clara de ovo; AT10 - Controle Negativo",
    active: false,
    identifier: "apt10",
  },
  {
    test_name: "Bateria Alimentos (10 substâncias)",
    substances:
      "AL1 – Amendoim; AL2 – Caseína; AL3 – Castanhas Mix; AL4 – Clara de Ovo; AL5 – Gema de Ovo; AL6 - Leite de Vaca; AL7 – Milho; AL8 – Soja; AL9 – Trigo; AL10 – Controle Negativo.",
    active: false,
    identifier: "alimentos",
  },
  {
    test_name: "Bateria Anti-Inflamatórios (10 substâncias) 4.14.01.79-4",
    substances:
      "AI1 – Ácido Acetilsalicílico; AI2 – Ibuprofeno; AI3 – Naproxeno; AI4 – Dipirona; AI5 – Nimesulida; AI6 – Cetoprofeno; AI7 – Paracetamol; AI8 – Diclofenaco; AI9 – Tenoxicam; AI10 – Controle Negativo.",
    active: false,
    identifier: "antiflamatorios",
  },
];

export const patch_custom = {
  test_name: "Bateria Personalizada (0 substâncias)",
  substances: "PS1 – AAAAAAAAAAAAAAA; PSx – Controle Negativo.",
  active: false,
};

export const patch_options = [
  "PA201 - Teste de Contato Tipo 1",
  "PA202 - Teste de Contato Tipo 2",
  "PA203 - Teste de Contato Tipo 3",
];

export const espirometria_options = {
  test_name: "PA401 - Prova de função respiratória completa (4.01.05.07-5)",
  text: "Ao realizante: Usar salbultamol spray 200mcg.<br/>Constar CVF, VEF1, VEF<sub>1</sub>/CVF, PFE, FET, FIVC e FEF25-75.",
  to_patient: [
    {
      text: "Suspender o Singulair 02 dias antes do exame.",
      active: false,
      fixed: true,
    },
    {
      text: "Suspender o Montelair 02 dias antes do exame.",
      active: false,
      fixed: true,
    },
    {
      text: "Suspender o Aerolin spray 12h antes do exame.",
      active: false,
      fixed: true,
    },
    {
      text: "Suspender o Seretide spray 24h antes do exame.",
      active: false,
      fixed: true,
    },
  ],
};

export const tic_data = [
  {
    type: "PA301 - TIC (até dois antígenos)",
    options: [
      {
        group_name: "Teste da Histamina (duas áreas testadas): 4.14.01.08-5",
        elements: [
          { name: "Controle Positivo e Controle Negativo", active: true },
        ],
      },
      {
        group_name:
          "Provas imuno-alérgicas para bactérias (por antígeno): 4.14.01.06-9",
        elements: [
          { name: "C.acnes", active: true },
          { name: "M.bovis", active: false },
        ],
      },

      {
        group_name:
          "Provas imuno-alérgicas para fungos (por antígeno): 4.14.01.07-7",
        elements: [
          { name: "Candidina", active: true },
          { name: "Tricofitina", active: false },
        ],
      },

      {
        group_name: "Testes cutâneo-alérgicos para fungos: 4.14.01.38-7",
        elements: [{ name: "C.albicans", active: false }],
      },
    ],
  },
  {
    type: "PA302 - TIC (mais que dois antígenos)",
    options: [
      {
        group_name: "Teste da Histamina (duas áreas testadas): 4.14.01.08-5",
        elements: [
          { name: "Controle Positivo e Controle Negativo.", active: true },
        ],
      },
      {
        group_name:
          "Provas imuno-alérgicas para bactérias (por antígeno): 4.14.01.06-9",
        elements: [
          { name: "C.acnes", active: true },
          { name: "M.bovis", active: true },
        ],
      },

      {
        group_name:
          "Provas imuno-alérgicas para fungos (por antígeno): 4.14.01.07-7",
        elements: [
          { name: "Candidina", active: true },
          { name: "Tricofitina", active: true },
        ],
      },

      {
        group_name: "Testes cutâneo-alérgicos para fungos: 4.14.01.38-7",
        elements: [{ name: "C.albicans", active: true }],
      },
    ],
  },
];

export const cutaneo_medicamentos_options = {
  name: "PA303 - Teste cutâneo de puntura ou intradérmico com medicamentos (até 3 drogas)",
  text: "PA303 - Teste cutâneo de puntura ou intradérmico com medicamentos (até 3 drogas): 2.01.01.37-6",
  drugs: "",
};

export const oral_medicamentos_options = {
  name: "Teste de provocação com medicamentos via oral",
  text: "Teste de provocação com medicamentos via oral: 2.01.01.38-4",
  drugs: "",
};

export const injetavel_medicamentos_options = {
  name: "Teste de provocação medicamentos via injetável",
  text: "Teste de provocação medicamentos via injetável: 2.01.01.39-2",
  drugs: "",
};

export const oral_alimentos_options = {
  name: "Teste de Provocação Oral (TPO) com alimentos",
  text: "Teste de Provocação Oral (TPO) com alimentos: 2.01.01.36-8",
  drugs: "",
};

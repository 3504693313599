import { useDispatch, useSelector } from "react-redux";
import { prescriptionActions } from "../../store/prescription-slice";
import DateInput from "../AllergensComponents/PatchTestComponents/header_footer/DateInput";
import { formatDatefromBr, formatDatetoBr } from "../../util/stringFormaters";

import {
  sendNewPrescription,
  updatePrescription,
} from "../../store/prescription-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import "./PrescriptionForm.css";
import { useEffect, useState } from "react";

function PrescriptionForm() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const selected_prescription = useSelector(
    (state) => state.prescription.selected_prescription
  );

  const [showOralUseState, setShowOralUse] = useState(false);
  const [showExternalUseState, setShowExternalUse] = useState(false);
  const [showInhalationUseState, setShowInhalationUse] = useState(false);
  const [showInjectableUseState, setShowInjectableUse] = useState(false);
  const [showProtocolState, setShowProtocol] = useState(false);

  useEffect(() => {
    if (selected_prescription.oral_use) {
      setShowOralUse(true);
    }
    if (selected_prescription.external_use) {
      setShowExternalUse(true);
    }
    if (selected_prescription.inhalation_use) {
      setShowInhalationUse(true);
    }
    if (selected_prescription.injectable_use) {
      setShowInjectableUse(true);
    }
    if (selected_prescription.protocol) {
      setShowProtocol(true);
    }
  }, [selected_prescription]);

  const init_options = {
    plugins:
      "autolink charmap emoticons lists table wordcount checklist casechange textcolor permanentpen powerpaste advtable autocorrect inlinecss",
    toolbar:
      "customInsertButton indent outdent | undo redo | fontsize | bold italic underline strikethrough forecolor backcolor | align lineheight | numlist bullist | charmap | removeformat",
    content_style:
      "body { margin: 0; padding-top:0.2cm; padding-right:0.8cm; padding-left: 0.7cm; font-size: 10pt; font-family: sans-serif; font-stretch: extra-expanded;} p { margin: 0; }",
    promotion: false,
    branding: false,
    width: "14.8cm",
    height: "7cm",
    setup: (editor) => {
      editor.ui.registry.addButton("customInsertButton", {
        text: "...",
        onAction: function (_) {
          editor.insertContent("<p style='display: flex;'># Item&nbsp;&nbsp;<span style='flex-grow: 1; border-bottom: 1pt dotted;'>&nbsp;</span>&nbsp;&nbsp;Qnt</p>");
        },
      });
    },
  };

  function handleSubmit(event) {
    event.preventDefault();
    const updated_prescription = {
      patient_id: selected_prescription.patient_id,
      clinic_id: selected_prescription.clinic_id,
      recipe: selected_prescription.recipe,
      oral_use: selected_prescription.oral_use,
      external_use: selected_prescription.external_use,
      inhalation_use: selected_prescription.inhalation_use,
      injectable_use: selected_prescription.injectable_use,
      protocol: selected_prescription.protocol,
      signature_date: formatDatefromBr(event.target.signature_date.value),
    };

    if (selected_prescription.id) {
      dispatch(
        updatePrescription(
          updated_prescription,
          selected_prescription.id,
          token
        )
      );
    } else {
      dispatch(sendNewPrescription(updated_prescription, token));
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit} className="container">
        <div className="d-flex align-items-center">
          <h2>Preenchimento da Prescrição:</h2>
          <FontAwesomeIcon
            icon={faEraser}
            title="Apagar"
            onClick={() => {
              dispatch(prescriptionActions.setOralUse(""));
              dispatch(prescriptionActions.setExternalUse(""));
              dispatch(prescriptionActions.setInhalationUse(""));
              dispatch(prescriptionActions.setInjectableUse(""));
              dispatch(prescriptionActions.setProtocol(""));
            }}
            className="btn"
          />
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="recipe"
              checked={selected_prescription.recipe}
              onChange={(e) =>
                dispatch(prescriptionActions.setReceituaraio(e.target.checked))
              }
            />
            <label className="form-check-label " htmlFor="recipe">
              Rx
            </label>
          </div>
        </div>
        <h5
          role="button"
          className="mt-3"
          onClick={() => {
            setShowOralUse(!showOralUseState);
          }}
        >
          USO ORAL
        </h5>
        {showOralUseState && (
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            init={init_options}
            value={selected_prescription.oral_use}
            onEditorChange={(value) =>
              dispatch(prescriptionActions.setOralUse(value))
            }
          />
        )}
        <h5
          role="button"
          className="mt-3"
          onClick={() => {
            setShowExternalUse(!showExternalUseState);
          }}
        >
          USO EXTERNO
        </h5>
        {showExternalUseState && (
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            init={init_options}
            value={selected_prescription.external_use}
            onEditorChange={(value) =>
              dispatch(prescriptionActions.setExternalUse(value))
            }
          />
        )}
        <h5
          role="button"
          className="mt-3"
          onClick={() => {
            setShowInhalationUse(!showInhalationUseState);
          }}
        >
          USO INALATÓRIO
        </h5>
        {showInhalationUseState && (
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            init={init_options}
            value={selected_prescription.inhalation_use}
            onEditorChange={(value) =>
              dispatch(prescriptionActions.setInhalationUse(value))
            }
          />
        )}
        <h5
          role="button"
          className="mt-3"
          onClick={() => {
            setShowInjectableUse(!showInjectableUseState);
          }}
        >
          USO INJETÁVEL
        </h5>
        {showInjectableUseState && (
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            init={init_options}
            value={selected_prescription.injectable_use}
            onEditorChange={(value) =>
              dispatch(prescriptionActions.setInjectableUse(value))
            }
          />
        )}
        <h5
          role="button"
          className="mt-3"
          onClick={() => {
            setShowProtocol(!showProtocolState);
          }}
        >
          PROTOCOLO
        </h5>
        {showProtocolState && (
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            init={init_options}
            value={selected_prescription.protocol}
            onEditorChange={(value) =>
              dispatch(prescriptionActions.setProtocol(value))
            }
          />
        )}
        <div className="my-3 row justify-content-center">
          <div className="col-1">
            <label htmlFor="signature_date">Data:</label>
          </div>
          <div className="col-3">
            <DateInput
              id="signature_date"
              with_date_picker={true}
              default_value={formatDatetoBr(
                selected_prescription.signature_date
              )}
            />
          </div>
          <button className="col-3 btn btn-primary" type="submit">
            Gerar Prescrição
          </button>
        </div>
      </form>
    </>
  );
}

export default PrescriptionForm;

import { useEffect, useState } from "react";
import classes from "./SubstanceInput.module.css";
import chamber_img from "../../../../images/patch_images/chamber_color.png";
import { useSelector } from "react-redux";

function SubstanceInputButtons(props) {
  const options = ["?", "-", "+", "++", "+++"];
  const [stateMeasure1, setMeasure1] = useState(options[1]);
  const [stateMeasure2, setMeasure2] = useState(options[1]);

  const patch_measurements = useSelector(
    (state) => state.allergens.patch_measurements
  );

  useEffect(() => {
    if (patch_measurements.length > 0) {
      const patch_measurement = patch_measurements.find(
        (measurement) =>
          measurement.identifier === props.test_identifier &&
          measurement.label === props.measurement_label
      );

      if (patch_measurement) {
        setMeasure1(patch_measurement.first);
        setMeasure2(patch_measurement.second);
      } else {
        setMeasure1("-");
        setMeasure2("-");
      }
    } else {
      setMeasure1("-");
      setMeasure2("-");
    }
  }, [
    patch_measurements,
    setMeasure1,
    setMeasure2,
    props.measurement_label,
    props.test_identifier,
  ]);

  function buttonPlusHandler(event, stateMeasure, setMeasure) {
    const index = options.findIndex((element) => element === stateMeasure);
    const nextIndex = index + 1 === options.length ? index : index + 1;
    setMeasure(options[nextIndex]);
  }
  function buttonMinusHandler(event, stateMeasure, setMeasure) {
    const index = options.findIndex((element) => element === stateMeasure);
    const nextIndex = index === 0 ? index : index - 1;
    setMeasure(options[nextIndex]);
  }

  return (
    <div className={classes.measurement_container}>
      <img
        src={chamber_img}
        alt="chamber_img"
        className={classes.chamber_img}
      />
      <div className={classes.measurment_label}>{props.measurement_label}</div>
      <div className={classes.values_container}>
        <div
          className={`${classes.value} ${
            stateMeasure1 !== "-" && stateMeasure1 !== "?" && classes.red
          }`}
        >
          {stateMeasure1}
        </div>
        <div
          className={`${classes.value} ${
            stateMeasure2 !== "-" && stateMeasure2 !== "?" && classes.red
          }`}
        >
          {stateMeasure2}
        </div>
      </div>
      <div className={classes.buttons_container}>
        <div
          className={classes.button}
          onClick={(event) =>
            buttonMinusHandler(event, stateMeasure1, setMeasure1)
          }
        />
        <div
          className={classes.button}
          onClick={(event) =>
            buttonPlusHandler(event, stateMeasure1, setMeasure1)
          }
        />
        <div
          className={classes.button}
          onClick={(event) =>
            buttonMinusHandler(event, stateMeasure2, setMeasure2)
          }
        />
        <div
          className={classes.button}
          onClick={(event) =>
            buttonPlusHandler(event, stateMeasure2, setMeasure2)
          }
        />
      </div>
      <input
        className={classes.hidden}
        id={
          "patch_" +
          props.test_identifier +
          props.measurement_label +
          "_measurement_1"
        }
        value={stateMeasure1}
        onChange={() => {}}
      />
      <input
        className={classes.hidden}
        id={
          "patch_" +
          props.test_identifier +
          props.measurement_label +
          "_measurement_2"
        }
        value={stateMeasure2}
        onChange={() => {}}
      />
    </div>
  );
}
export default SubstanceInputButtons;

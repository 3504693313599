import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { solicitacaoActions } from "../../../store/solicitacao-slice";
import classes from "./OthersTable.module.css";
import { Form, Button } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";

function OthersTable() {
  const dispatch = useDispatch();
  const others = useSelector((state) => state.solicitacao.others);
  const others_new = useSelector((state) => state.solicitacao.others_new);
  function clickHandler(element) {
    dispatch(solicitacaoActions.removeOther(element));
  }
  function addOther(e) {
    dispatch(solicitacaoActions.addNewOther());
  }

  return (
    <>
      <Form.Label className={classes.label} htmlFor="lab">
        Outros:
      </Form.Label>

      {others.map((element, index) => (
        <div
          className={classes.element}
          key={index}
          onClick={() => clickHandler(element)}
          dangerouslySetInnerHTML={{ __html: element }}
        ></div>
      ))}

      <div className={classes.row}>
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          init={{
            statusbar: false,
            menubar: false,
            toolbar: false,
            content_style:
              "body { margin: 0; padding-top:0.2cm; padding-right:0.8cm; padding-left: 0.7cm; font-size: 10pt; font-family: sans-serif; font-stretch: extra-expanded;} p { margin: 0; }",
            promotion: false,
            branding: false,
            width: "14.8cm",
            height: "3cm",
          }}
          value={others_new}
          onEditorChange={(value) =>
            dispatch(solicitacaoActions.typeNewOthers(value))
          }
        />
        <div className={classes.button_container}>
          <Button onClick={addOther}>Adicionar</Button>
        </div>
      </div>
      <p
        className="mt-2 btn"
        onClick={() => {
          dispatch(
            solicitacaoActions.typeNewOthers(
              "Exame Micológico Direto + Cultura para Fungos + Antibiograma (Antifungigrama)"
            )
          );
        }}
      >
        Exame Micológico Direto + Cultura para Fungos + Antibiograma
        (Antifungigrama)
      </p>
    </>
  );
}

export default OthersTable;

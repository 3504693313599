import classes from "./ActivateAllergen.module.css";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { prickActions } from "../../../../store/prick-slice";

function ActivateAllergen({ element, onChangeActiveHandler }) {
  const dispatch = useDispatch();

  function checkAtive() {
    dispatch(prickActions.toggleActivePrick(element.identifier));
  }

  return (
    <div className={classes.container}>
      <div
        onClick={checkAtive}
        dangerouslySetInnerHTML={{ __html: element.label }}
      ></div>
      <Form.Check
        className={classes.checkbox}
        type="checkbox"
        name={`prick_${element.identifier}_active`}
        checked={element.active}
        onChange={() => false}
      />
    </div>
  );
}
export default ActivateAllergen;

import React, { useEffect } from "react";
import classes from "./SelectPatchForm.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteOnePatchForm,
  downloadPatchPDF,
  fetchOnePatchFormMeasurements,
} from "../../../../store/allergens-actions";
import { formatIsoDateStringToCustom } from "../../../../util/stringFormaters";
import { confirm } from "../../../../util/Confirmation";
import { allergensActions } from "../../../../store/allergens-slice";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function SelectPatchForm() {
  const dispatch = useDispatch();
  const all_patch = useSelector((state) => state.allergens.all_patch);

  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );
  const patch_form_selected = useSelector(
    (state) => state.allergens.patch_form_selected
  );
  const selected_clinic = useSelector((state) => state.clinic.selected_clinic);
  const token = useSelector((state) => state.login.token);

  useEffect(() => {
    dispatch(allergensActions.setPatchFormSelected([]));
    dispatch(allergensActions.setPatchMeasurements([]));
  }, [dispatch, selected_patient]);

  const handleDelete = async (one_patch_form) => {
    if (await confirm("Tem certeza que deseja excluir esse formulário?")) {
      dispatch(deleteOnePatchForm(one_patch_form, token));
    }
  };

  function newPatchFormHandler() {
    dispatch(
      allergensActions.setPatchFormSelected({
        patient_id: selected_patient.id,
        requester: "DR. CLÉRISTON DOMINGUES",
        clinic_id: selected_clinic.id,
        application: "",
        first_measurement: "",
        second_measurement: "",
        city: selected_clinic.city,
        signature_date: new Date().toISOString().split("T")[0],
        name: selected_patient.name,
        birthday: selected_patient.birthday,
      })
    );
    dispatch(allergensActions.setPatchMeasurements([]));
  }

  function cancelPatchFormHandler(e) {
    dispatch(allergensActions.setPatchFormSelected([]));
    dispatch(allergensActions.setPatchMeasurements([]));
    dispatch(allergensActions.deselectAllPatch());
  }

  return (
    <div className={classes.container}>
      <div className={classes.list_border}>
        <div className={classes.patch_form_list}>
          {all_patch
            .filter(
              (patch_form) => patch_form.patient_id === selected_patient.id
            )
            .map((one_patch_form, index) => (
              <div
                className={`${classes.one_patch_form} ${
                  patch_form_selected.id === one_patch_form.id &&
                  classes.selected
                }`}
                key={index}
              >
                <div
                  onClick={(e) => {
                    dispatch(
                      allergensActions.setPatchFormSelected(one_patch_form)
                    );
                    dispatch(
                      fetchOnePatchFormMeasurements(one_patch_form.id, token)
                    );
                  }}
                  className={classes.item_text}
                >
                  {"Patch de "}
                  {one_patch_form.name}
                  {" criada em "}
                  {formatIsoDateStringToCustom(one_patch_form.updated_at)}
                </div>
                {one_patch_form.pdf && (
                  <FontAwesomeIcon
                    icon={faFile}
                    title="Fazer Download"
                    onClick={() => {
                      dispatch(
                        downloadPatchPDF(
                          one_patch_form,
                          selected_patient.name,
                          token
                        )
                      );
                    }}
                    className="btn"
                  />
                )}
                <FontAwesomeIcon
                  icon={faTrashCan}
                  title="Excluir"
                  onClick={() => handleDelete(one_patch_form)}
                  className={classes.action}
                />
              </div>
            ))}
        </div>
      </div>
      {!patch_form_selected.patient_id && (
        <Button onClick={newPatchFormHandler}>Criar Novo Patch</Button>
      )}
      {patch_form_selected.patient_id && (
        <Button onClick={cancelPatchFormHandler}>Cancelar Patch</Button>
      )}
    </div>
  );
}
export default SelectPatchForm;

import { uiActions } from "./ui-slice";
import { clinicActions } from "./clinics-slice";

const API_URL_CLINICS = process.env.REACT_APP_API_URL + "/clinics";

export const fetchAllClinics = (token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(API_URL_CLINICS, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Could not fetch clinic data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const data_received = await fetchData();
      dispatch(clinicActions.setAllClinics(data_received.clinics));
      dispatch(clinicActions.selectClinic(data_received.active_clinic_id));      
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao buscar dados das clinicas!",
        })
      );
    }
  };
};

export const updateClinic = (clinic, clinic_id, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Atualizando...",
        message: "Atualizando Cadastro de Clinica!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_CLINICS + "/" + clinic_id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(clinic),
      });

      if (!response.ok) {
        throw new Error("Falha ao atualizar cadastro de Imunoterapia.");
      }

      const data = await response.json();

      return data;
    };

    try {
      await sendRequest();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Clínica atualizada com sucesso!",
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao atualizar cadastro de Clínica.",
        })
      );
    }
  };
};

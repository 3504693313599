import React from "react";
import classes from "./Login.module.css";
import { Button, Form } from "react-bootstrap";
import { login } from "../../store/login-actions";
import { useDispatch } from "react-redux";
import { loginActions } from "../../store/login-slice";

function Login() {
  const dispatch = useDispatch();
  const tokenStorage = localStorage.getItem("token");
  if (tokenStorage) {
    dispatch(loginActions.setToken(tokenStorage));
  }

  function submitHandler(e) {
    e.preventDefault();
    dispatch(
      login({
        user: {
          email: e.target.email.value,
          password: e.target.password.value,
        },
      })
    );
  }

  return (
    <div>
      <h2>Login</h2>
      <Form className={classes.container} onSubmit={submitHandler}>
        <div className={classes.group}>
          <Form.Label htmlFor="email">Email:</Form.Label>
          <Form.Control
            type="text"
            id="email"
            placeholder="email"
          ></Form.Control>
        </div>
        <div className={classes.group}>
          <Form.Label htmlFor="password">Senha:</Form.Label>
          <Form.Control
            type="password"
            id="password"
            placeholder="senha"
          ></Form.Control>
        </div>
        <Button type="submit">Logar</Button>
      </Form>
    </div>
  );
}

export default Login;

function ReportLogicMean(formData) {
  const controlExamInputs = {
    positiveControl1: formData.controlExamInputs.positiveControl1,
    positiveControl2: formData.controlExamInputs.positiveControl2,
    positiveMean:
      (formData.controlExamInputs.positiveControl1 +
        formData.controlExamInputs.positiveControl2) /
      2,
    negativeControl1: formData.controlExamInputs.negativeControl1,
    negativeControl2: formData.controlExamInputs.negativeControl2,
    negativeMean:
      (formData.controlExamInputs.negativeControl1 +
        formData.controlExamInputs.negativeControl2) /
      2,
  };

  const measurementInputs = formData.measurementInputs.map((element) => {
    return {
      check: element.check,
      active: element.active,
      identifier: element.identifier,
      measurement_1: element.measurement_1,
      measurement_2: element.measurement_2,
      mean: (element.measurement_1 + element.measurement_2) / 2,
      result:
        ((element.measurement_1 + element.measurement_2) / 2 >= 3 &&
          element.checkPositive == null) ||
        element.checkPositive
          ? "Positive"
          : "Negative",
    };
  });

  const comments = { text: formData.comments.text };

  const headerInputs = {
    patient: formData.headerInputs.patient,
    dn: formData.headerInputs.dn,
    requester: formData.headerInputs.requester,
    clinic: formData.headerInputs.clinic,
    date: formData.headerInputs.date,
    puncture: formData.headerInputs.puncture,
    measurement_time: formData.headerInputs.measurement_time,
  };

  const footerInputs = {
    city: formData.footerInputs.city,
    signature_date: formData.footerInputs.signature_date,
  };

  return {
    controlExamInputs: controlExamInputs,
    measurementInputs: measurementInputs,
    comments: comments,
    headerInputs: headerInputs,
    footerInputs: footerInputs,
  };
}

function readMeasurementInputs(elements_array, data_array) {
  const measurementInputs = elements_array.map((element) => {
    var measurement_1;
    var measurement_2;
    var check;
    var active;
    var checkPositive;

    // eslint-disable-next-line
    eval(
      "measurement_1 = parseFloat(event.target.prick_" +
        element.identifier +
        "_1.value);"
    );
    // eslint-disable-next-line
    eval(
      "measurement_2 = parseFloat(event.target.prick_" +
        element.identifier +
        "_2.value);"
    );
    // eslint-disable-next-line
    eval("check = event.target.prick_" + element.identifier + "_psd.checked;");

    // eslint-disable-next-line
    eval(
      "active = event.target.prick_" + element.identifier + "_active.checked;"
    );

    if (
      data_array
        .find((data) => data.title === "Alimentos")
        .groups_array[0].elements.map((element) => element.identifier)
        .includes(element.identifier)
    ) {
      // eslint-disable-next-line
      eval(
        "checkPositive = event.target.prick_" +
          element.identifier +
          "_checkPositive.checked;"
      );
    } else {
      checkPositive = null;
    }

    return {
      identifier: element.identifier,
      measurement_1: isNaN(measurement_1) ? 0 : measurement_1,
      measurement_2: isNaN(measurement_2) ? 0 : measurement_2,
      check: check,
      active: active,
      checkPositive: checkPositive,
    };
  });
  return measurementInputs;
}
export function handleSubmit(event, data_array, formDataHandler) {
  event.preventDefault();
  const elements_array = [];
  data_array.forEach((data) =>
    data.groups_array.forEach((group) =>
      group.elements.forEach((element) => elements_array.push(element))
    )
  );

  const measurementInputs = readMeasurementInputs(elements_array, data_array);

  const headerInputs = {
    patient: event.target.prick_patient.value,
    dn: event.target.prick_dn.value,
    requester: event.target.prick_requester.value,
    clinic: event.target.prick_clinic.value,
    date: event.target.prick_signature_date.value,
    puncture: event.target.prick_puncture.value,
    measurement_time: event.target.prick_measurement_time.value,
  };

  const footerInputs = {
    city: event.target.prick_city.value,
    signature_date: event.target.prick_signature_date.value,
  };

  const positiveControl1 = parseFloat(
    event.target.prick_positiveControl1.value
  );
  const positiveControl2 = parseFloat(
    event.target.prick_positiveControl2.value
  );
  const negativeControl1 = parseFloat(
    event.target.prick_negativeControl1.value
  );
  const negativeControl2 = parseFloat(
    event.target.prick_negativeControl2.value
  );
  const controlExamInputs = {
    positiveControl1: isNaN(positiveControl1) ? 0 : positiveControl1,
    positiveControl2: isNaN(positiveControl2) ? 0 : positiveControl2,
    negativeControl1: isNaN(negativeControl1) ? 0 : negativeControl1,
    negativeControl2: isNaN(negativeControl2) ? 0 : negativeControl2,
  };

  const comments = {
    text: event.target.prick_comments.value,
  };

  const formDataArray = {
    measurementInputs: measurementInputs,
    headerInputs: headerInputs,
    footerInputs: footerInputs,
    controlExamInputs: controlExamInputs,
    comments: comments,
  };

  const formDataWithResult = ReportLogicMean(formDataArray);

  const prick_measurements = [];

  formDataWithResult.measurementInputs.forEach((measurementInput) => {
    measurementInput.active &&
      prick_measurements.push({
        first: measurementInput.measurement_1,
        second: measurementInput.measurement_2,
        mean: measurementInput.mean,
        result: measurementInput.result,
        psd: measurementInput.check,
        prick_element_info_identifier: measurementInput.identifier,
      });
  });

  const measurement_time_string = new Date(
    event.target.prick_measurement_time.value * 1000
  ).toISOString();
  const puncture_string = new Date(
    event.target.prick_puncture.value * 1000
  ).toISOString();

  const dataToSend = {
    prick_form: {
      clinic_id: parseInt(event.target.prick_clinic.value),
      city: event.target.prick_city.value,
      puncture_time:
        puncture_string.substring(11, 13) +
        "h" +
        puncture_string.substring(14, 16),
      reading_time:
        measurement_time_string.substring(11, 13) +
        "h" +
        measurement_time_string.substring(14, 16),
      signature_date: event.target.prick_signature_date.value,
      requester: event.target.prick_requester.value,
      comments: event.target.prick_comments.value,
      environmental_control: event.target.prick_environment_control.checked,
      eczema: event.target.prick_advices_eczema.checked,
      da_dac: event.target.prick_advices_da_dac.checked,
      positive_control1: controlExamInputs.positiveControl1,
      positive_control2: controlExamInputs.positiveControl2,
      negative_control1: controlExamInputs.negativeControl1,
      negative_control2: controlExamInputs.negativeControl2,
      mean_positive_control:
        (controlExamInputs.positiveControl1 +
          controlExamInputs.positiveControl2) /
        2,
      mean_negative_control:
        (controlExamInputs.negativeControl1 +
          controlExamInputs.negativeControl2) /
        2,
    },
    prick_measurements: prick_measurements,
  };

  formDataHandler(dataToSend);
}

import { uiActions } from "./ui-slice";
import { convertToFileName } from "../util/stringFormaters";

const API_URL_GUIDELINES = process.env.REACT_APP_API_URL + "/guidelines";

export const createGuidelinesPDF = (guidelines, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Download...",
        message: "Realizando Download de Impresso",
      })
    );
    const fetchData = async () => {
      const response = await fetch(API_URL_GUIDELINES + ".pdf", {
        method: "POST",
        headers: {
          Accept: "application/pdf",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(guidelines),
      });

      if (!response.ok) {
        throw new Error("Could not download guidelines pdf!");
      }
      const blob = await response.blob();

      // Create a temporary URL object
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Impressos_${convertToFileName(guidelines.name)}.pdf`
      ); // Set the filename for the downloaded file
      // Set appropriate 'as' value for the resource based on its type
      link.setAttribute("as", "document"); // For PDF files, use 'document' as the 'as' value
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up the temporary URL object
      window.URL.revokeObjectURL(url);

      return response;
    };

    try {
      await fetchData();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Download de impresso realizado com sucesso!",
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao fazer download de impresso!",
        })
      );
    }
  };
};

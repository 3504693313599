import PrescriptionOptionForm from "./PrescriptionOptionForm";
import { useDispatch, useSelector } from "react-redux";
import { updatePrescriptionOption } from "../../../store/prescription_options-actions";

function EditPrescriptionOption({ prescription_option, setShowForm }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);

  function handleSubmit(event) {
    event.preventDefault();
    const updated_prescription_option = {
      id: event.target.id.value,
      body: event.target.body.value,
      title: event.target.title.value,
      category: event.target.category.value,
      order: event.target.order.value,
    };
    dispatch(updatePrescriptionOption(updated_prescription_option, token));
    setShowForm(false);
  }
  return (
    <PrescriptionOptionForm
      prescription_option={prescription_option}
      setShowForm={setShowForm}
      handleSubmit={handleSubmit}
    />
  );
}
export default EditPrescriptionOption;

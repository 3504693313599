import ImonutorapiaForm from "../components/ImunoterapiaComponents/Form/ImonutorapiaForm";
import { useSelector } from "react-redux";
import SelectImmunotherapy from "../components/ImunoterapiaComponents/Form/SelectImmunotherapy";

function ImunoterapiaPage() {
  const showForm = useSelector((state) => state.imuno.showForm);

  return (
    <div className="container">
      <SelectImmunotherapy />
      {showForm && <ImonutorapiaForm />}
    </div>
  );
}
export default ImunoterapiaPage;

export function dateFormater(date) {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "/" + mm + "/" + yyyy;
}

export function formatDatefromBr(br_date) {
  const day = br_date.slice(0, 2);
  const month = br_date.slice(3, 5); // Months start at 0!
  const year = br_date.slice(6);
  return year + "-" + month + "-" + day;
}

export function formatDatetoBr(date) {
  if (date.length > 6) {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7); // Months start at 0!
    const day = date.slice(8);
    return day + "/" + month + "/" + year;
  } else if (date.length > 3) {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7); // Months start at 0!
    return month + "/" + year;
  } else {
    return date;
  }
}

export function formatCPF(v) {
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.slice(0, 11); //Remove tudo após o 11o dígito
  v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
  //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
  return v;
}

export function formatContact(value) {
  value = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
  value = value.slice(0, 11); //Remove tudo após o 11o dígito
  value = value.replace(/(\d{2})(\d)/, "($1) $2"); //Coloca parenteses entre os dois primeiros dígitos e espaço após
  value = value.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca traço antes dos últimos 4 dígitos
  return value;
}

export function formatLeftZeros(value, nDigits) {
  var value_str = String(value);
  var zerosToAdd = nDigits - value_str.length;

  if (zerosToAdd > 0) {
    value_str = "0".repeat(zerosToAdd) + value_str;
  }

  return value_str;
}

export function formatTimeStringToSeconds(timeString) {
  const regexPattern = /^(\d+)(?::|h)(\d+)$/;
  const match = timeString.match(regexPattern);

  if (match) {
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const totalSeconds = hours * 3600 + minutes * 60;
    return totalSeconds;
  } else {
    return null; // Invalid time string format
  }
}

export function formatSecondsToTimeString(sec) {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.round((sec - hours * 3600) / 60);
  return formatLeftZeros(hours, 2) + "h" + formatLeftZeros(minutes, 2);
}

export function dateBRToString(date) {
  const date_array = date.split("/");
  var dd = date_array[0];
  dd = dd === "01" ? "1º" : dd;
  const yyyy = date_array[2];
  const month_idx = parseInt(date_array[1]) - 1;
  const months = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];
  const selected_month = months[month_idx];
  return dd + " de " + selected_month + " de " + yyyy;
}

export function convertToFileName(fullName) {
  // Remove leading and trailing spaces
  fullName = fullName.trim();

  // Replace spaces with underscores
  const fileName = fullName.replace(/\s+/g, "_");

  // Convert to lowercase and remove special characters
  const normalizedFileName = fileName
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  return normalizedFileName;
}

export function numberFormat(number) {
  return new Intl.NumberFormat("pt-BR").format(number);
}

export function replace_space_with_html(text) {
  const regex1 = /(?<=>)(&nbsp;)*\s+[^<]*(?=<)/g; // space in the start
  const regex2 = /(?<=>)[^<]*\s{2,}(?=<)/g; // space in the middle
  const text_replaced = text
    .replace(regex1, (match) => {
      return match.replace(/^((&nbsp;)*)(\s)+/g, "$1&nbsp;");
    })
    .replace(regex2, (match) => {
      return match.replace(/\s{2,}/g, "&nbsp;&nbsp;");
    });
  return text_replaced;
}


export function formatIsoDateStringToCustom(isoDateString) {
  // Parse the ISO string into a Date object
  const date = new Date(isoDateString);

  // Adjust for the timezone offset (-3 hours for UTC-3)
  // Note: getTimezoneOffset returns the difference in minutes between the local time and UTC,
  // so we subtract to apply the desired offset.
  const adjustedDate = new Date(date.getTime() - 3 * 60 * 60 * 1000);

  // Extract individual components
  const day = adjustedDate.getUTCDate().toString().padStart(2, "0");
  const month = (adjustedDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const year = adjustedDate.getUTCFullYear();
  const hours = adjustedDate.getUTCHours().toString().padStart(2, "0");
  const minutes = adjustedDate.getUTCMinutes().toString().padStart(2, "0");

  // Format the date
  const formattedDate = `${day}/${month}/${year} às ${hours}h${minutes}min`;

  return formattedDate; // Output: "06 03 2024 14 05 58"
}
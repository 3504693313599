import { createSlice } from "@reduxjs/toolkit";

const prescription_optionsSlice = createSlice({
  name: "prescription_options",
  initialState: {
    need_fetch: true,
    all_prescription_options: [],
  },
  reducers: {
    setAllPrescriptionOptions(state, action) {
      state.all_prescription_options = action.payload;
      state.need_fetch = false;
    },
    addOnePrescriptionOption(state, action) {
      state.all_prescription_options.unshift(action.payload);
      state.all_prescription_options = state.all_prescription_options.sort(
        (a, b) => a.order - b.order
      );
      state.all_prescription_options.map((option, index) => {
        return (option.order = index + 1);
      });
    },
    updateOnePrescriptionOption(state, action) {
      const idx = state.all_prescription_options.findIndex(
        (prescription_option) => prescription_option.id === action.payload.id
      );
      state.all_prescription_options.splice(idx, 1);
      // Insert the element at the new position
      state.all_prescription_options.splice(
        action.payload.order - 1,
        0,
        action.payload
      );

      state.all_prescription_options.map((option, index) => {
        return (option.order = index + 1);
      });
    },
    removeOnePrescriptionOption(state, action) {
      const idx = state.all_prescription_options.findIndex(
        (prescription_option) => prescription_option.id === action.payload.id
      );
      state.all_prescription_options.splice(idx, 1);
      state.all_prescription_options.map((option, index) => {
        return (option.order = index + 1);
      });
    },
  },
});

export const prescription_optionsActions = prescription_optionsSlice.actions;

export default prescription_optionsSlice;

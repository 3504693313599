import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { solicitacaoActions } from "../../../store/solicitacao-slice";
import classes from "./EspirometriaTable.module.css";
import { Button, Form } from "react-bootstrap";

function EspirometriaTable() {
  const dispatch = useDispatch();
  const espirometria_new = useSelector(
    (state) => state.solicitacao.espirometria_new
  );

  const espirometria_options = useSelector(
    (state) => state.solicitacao.espirometria_options
  );

  function clickHandler(one_option) {
    dispatch(solicitacaoActions.toggleEspirometriaActive(one_option));
  }

  function espirometria_new_text_handler(e) {
    dispatch(solicitacaoActions.typeNewEspirometria(e.target.value));
  }
  function addEspirometria(e) {
    dispatch(solicitacaoActions.addNewEspirometria());
  }
  return (
    <>
      {espirometria_options.to_patient.map((one_option, index) => (
        <div
          className={`${classes.element} ${
            !one_option.active && classes.inactive
          }`}
          key={index}
          onClick={() => clickHandler(one_option)}
        >
          {one_option.text}
        </div>
      ))}
      <div className={classes.row}>
        <Form.Control
          type="text"
          id="new_espirometria"
          placeholder="Observações"
          onChange={espirometria_new_text_handler}
          value={espirometria_new}
        ></Form.Control>
        <div className={classes.button_container}>
          <Button onClick={addEspirometria}>Adicionar</Button>
        </div>
      </div>
    </>
  );
}

export default EspirometriaTable;

import { uiActions } from "./ui-slice";
import { prescription_optionsActions } from "./prescription_options-slice";

const API_URL_PRESCRIPTION_OPTIONS =
  process.env.REACT_APP_API_URL + "/prescription_options";

export const fetchAllPrescriptionOptions = (token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(API_URL_PRESCRIPTION_OPTIONS, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error("Could not fetch prescription option data!");
      }

      const data = await response.json();

      return data;
    };

    try {
      const all_prescription_options_received = await fetchData();
      dispatch(
        prescription_optionsActions.setAllPrescriptionOptions(
          all_prescription_options_received
        )
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao buscar opção de receita!",
        })
      );
    }
  };
};

export const sendNewPrescriptionOption = (newPrescriptionOption, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Cadastrando...",
        message: "Cadastrando nova Opção de Receita!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(API_URL_PRESCRIPTION_OPTIONS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newPrescriptionOption),
      });

      if (!response.ok) {
        throw new Error("Falha ao cadastrar nova Opção de Receita.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const new_prescription_option_received = await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Opção de Receita cadastrada com sucesso!",
        })
      );
      dispatch(
        prescription_optionsActions.addOnePrescriptionOption(
          new_prescription_option_received
        )
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Falha ao cadastrar nova opção de receita.",
        })
      );
    }
  };
};

export const updatePrescriptionOption = (
  prescription_option,
  token
) => {
  const prescription_option_id = prescription_option.id;
  delete prescription_option.id;
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Atualizando...",
        message: "Atualizando Cadastro do Imunoterapia!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(
        API_URL_PRESCRIPTION_OPTIONS + "/" + prescription_option_id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(prescription_option),
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao atualizar cadastro de opção de receita.");
      }

      const data = await response.json();

      return data;
    };

    try {
      const prescription_option_data_received = await sendRequest();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Opção de receita atualizada com sucesso!",
        })
      );
      dispatch(
        prescription_optionsActions.updateOnePrescriptionOption(
          prescription_option_data_received
        )
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Erro!",
          message: "Falha ao atualizar cadastro de opção de receita.",
        })
      );
    }
  };
};

export const deleteOnePrescriptionOption = (prescription_options, token) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Excluindo...",
        message: "Cadastro Excluído!",
      })
    );

    const sendRequest = async () => {
      const response = await fetch(
        API_URL_PRESCRIPTION_OPTIONS + "/" + prescription_options.id,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: "",
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao excluir cadastro de opção de receita.");
      }

      await response.json();

      return;
    };

    try {
      await sendRequest();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Sucesso!",
          message: "Opção de Receita excluída com sucesso!",
        })
      );
      dispatch(
        prescription_optionsActions.removeOnePrescriptionOption(
          prescription_options
        )
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Falha ao excluír cadastro de opção de receita.",
        })
      );
    }
  };
};

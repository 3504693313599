import classes from "./ExamControl.module.css";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function ExamControl() {
  const prick_form_selected = useSelector(
    (state) => state.prick.prick_form_selected
  );
  const [statePositive1, setPositive1] = useState(
    prick_form_selected.positive_control1 != null
      ? prick_form_selected.positive_control1
      : 0
  );
  const [statePositive2, setPositive2] = useState(
    prick_form_selected.positive_control2 != null
      ? prick_form_selected.positive_control2
      : 0
  );
  const [stateNegative1, setNegative1] = useState(
    prick_form_selected.negative_control1 != null
      ? prick_form_selected.negative_control1
      : 0
  );
  const [stateNegative2, setNegative2] = useState(
    prick_form_selected.negative_control2 != null
      ? prick_form_selected.negative_control2
      : 0
  );

  useEffect(() => {
    setPositive1(
      prick_form_selected.positive_control1 != null
        ? prick_form_selected.positive_control1
        : 0
    );
    setPositive2(
      prick_form_selected.positive_control2 != null
        ? prick_form_selected.positive_control2
        : 0
    );
    setNegative1(
      prick_form_selected.negative_control1 != null
        ? prick_form_selected.negative_control1
        : 0
    );
    setNegative2(
      prick_form_selected.negative_control2 != null
        ? prick_form_selected.negative_control2
        : 0
    );
  }, [prick_form_selected]);

  function Positive1Handler(e) {
    setPositive1(e.target.value);
    setPositive2(e.target.value);
  }
  function Negative1Handler(e) {
    setNegative1(e.target.value);
    setNegative2(e.target.value);
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>Controles do Exame</div>
      <div className={classes.row}>
        <div className={classes.item}>
          <Form.Label className={classes.label} htmlFor="positiveControl">
            Controle Positivo
          </Form.Label>
          <Form.Control
            className={classes.numer_input}
            type="number"
            id="prick_positiveControl1"
            value={statePositive1}
            onChange={Positive1Handler}
          ></Form.Control>
          x
          <Form.Control
            className={classes.numer_input}
            type="number"
            id="prick_positiveControl2"
            value={statePositive2}
            onChange={(e) => setPositive2(e.target.value)}
          ></Form.Control>
          mm
        </div>
        <div className={classes.item}>
          <Form.Label className={classes.label} htmlFor="NegativeControl">
            Controle Negativo
          </Form.Label>
          <Form.Control
            className={classes.numer_input}
            type="number"
            id="prick_negativeControl1"
            value={stateNegative1}
            onChange={Negative1Handler}
          ></Form.Control>
          x
          <Form.Control
            className={classes.numer_input}
            type="number"
            id="prick_negativeControl2"
            value={stateNegative2}
            onChange={(e) => setNegative2(e.target.value)}
          ></Form.Control>
          mm
        </div>
      </div>
    </div>
  );
}
export default ExamControl;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { solicitacaoActions } from "../../../store/solicitacao-slice";
import classes from "./PatchTable.module.css";
import { Form } from "react-bootstrap";

function PatchTable() {
  const dispatch = useDispatch();
  const patch_data = useSelector((state) => state.solicitacao.patch_data);
  const patch_custom = useSelector((state) => state.solicitacao.patch_custom);
  function clickHandler(data) {
    dispatch(solicitacaoActions.togglePatchActive(data));
  }
  function clickCustomPatch() {
    dispatch(solicitacaoActions.toggleCustomPatchActive());
  }

  return (
    <>
      {patch_data.map((data, index) => (
        <div
          className={`${classes.element} ${!data.active && classes.inactive}`}
          key={index}
          onClick={() => clickHandler(data)}
        >
          {data.test_name}
        </div>
      ))}
      <div
        className={`${classes.element} ${
          !patch_custom.active && classes.inactive
        }`}
        onClick={() => clickCustomPatch()}
      >
        {patch_custom.test_name}
      </div>
      {patch_custom.active && (
        <div>
          <Form.Control
            type="text"
            id="patch_custom"
            value={patch_custom.test_name}
            onChange={(e) =>
              dispatch(solicitacaoActions.setCustomPatchName(e.target.value))
            }
          />
          <Form.Control
            type="text"
            id="patch_custom"
            value={patch_custom.substances}
            onChange={(e) =>
              dispatch(
                solicitacaoActions.setCustomPatchSubstances(e.target.value)
              )
            }
          />
        </div>
      )}
    </>
  );
}

export default PatchTable;

import { useSelector } from "react-redux";
import PrescriptionForm from "../components/PrescriptionComponents/PrescriptionForm";
import SelectPrescription from "../components/PrescriptionComponents/SelectPrescription";
import PrescriptionOptions from "../components/PrescriptionComponents/PrescriptionOptions/PrescriptionOptions.js";

function Prescription() {
  const selected_prescription = useSelector(
    (state) => state.prescription.selected_prescription
  );
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );

  return (
    <>
      {selected_patient && <SelectPrescription />}
      {selected_prescription && (
        <div className="mt-3 row">
          <div className="col">
            <PrescriptionOptions />
          </div>
          <div className="col">
            <PrescriptionForm />
          </div>
        </div>
      )}
    </>
  );
}

export default Prescription;

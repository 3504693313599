import { createSlice } from "@reduxjs/toolkit";
import { data_array as prick_data_array } from "../data/PrickTestData/LoadData";

const prickSlice = createSlice({
  name: "prick",
  initialState: {
    prick_data: prick_data_array,
    prick_measurements: [],
    prick_form_selected: [],
    prick_report: [],
    all_prick: [],
    need_download: false,
  },
  reducers: {
    selectPrick(state, action) {
      const index = state.prick_data.findIndex(
        (data) => data.title === action.payload
      );
      state.prick_data[index].groups_array.forEach((group) =>
        group.elements.forEach((element) => (element.active = true))
      );
    },
    deselectPrick(state, action) {
      const index = state.prick_data.findIndex(
        (data) => data.title === action.payload
      );
      state.prick_data[index].groups_array.forEach((group) =>
        group.elements.forEach((element) => (element.active = false))
      );
    },
    deselectAllPrick(state) {
      state.prick_data.forEach((data) =>
        data.groups_array.forEach((group) =>
          group.elements.forEach((element) => {
            element.active = false;
          })
        )
      );
      state.showReport = false;
    },
    toggleActivePrick(state, action) {
      state.prick_data.forEach((data) =>
        data.groups_array.forEach((group) =>
          group.elements.forEach((element) => {
            if (element.identifier === action.payload)
              element.active = !element.active;
          })
        )
      );
    },
    setAllPricks(state, action) {
      state.all_prick = action.payload;
    },
    removeOnePrickForm(state, action) {
      const idx_all = state.all_prick.findIndex(
        (prick_form) => prick_form.id === action.payload.id
      );
      state.all_prick.splice(idx_all, 1);
    },
    updateOnePrickForm(state, action) {
      const idx = state.all_prick.findIndex(
        (one_pach_form) => one_pach_form.id === action.payload.id
      );
      state.all_prick.splice(idx, 1, action.payload);
      if (state.prick_form_selected.id === action.payload.id) {
        state.prick_form_selected = action.payload;
      }
    },
    addOnePrickForm(state, action) {
      state.all_prick.unshift(action.payload);
    },
    setPrickMeasurements(state, action) {
      state.prick_measurements = action.payload;
      const identifiers = action.payload.map((e) => e.identifier);
      state.prick_data.forEach((data) =>
        data.groups_array.forEach((group) =>
          group.elements.forEach((element) => {
            if (identifiers.includes(element.identifier)) {
              element.active = true;
            } else {
              element.active = false;
            }
          })
        )
      );
    },
    setPrickFormSelected(state, action) {
      state.prick_form_selected = action.payload;
    },
    needDownload(state, action) {
      state.need_download = action.payload;
    },
  },
});

export const prickActions = prickSlice.actions;

export default prickSlice;

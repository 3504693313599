import classes from "./CommentsInput.module.css";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

function CommentsInput() {
  const prick_form_selected = useSelector(
    (state) => state.prick.prick_form_selected
  );
  const [stateComments, setComments] = useState([]);

  useEffect(() => {
    setComments(
      prick_form_selected.comments !== null ? prick_form_selected.comments : ""
    );
  }, [prick_form_selected]);

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <div className={classes.header}>Comentários</div>
        <Form.Control
          className={classes.text_input}
          as="textarea"
          rows={3}
          id="prick_comments"
          value={stateComments}
          onChange={(e) => {
            setComments(e.target.value);
          }}
        ></Form.Control>
      </div>
    </div>
  );
}

export default CommentsInput;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllClinics, updateClinic } from "../../store/clinics-actions";
import { clinicActions } from "../../store/clinics-slice";

function SelectClinic() {
  const clinics = useSelector((state) => state.clinic.clinics);
  const selected_clinic = useSelector((state) => state.clinic.selected_clinic);
  const token = useSelector((state) => state.login.token);
  const need_fetch = useSelector((state) => state.clinic.need_fetch);
  const dispatch = useDispatch();

  useEffect(() => {
    if (need_fetch) {
      dispatch(fetchAllClinics(token));
    }
  }, [dispatch, need_fetch, token]);

  // Handle change in selection
  const handleChange = (event) => {
    dispatch(
      updateClinic(
        clinics.find((clinic) => clinic.id === parseInt(event.target.value)),
        parseInt(event.target.value),
        token
      )
    );
    dispatch(clinicActions.selectClinic(parseInt(event.target.value)));
  };

  return (
    <form>
      <select
        className="form-select form-select-sm"
        onChange={handleChange}
        value={selected_clinic ? selected_clinic.id : ""}
      >
        {clinics.length > 0 &&
          clinics.map((clinic) => (
            <option key={clinic.id} value={clinic.id} className="form-option">
              {clinic.acronym} - {clinic.name}
            </option>
          ))}
      </select>
    </form>
  );
}

export default SelectClinic;

import React from "react";
import classes from "./LaboratorioTable.module.css";
import { Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { solicitacaoActions } from "../../../store/solicitacao-slice";

function LaboratorioTable() {
  const dispatch = useDispatch();
  const lab_options = useSelector((state) => state.solicitacao.lab_options);
  const lab_search = useSelector((state) => state.solicitacao.lab_search);
  const lab_new = useSelector((state) => state.solicitacao.lab_new);

  function lab_search_handler(e) {
    dispatch(solicitacaoActions.searchLabOption(e.target.value));
  }
  function lab_select_option_handler(one_option) {
    dispatch(solicitacaoActions.selectLabOption(one_option));
  }
  function lab_deselect_option_handler(one_option) {
    dispatch(solicitacaoActions.deselectLabOption(one_option));
  }
  function filter_lab_search(array, query) {
    const query_LC = query
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    return array.filter((element) =>
      element.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(query_LC)
    );
  }

  function lab_new_text_handler(e) {
    dispatch(solicitacaoActions.typeNewLab(e.target.value));
  }
  function addExam(e) {
    dispatch(solicitacaoActions.addNewLab());
  }

  return (
    <>
      <Form.Label className={classes.label} htmlFor="lab">
        Laboratório:
      </Form.Label>
      <Form.Control
        type="text"
        id="lab_search"
        placeholder="pesquisar"
        onChange={lab_search_handler}
        value={lab_search}
      ></Form.Control>
      <div className={classes.row}>
        <div>
          Opções:
          <div className={classes.lab_list_container}>
            <div className={classes.lab_list}>
              {filter_lab_search(lab_options, lab_search)
                .filter((option) => !option.selected)
                .map((one_option, index) => (
                  <div
                    key={index}
                    onClick={(e) => lab_select_option_handler(one_option)}
                  >
                    {one_option.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div>
          Selecionadas:
          <div className={classes.lab_list_container}>
            <div className={classes.lab_list}>
              {lab_options
                .filter((option) => option.selected)
                .map((one_option, index) => (
                  <div
                    key={index}
                    onClick={(e) => lab_deselect_option_handler(one_option)}
                  >
                    {one_option.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.row}>
        <Form.Label className={classes.label} htmlFor="new_exam">
          Adicionar:
        </Form.Label>
        <Form.Control
          type="text"
          id="new_exam"
          placeholder="Novo exame"
          onChange={lab_new_text_handler}
          value={lab_new}
        ></Form.Control>
        <div className={classes.button_container}>
          <Button onClick={addExam}>Adicionar</Button>
        </div>
      </div>
    </>
  );
}

export default LaboratorioTable;

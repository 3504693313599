import { useEffect, useState } from "react";
import TimerPicker from "react-bootstrap-time-picker";

function TimerInput({ defaultValue, id, onChange }) {
  const [stateTime, setTime] = useState(defaultValue);

  function handleTimeChange(time) {
    setTime(time);
    onChange(time);
  }

  useEffect(() => {
    setTime(defaultValue);
  }, [defaultValue]);

  return (
    <TimerPicker
      start="06:00"
      step={1}
      value={stateTime}
      id={id}
      onChange={handleTimeChange}
    />
  );
}
export default TimerInput;

import React from "react";
import classes from "./PrickTable.module.css";
import { useSelector, useDispatch } from "react-redux";
import { solicitacaoActions } from "../../../store/solicitacao-slice";
import Form from "react-bootstrap/Form";
import { prick_titles } from "../../../data/SolicitacaoData/DATA_Solicitacao";

function PrickTable() {
  const data_array = useSelector((state) => state.solicitacao.prick_data);

  const dispatch = useDispatch();

  function clickHandler(identifier) {
    dispatch(solicitacaoActions.togglePrickActive(identifier));
  }

  function onChangeHandler(event, data) {
    if (event.target.checked) {
      data.elements.forEach((element) => {
        dispatch(solicitacaoActions.activatePrick(element));
      });
    } else {
      data.elements.forEach((element) => {
        dispatch(solicitacaoActions.deactivatePrick(element));
      });
    }
  }

  function allTestActive(data) {
    var allActive = true;
    data.elements.forEach((element) =>
      data_array.forEach((group) =>
        group.elements.forEach(
          (element_compare) =>
            element_compare.identifier === element &&
            !element_compare.active &&
            (allActive = false)
        )
      )
    );
    return allActive;
  }

  return (
    <div className={classes.container}>
      <div className={classes.container}>
        {prick_titles.map((data, index) => (
          <Form.Check
            type="checkbox"
            label={data.title}
            key={index}
            id={"prick_title_" + index}
            checked={allTestActive(data)}
            onChange={(e) => onChangeHandler(e, data)}
          />
        ))}
      </div>
      {data_array.map((group, index) => (
        <div key={index}>
          <div className={classes.group_name}>{group.group_name}</div>
          {group.elements.map((element, index) => (
            <div
              className={`${classes.element} ${
                !element.active && classes.inactive
              }`}
              key={index}
              onClick={() => clickHandler(element.identifier)}
              dangerouslySetInnerHTML={{ __html: element.label }}
            ></div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default PrickTable;

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/HomePage";
import Patients from "./pages/PatientsPage";
import Allergens from "./pages/AllergensPage";
import Imunoterapia from "./pages/ImunoterapiaPage";
import Solicitacao from "./pages/SolicitacaoPage";
import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/ErrorPage";
import Printed from "./pages/Printed";
import PatientRegister from "./pages/PatientRegister";
import OpenLayout from "./pages/OpenLayout";
import Prescription from "./pages/Prescription";
import Tic from "./pages/Tic";
import PatientRecord from "./pages/PatientRecord";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "patients", element: <Patients /> },
      { path: "patient_record", element: <PatientRecord /> },
      { path: "printed", element: <Printed /> },
      { path: "allergens", element: <Allergens /> },
      { path: "imunoterapia", element: <Imunoterapia /> },
      { path: "solicitacao", element: <Solicitacao /> },
      { path: "prescription", element: <Prescription /> },
      { path: "tic", element: <Tic /> },
    ],
  },
  {
    path: "/patient_register",
    element: <OpenLayout />,
    errorElement: <ErrorPage />,
    children: [{ index: true, element: <PatientRegister /> }],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

import classes from "./PacthTestSelector.module.css";
import Form from "react-bootstrap/Form";

import data_array from "../../../../data/PatchTestData/LoadData";
import { useDispatch, useSelector } from "react-redux";
import { allergensActions } from "../../../../store/allergens-slice";
import { useEffect } from "react";

function PacthTestSelector(props) {
  const dispach = useDispatch();
  const patch_selector = useSelector((state) => state.allergens.patch_selector);
  const patch_measurements = useSelector(
    (state) => state.allergens.patch_measurements
  );
  const patch_form_selected = useSelector(
    (state) => state.allergens.patch_form_selected
  );

  useEffect(() => {
    if (patch_form_selected.id && patch_measurements.length > 0) {
      dispach(allergensActions.deselectAllPatch());
      const identifiers = patch_measurements.map(
        (measurement) => measurement.identifier
      );
      data_array.forEach((data) => {
        identifiers.includes(data.identifier) &&
          dispach(allergensActions.selectPatch(data));
      });
    }
    if (!patch_form_selected.id) {
      dispach(allergensActions.deselectAllPatch());
      dispach(allergensActions.selectPatch(data_array[0]));
      dispach(allergensActions.selectPatch(data_array[1]));
    }
  }, [dispach, patch_measurements, patch_form_selected.id]);

  function onChangeHandler(event, data) {
    if (event.target.checked) {
      dispach(allergensActions.selectPatch(data));
    } else {
      dispach(allergensActions.deselectPatch(data));
    }
  }

  return (
    <div className={classes.container}>
      {data_array.map((data, index) => (
        <Form.Check
          type="checkbox"
          label={data.subtitle}
          key={index}
          name={index}
          id={"patch_selection_" + index}
          checked={patch_selector
            .map((patch) => patch.identifier)
            .includes(data.identifier)}
          onChange={(e) => onChangeHandler(e, data)}
        />
      ))}
    </div>
  );
}
export default PacthTestSelector;

import { useSelector } from "react-redux";
import PatientRecordForm from "../components/PatientRecordsComponents/PatientRecordForm";
import SelectPatientRecord from "../components/PatientRecordsComponents/SelectPatientRecord";
function PatientRecord() {
  // State to store input values
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );
  const selected_patient_record = useSelector(
    (state) => state.patient_record.selected_patient_record
  );

  return (
    <div>
      {selected_patient_record && <PatientRecordForm />}
      {selected_patient && <SelectPatientRecord />}
    </div>
  );
}

export default PatientRecord;

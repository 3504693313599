import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import DateInput from "../../AllergensComponents/PatchTestComponents/header_footer/DateInput";
import { dateFormater, formatDatefromBr } from "../../../util/stringFormaters";
import {
  prick_options,
  patch_options,
  tic_data,
  cutaneo_medicamentos_options,
  oral_medicamentos_options,
  injetavel_medicamentos_options,
  oral_alimentos_options,
} from "../../../data/SolicitacaoData/DATA_Solicitacao";
import classes from "./SolicitacaoForm.module.css";
import { useSelector, useDispatch } from "react-redux";
import { solicitacaoActions } from "../../../store/solicitacao-slice";
import PrickTable from "./PrickTable";
import PatchTable from "./PatchTable";
import EspirometriaTable from "./EspirometriaTable";
import TICTable from "./TICTable";
import LaboratorioTable from "./LaboratorioTable";
import OthersTable from "./OthersTable";
import {
  downloadSolicitacaoPDF,
  sendNewOrder,
} from "../../../store/solicitacao-actions";

function SolicitacaoForm() {
  const need_download = useSelector((state) => state.solicitacao.need_download);
  const form_data = useSelector((state) => state.solicitacao.form_data);
  const prick_enable = useSelector((state) => state.solicitacao.prick_enable);
  const prick_option_selected = useSelector(
    (state) => state.solicitacao.prick_option_selected
  );
  const patch_enable = useSelector((state) => state.solicitacao.patch_enable);
  const selected_order = useSelector(
    (state) => state.solicitacao.selected_order
  );
  const patch_option_selected = useSelector(
    (state) => state.solicitacao.patch_option_selected
  );
  const espirometria_enable = useSelector(
    (state) => state.solicitacao.espirometria_enable
  );
  const tic_enable = useSelector((state) => state.solicitacao.tic_enable);
  const tic_option_selected = useSelector(
    (state) => state.solicitacao.tic_option_selected
  );
  const enable_cutaneo_medicamentos = useSelector(
    (state) => state.solicitacao.enable_cutaneo_medicamentos
  );
  const enable_oral_medicamentos = useSelector(
    (state) => state.solicitacao.enable_oral_medicamentos
  );
  const enable_injetavel_medicamentos = useSelector(
    (state) => state.solicitacao.enable_injetavel_medicamentos
  );
  const enable_oral_alimentos = useSelector(
    (state) => state.solicitacao.enable_oral_alimentos
  );
  const date = useSelector((state) => state.solicitacao.date);
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );
  const token = useSelector((state) => state.login.token);
  const selected_clinic = useSelector((state) => state.clinic.selected_clinic);

  const [stateSubmitForm, setSubmitForm] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (need_download === true) {
      dispatch(
        downloadSolicitacaoPDF(selected_order, selected_patient.name, token)
      );
    }
  }, [dispatch, need_download, selected_order, selected_patient, token]);

  useEffect(() => {
    dispatch(solicitacaoActions.setPrickOption(prick_options[0]));
    dispatch(solicitacaoActions.setPatchOption(patch_options[0]));
    dispatch(solicitacaoActions.setDate(dateFormater(new Date())));
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(solicitacaoActions.setName(selected_patient.name));
  }, [dispatch, selected_patient]);

  function prickSelectHandler(e) {
    dispatch(solicitacaoActions.enablePrick(e.target.checked));
  }
  function patchSelectHandler(e) {
    dispatch(solicitacaoActions.enablePatch(e.target.checked));
  }
  function espirometriaSelectHandler(e) {
    dispatch(solicitacaoActions.enableEspirometria(e.target.checked));
  }
  function ticSelectHandler(e) {
    dispatch(solicitacaoActions.enableTIC(e.target.checked));
  }
  function cutaneoMedicamentosSelectHandler(e) {
    dispatch(
      solicitacaoActions.enableCutaneoMedicamentos({
        enable: e.target.checked,
        cutaneo_medicamentos: cutaneo_medicamentos_options,
      })
    );
  }
  function oralMedicamentosSelectHandler(e) {
    dispatch(
      solicitacaoActions.enableOralMedicamentos({
        enable: e.target.checked,
        oral_medicamentos: oral_medicamentos_options,
      })
    );
  }
  function injetavelMedicamentosSelectHandler(e) {
    dispatch(
      solicitacaoActions.enableInjetavelMedicamentos({
        enable: e.target.checked,
        injetavel_medicamentos: injetavel_medicamentos_options,
      })
    );
  }
  function oralAlimentosSelectHandler(e) {
    dispatch(
      solicitacaoActions.enableOralAlimentos({
        enable: e.target.checked,
        oral_alimentos: oral_alimentos_options,
      })
    );
  }

  function dateChangeHandler(input_formated) {
    dispatch(solicitacaoActions.setDate(input_formated));
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(solicitacaoActions.submitForm());
    setSubmitForm(true);
  }

  useEffect(() => {
    if (stateSubmitForm) {
      const orderTosend = {
        order: {
          patient_id: selected_patient.id,
          clinic_id: selected_clinic.id,
          city: selected_clinic.city,
          signature_date: formatDatefromBr(form_data.date),
          requester: "DR. CLÉRISTON DOMINGUES",
          tcpim: form_data.cutaneo_medicamentos.drugs,
          tpmvo: form_data.oral_medicamentos.drugs,
          tpmvi: form_data.injetavel_medicamentos.drugs,
          tpoca: form_data.oral_alimentos.drugs,
          spirometry: form_data.espirometria
            ? JSON.stringify(form_data.espirometria.to_patient)
            : null,
          others: form_data.others ? JSON.stringify(form_data.others) : null,
          others_lab_exams: form_data.labs
            ? JSON.stringify(form_data.labs)
            : null,
          others_patch_test: form_data.patch_custom
            ? JSON.stringify({
                test_name: form_data.patch_custom.test_name,
                substances: form_data.patch_custom.substances,
              })
            : null,
          prick_set_description: form_data.pricks_option
            ? form_data.pricks_option.split(" - ")[1]
            : null,
          patch_set_code: form_data.patch_option
            ? form_data.patch_option.split(" ")[0]
            : null,
          tic_set_code: form_data.tic.type && form_data.tic.type.split(" ")[0],
          patch_identifier_list: form_data.patch_tests,
          prick_element_list: form_data.prick_tests,
          tic_element_list: form_data.tic
            ? form_data.tic.groups.reduce((acc, curr) => {
                return acc.concat(curr.elements);
              }, [])
            : [],
        },
      };
      dispatch(sendNewOrder(orderTosend, token));
    }
    setSubmitForm(false);
  }, [
    dispatch,
    stateSubmitForm,
    setSubmitForm,
    form_data,
    selected_clinic,
    selected_patient,
    token,
  ]);

  return (
    <>
      <Form className={classes.container} onSubmit={handleSubmit}>
        <Form.Label className={classes.label} htmlFor="name">
          Nome:
        </Form.Label>
        <Form.Control
          type="text"
          id="patient_name"
          placeholder="Nome"
          className={classes.input_text}
          value={selected_patient && selected_patient.name}
          onChange={(e) => {}}
          disabled
        />
        <div className={classes.test_container}>
          <LaboratorioTable />
        </div>
        <div className={classes.test_container}>
          <Form.Check
            type="checkbox"
            label="Prick Test"
            id="prick"
            className={classes.label}
            onChange={prickSelectHandler}
          />
          {prick_enable && (
            <div>
              {prick_options.map((one_option, index) => (
                <Form.Check
                  type="radio"
                  label={one_option.name}
                  key={index}
                  id={"prick_option_" + index}
                  checked={prick_option_selected === one_option}
                  onChange={() => {
                    dispatch(solicitacaoActions.setPrickOption(one_option));
                  }}
                />
              ))}
              <PrickTable />
            </div>
          )}
        </div>
        <div className={classes.test_container}>
          <Form.Check
            type="checkbox"
            label="Patch Test"
            id="patch"
            className={classes.label}
            onChange={patchSelectHandler}
          />
          {patch_enable && (
            <div>
              {patch_options.map((one_option, index) => (
                <Form.Check
                  type="radio"
                  label={one_option}
                  key={index}
                  id={"patch_option_" + index}
                  checked={patch_option_selected === one_option}
                  onChange={() => {
                    dispatch(solicitacaoActions.setPatchOption(one_option));
                  }}
                />
              ))}
              <PatchTable />
            </div>
          )}
        </div>

        <div className={classes.test_container}>
          <Form.Check
            type="checkbox"
            label="Espirometria"
            id="espirometria"
            className={classes.label}
            onChange={espirometriaSelectHandler}
          />
          {espirometria_enable && (
            <div>
              <EspirometriaTable />
            </div>
          )}
        </div>

        <div className={classes.test_container}>
          <Form.Check
            type="checkbox"
            label="Teste de Imunidade Celular"
            id="tic"
            className={classes.label}
            onChange={ticSelectHandler}
          />
          {tic_enable && (
            <div>
              {tic_data.map((one_option, index) => (
                <Form.Check
                  type="radio"
                  label={one_option.type}
                  key={index}
                  id={"tic_option_" + index}
                  checked={tic_option_selected.type === one_option.type}
                  onChange={() => {
                    dispatch(solicitacaoActions.setTICOption(one_option));
                  }}
                />
              ))}
              <TICTable />
            </div>
          )}
        </div>

        <div className={classes.test_container}>
          <Form.Check
            type="checkbox"
            label={cutaneo_medicamentos_options.name}
            id="cutaneo_medicamentos"
            className={classes.label}
            onChange={cutaneoMedicamentosSelectHandler}
          />
          {enable_cutaneo_medicamentos && (
            <Form.Control
              type="text"
              label="drogas"
              id="cutaneo_medicamentos"
              placeholder="Drogas"
              className={classes.input_text}
              onChange={(e) =>
                dispatch(
                  solicitacaoActions.setCutaneoMedicamentos(e.target.value)
                )
              }
            />
          )}
        </div>
        <div className={classes.test_container}>
          <Form.Check
            type="checkbox"
            label={oral_medicamentos_options.name}
            id="oral_medicamentos"
            className={classes.label}
            onChange={oralMedicamentosSelectHandler}
          />
          {enable_oral_medicamentos && (
            <Form.Control
              type="text"
              label="drogas"
              id="oral_medicamentos"
              placeholder="Drogas"
              className={classes.input_text}
              onChange={(e) =>
                dispatch(solicitacaoActions.setOralMedicamentos(e.target.value))
              }
            />
          )}
        </div>
        <div className={classes.test_container}>
          <Form.Check
            type="checkbox"
            label={injetavel_medicamentos_options.name}
            id="injetavel_medicamentos"
            className={classes.label}
            onChange={injetavelMedicamentosSelectHandler}
          />
          {enable_injetavel_medicamentos && (
            <Form.Control
              type="text"
              label="drogas"
              id="injetavel_medicamentos"
              placeholder="Drogas"
              className={classes.input_text}
              onChange={(e) =>
                dispatch(
                  solicitacaoActions.setInjetavelMedicamentos(e.target.value)
                )
              }
            />
          )}
        </div>
        <div className={classes.test_container}>
          <Form.Check
            type="checkbox"
            label={oral_alimentos_options.name}
            id="oral_alimentos"
            className={classes.label}
            onChange={oralAlimentosSelectHandler}
          />
          {enable_oral_alimentos && (
            <Form.Control
              type="text"
              label="food"
              id="oral_alimentos"
              placeholder="Alimentos"
              className={classes.input_text}
              onChange={(e) =>
                dispatch(solicitacaoActions.setOralAlimentos(e.target.value))
              }
            />
          )}
        </div>
        <div className={classes.test_container}>
          <OthersTable />
        </div>
        <DateInput
          id="signature_date"
          label="Data:"
          with_date_picker={true}
          default_value={date}
          onChangeHandler={dateChangeHandler}
        />
        <div className={classes.button_container}>
          <Button type="submit">Gerar Nova Solicitacao</Button>
        </div>
      </Form>
    </>
  );
}

export default SolicitacaoForm;

import classes from "./PrickTestSelector.module.css";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { prickActions } from "../../../../store/prick-slice";

function PrickTestSelector() {
  const dispach = useDispatch();
  const prick_data = useSelector((state) => state.prick.prick_data);

  function onChangeHandler(event, title) {
    if (event.target.checked) {
      dispach(prickActions.selectPrick(title));
    } else {
      dispach(prickActions.deselectPrick(title));
    }
  }

  function allTestActive(test) {
    var allActive = true;
    test.groups_array.forEach((group) =>
      group.elements.forEach(
        (element) => !element.active && (allActive = false)
      )
    );
    return allActive;
  }
  return (
    <div className={classes.container}>
      {prick_data.map((data, index) => (
        <Form.Check
          type="checkbox"
          label={data.title}
          key={index}
          id={"prick_selection_" + index}
          checked={allTestActive(data)}
          onChange={(e) => onChangeHandler(e, data.title)}
        />
      ))}
    </div>
  );
}
export default PrickTestSelector;

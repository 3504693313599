const aeroalegenos = {
  title: "Aeroalérgenos",
  groups_array: [
    {
      group_name: "Ácaros",
      elements: [
        {
          identifier: "Dp",
          label: "<i>D. pteronyssinus</i>",
          active: false,
        },
        {
          identifier: "Df",
          label: "<i>D. farinae</i>",
          active: false,
        },
        {
          identifier: "Bt",
          label: "<i>Blomia tropicalis</i>",
          active: false,
        },
      ],
    },
    {
      group_name: "Fungos do Ar",
      elements: [
        {
          identifier: "Fg",
          label: "Fungos Mix III<sup>A</sup>",
          note: "A - <i>Altenaria alternata + Aspergillus fumigatus + Cladosporium herbarum + Penicillium notatum</i>",
          active: false,
        },
      ],
    },
    {
      group_name: "Pólens de Gramíneas",
      elements: [
        {
          identifier: "Po",
          label: "Gramíneas Mix II<sup>B</sup>",
          note: "B -<i>Dactylis glomerata + Lolium perene + Phleum pratense</i>",
          active: false,
        },
      ],
    },
    {
      group_name: "Epitélios de Animais",
      elements: [
        {
          identifier: "Ba",
          label: "Baratas Mix<sup>C</sup>",
          note: "C -<i>Blatella germanica + Periplaneta americana</i>",
          active: false,
        },
        {
          identifier: "Ec",
          label: "<i>Canis familiaris</i> (cão)",
          active: false,
        },
        {
          identifier: "Eg",
          label: "<i>Felis domesticus</i> (gato)",
          active: false,
        },
      ],
    },
  ],
};
const alimentos = {
  title: "Alimentos",
  groups_array: [
    {
      group_name: "",
      elements: [
        {
          identifier: "Lv",
          label: "Leite de vaca",
          active: false,
        },
        {
          identifier: "Ov",
          label: "Ovo de galinha",
          active: false,
        },
        { identifier: "Sj", label: "Soja", active: false },
        {
          identifier: "Tg",
          label: "Trigo",
          active: false,
        },
        {
          identifier: "Am",
          label: "Amendoim",
          active: false,
        },
        {
          identifier: "Ct",
          label: "Castanhas",
          active: false,
        },
        {
          identifier: "Ca",
          label: "Camarão",
          active: false,
        },
        {
          identifier: "Pe",
          label: "Peixes",
          active: false,
        },
      ],
    },
  ],
};
const insetos = {
  title: "Insetos",
  groups_array: [
    {
      group_name: "Mosquito",
      elements: [
        {
          identifier: "Cp",
          label: "<i>Culex pipiens</i>",
          active: false,
        },
      ],
    },
    {
      group_name: "Formiga",
      elements: [
        {
          identifier: "Si",
          label: "<i>Solenopsis invicta</i>",
          active: false,
        },
      ],
    },
  ],
};
const latex = {
  title: "Látex",
  groups_array: [
    {
      group_name: "",
      elements: [
        {
          identifier: "Hb",
          label: "<i>Hevea brasiliensis</i>",
          active: false,
        },
      ],
    },
  ],
};
export const data_array = [aeroalegenos, alimentos, insetos, latex];

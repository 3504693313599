import React from "react";
import classes from "./SelectDiagnosis.module.css";
import { useDispatch, useSelector } from "react-redux";
import { imunoActions } from "../../../store/imuno-slice";
import { Form } from "react-bootstrap";

function SelectDiagnosis() {
  const diagnosis_data = useSelector((state) => state.imuno.diagnosis_data);
  const search_diagnosis_name = useSelector(
    (state) => state.imuno.search_diagnosis_name
  );
  const dispatch = useDispatch();

  function filter_diagnosis_search(array, query) {
    const query_LC = query
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    return array.filter((element) =>
      element
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(query_LC)
    );
  }

  return (
    <>
      <div className={classes.row}>
        <Form.Label htmlFor="imuno_name_search">Pesquisar:</Form.Label>
        <Form.Control
          type="text"
          id="diagnosis_name_search"
          placeholder="Nome"
          value={search_diagnosis_name}
          onChange={(e) => {
            dispatch(imunoActions.typeSearchDiagnosisName(e.target.value));
          }}
        ></Form.Control>
      </div>
      <div className={classes.border}>
        <div className={classes.container}>
          {filter_diagnosis_search(diagnosis_data, search_diagnosis_name).map(
            (one_option, index) => (
              <div
                key={index}
                onClick={() => dispatch(imunoActions.setDiagnosis(one_option))}
                className={classes.item}
              >
                {one_option}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default SelectDiagnosis;

import React from "react";
import classes from "./SelectTic.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { ticActions } from "../../store/tic-slice";
import {
  fetchAllTics,
  downloadTicPDF,
  deleteOneTic,
} from "../../store/tic-actions";

import { formatIsoDateStringToCustom } from "../../util/stringFormaters";
import { confirm } from "../../util/Confirmation";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faFile } from "@fortawesome/free-solid-svg-icons";

function SelectTic() {
  const dispatch = useDispatch();
  const all_tics = useSelector((state) => state.tic.all_tics);
  const selected_tic = useSelector((state) => state.tic.selected_tic);
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );

  const tic_need_fetch = useSelector((state) => state.tic.need_fetch);
  const need_download = useSelector((state) => state.tic.need_download);
  const token = useSelector((state) => state.login.token);
  const selected_clinic = useSelector((state) => state.clinic.selected_clinic);

  useEffect(() => {
    if (tic_need_fetch) {
      dispatch(fetchAllTics(token));
    }
  }, [dispatch, tic_need_fetch, token]);

  useEffect(() => {
    if (need_download !== false) {
      dispatch(downloadTicPDF(selected_tic, selected_patient.name, token));
    }
  }, [dispatch, need_download, selected_patient, selected_tic, token]);

  useEffect(() => {
    dispatch(ticActions.selectTic(""));
  }, [dispatch, selected_patient]);

  const handleDelete = async (one_tic) => {
    if (await confirm("Tem certeza que deseja excluir esse formulário?")) {
      dispatch(deleteOneTic(one_tic, token));
    }
  };

  function newTicFormHandler() {
    dispatch(
      ticActions.selectTic({
        patient_id: selected_patient.id,
        clinic_id: selected_clinic.id,
        signature_date: new Date().toISOString().split("T")[0],
      })
    );
  }

  function cancelTicFormHandler(e) {
    dispatch(ticActions.selectTic(""));
  }
  return (
    <div className={classes.container}>
      <div className={classes.list_border}>
        <div className={classes.immunotherapy_list}>
          {all_tics
            .filter((one_tic) => one_tic.patient_id === selected_patient.id)
            .map((one_tic, index) => (
              <div
                onClick={() => dispatch(ticActions.selectTic(one_tic))}
                className={`${classes.one_immunotherapy} ${
                  selected_tic.id === one_tic.id ? classes.selected : ""
                }`}
                key={index}
              >
                <div className={classes.item_text}>
                  {"TIC em "}
                  {formatIsoDateStringToCustom(one_tic.updated_at)}
                </div>
                {one_tic.pdf && (
                  <FontAwesomeIcon
                    icon={faFile}
                    title="Fazer Download"
                    onClick={() =>
                      dispatch(
                        downloadTicPDF(one_tic, selected_patient.name, token)
                      )
                    }
                    className={classes.action}
                  />
                )}
                <FontAwesomeIcon
                  icon={faTrashCan}
                  title="Excluir"
                  onClick={() => handleDelete(one_tic)}
                  className={classes.action}
                />
              </div>
            ))}
        </div>
      </div>
      {!selected_tic.patient_id && (
        <Button onClick={newTicFormHandler}>Criar Novo TIC</Button>
      )}
      {selected_tic.patient_id && (
        <Button onClick={cancelTicFormHandler}>Cancelar TIC</Button>
      )}
    </div>
  );
}
export default SelectTic;

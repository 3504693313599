import InputSubstance from "./InputSubstance";
import classes from "./OneForm.module.css";
import React from "react";

function OneForm({ data }) {
  var header_class = classes.header;
  var container_class = classes.container;
  var content_cass = classes.content;
  var defaultValue = "";

  switch (data.title) {
    case "Alimentos":
      header_class = `${header_class} ${classes.header_food}`;
      container_class = `${container_class} ${classes.container_food}`;
      content_cass = `${content_cass} ${classes.content_food}`;
      defaultValue = 0;
      break;
    case "Insetos":
      header_class = `${header_class} ${classes.header_insects}`;
      container_class = `${container_class} ${classes.container_insects}`;
      content_cass = `${content_cass} ${classes.content_insects}`;
      break;
    case "Látex":
      header_class = `${header_class} ${classes.header_latex}`;
      container_class = `${container_class} ${classes.container_latex}`;
      content_cass = `${content_cass} ${classes.content_latex}`;
      defaultValue = 0;
      break;
    default:
  }

  return (
    <div className={container_class}>
      <div className={header_class}>{data.title}</div>
      <div className={content_cass}>
        {data.groups_array.map((group, index) => (
          <React.Fragment key={index}>
            {group.group_name !== "" ? (
              <div className={classes.group_name} key={group.group_name}>
                {group.group_name}
              </div>
            ) : (
              ""
            )}
            {group.elements.map((element, index) => (
              <InputSubstance
                element={element}
                defaultValue={defaultValue}
                title={data.title}
                key={index}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
export default OneForm;

export const data = {
  identifier: "nails",
  subtitle: "BATERIA UNHAS",
  form_title: "UNHAS",
  number: "(20 SUBSTÂNCIAS)",
  initialShow: false,
  sensitizers: [
    {
      measurement_label: "U1",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U2",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U3",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U4",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U5",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U6",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U7",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U8",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U9",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U10",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U11",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U12",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U13",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U14",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U15",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U16",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U17",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U18",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U19",
      patch_test_info_identifier: "nails",
    },
    {
      measurement_label: "U20",
      patch_test_info_identifier: "nails",
    },
  ],
};

import { useSelector } from "react-redux";
import TicForm from "../components/TicComponents/TicForm";
import SelectTic from "../components/TicComponents/SelectTic";

function Tic() {
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );
  const selected_tic = useSelector((state) => state.tic.selected_tic);
  return (
    <>
      {selected_patient && <SelectTic />}
      {selected_tic && <TicForm formData={selected_tic} />}
    </>
  );
}

export default Tic;

import classes from "./HeaderInput.module.css";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import DateInput from "../../PatchTestComponents/header_footer/DateInput";
import TimerInput from "./TimeInput";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  dateFormater,
  formatDatetoBr,
  formatTimeStringToSeconds,
} from "../../../../util/stringFormaters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

function HeaderInput() {
  const patch_form_selected = useSelector(
    (state) => state.allergens.patch_form_selected
  );
  const prick_form_selected = useSelector(
    (state) => state.prick.prick_form_selected
  );
  const [stateDate, setDate] = useState("");
  const clinics = useSelector((state) => state.clinic.clinics);

  const [stateRequester, setRequester] = useState([]);
  const [stateClinic, setClinic] = useState(0);
  const [stateCity, setCity] = useState([]);
  const [statePunctureTime, setPunctureTime] = useState([]);
  const [stateReadingTime, setReadingTime] = useState([]);

  useEffect(() => {
    setDate(
      prick_form_selected.signature_date !== null
        ? formatDatetoBr(prick_form_selected.signature_date)
        : ""
    );
    setRequester(
      patch_form_selected.requester !== undefined
        ? patch_form_selected.requester
        : prick_form_selected.requester
    );
    setClinic(
      patch_form_selected.clinic_id !== undefined
        ? patch_form_selected.clinic_id
        : prick_form_selected.clinic_id
    );
    setCity(
      patch_form_selected.city !== undefined
        ? patch_form_selected.city
        : prick_form_selected.city
    );
    setPunctureTime(
      prick_form_selected.puncture_time != null
        ? formatTimeStringToSeconds(prick_form_selected.puncture_time)
        : ""
    );
    setReadingTime(
      prick_form_selected.reading_time != null
        ? formatTimeStringToSeconds(prick_form_selected.reading_time)
        : ""
    );
  }, [prick_form_selected, patch_form_selected]);

  function handlePuncureChange(time) {
    setPunctureTime(time);
    setReadingTime(time + 20 * 60);
  }
  function handleReadingChange(time) {
    setReadingTime(time);
  }

  function currentMinutes() {
    const current_milisec =
      new Date().getTime() - new Date().setHours(0, 0, 0, 0);
    return Math.floor(current_milisec / 1000 / 60);
  }

  return (
    <div className={classes.header_input_container}>
      <div className={classes.row}>
        <div
          hidden={patch_form_selected.name !== undefined}
          className={classes.item}
        >
          <Form.Label htmlFor="prick_patient">Paciente:</Form.Label>
          <Form.Control
            type="text"
            id="prick_patient"
            placeholder="Paciente"
            value={prick_form_selected.name}
            onChange={() => {}}
            className={classes.text_input}
            disabled
          ></Form.Control>
        </div>
        <div
          hidden={patch_form_selected.birthday !== undefined}
          className={classes.item}
        >
          <Form.Label htmlFor="prick_dn">DN:</Form.Label>
          <Form.Control
            type="text"
            id="prick_dn"
            placeholder="Data de Nascimento"
            value={formatDatetoBr(
              prick_form_selected.birthday === null
                ? ""
                : prick_form_selected.birthday
            )}
            onChange={() => {}}
            className={classes.text_input}
            disabled
          ></Form.Control>{" "}
        </div>
        <div
          hidden={patch_form_selected.requester !== undefined}
          className={classes.item}
        >
          <Form.Label htmlFor="prick_requester">Solicitante:</Form.Label>
          <Form.Control
            type="text"
            id="prick_requester"
            placeholder="Solicitante"
            value={stateRequester}
            className={classes.text_input}
            onChange={(e) => {
              setRequester(e.target.value);
            }}
          ></Form.Control>
        </div>
        <div
          hidden={patch_form_selected.clinic_id !== undefined}
          className={`${classes.item} ${classes.clinic}`}
        >
          <Form.Label htmlFor="prick_clinic">Clinica:</Form.Label>
          <Form.Select
            id="prick_clinic"
            onChange={(e) => {
              setClinic(e.target.value);
              setCity(
                clinics.find((clinic) => clinic.id === parseInt(e.target.value))
                  .city
              );
            }}
            value={stateClinic}
          >
            {clinics.map((clinic, index) => (
              <option key={index} value={clinic.id}>
                {clinic.acronym + " - " + clinic.name}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
      <div className={classes.row}>
        <div
          hidden={patch_form_selected.city !== undefined}
          className={classes.item}
        >
          <Form.Label htmlFor="prick_city">Local:</Form.Label>
          <Form.Control
            type="text"
            id="prick_city"
            value={stateCity}
            onChange={(e) => setCity(e.target.value)}
            disabled
          ></Form.Control>
        </div>
        <div>
          <DateInput
            id="prick_signature_date"
            label="Data:"
            default_value={stateDate}
            with_date_picker={true}
          />
        </div>
        <div>
          <Form.Label htmlFor="prick_puncture">Puntura:</Form.Label>
          <FontAwesomeIcon
            className={classes.action}
            icon={faClock}
            onClick={() => {
              setDate(dateFormater(new Date()));
              handlePuncureChange(currentMinutes() * 60);
            }}
          />
          <TimerInput
            id="prick_puncture"
            onChange={handlePuncureChange}
            defaultValue={statePunctureTime}
          />
        </div>
        <div>
          <Form.Label htmlFor="prick_measurement_time">Leitura:</Form.Label>
          <FontAwesomeIcon
            className={classes.action}
            icon={faClock}
            onClick={() => {
              handleReadingChange(currentMinutes() * 60);
            }}
          />
          <TimerInput
            id="prick_measurement_time"
            defaultValue={stateReadingTime}
            onChange={handleReadingChange}
          />
        </div>
      </div>
    </div>
  );
}

export default HeaderInput;

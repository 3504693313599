export const data = {
  identifier: "capilar",
  subtitle: "BATERIA CAPILAR",
  form_title: "CAPILAR",
  number: "(15 SUBSTÂNCIAS)",
  initialShow: false,
  sensitizers: [
    {
      measurement_label: "CA1",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA2",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA3",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA4",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA5",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA6",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA7",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA8",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA9",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA10",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA11",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA12",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA13",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA14",
      patch_test_info_identifier: "capilar",
    },
    {
      measurement_label: "CA15",
      patch_test_info_identifier: "capilar",
    },
  ],
};

import { createSlice } from "@reduxjs/toolkit";

const patientRecordsSlice = createSlice({
  name: "patient_record",
  initialState: {
    all_patient_records: [],
    selected_patient_record: "",
    need_fetch: true,
  },
  reducers: {
    setAllPatientRecord(state, action) {
      state.all_patient_records = action.payload;
      state.need_fetch = false;
    },
    addOnePatientRecord(state, action) {
      state.all_patient_records.unshift(action.payload);
    },
    removeOnePatientRecord(state, action) {
      const idx = state.all_patient_records.findIndex(
        (patient_record) => patient_record.id === action.payload.id
      );
      state.all_patient_records.splice(idx, 1);
    },
    updateOnePatientRecord(state, action) {
      const idx = state.all_patient_records.findIndex(
        (patient_record) => patient_record.id === action.payload.id
      );
      state.all_patient_records.splice(idx, 1, action.payload);
      if (state.selected_patient_record.id === action.payload.id)
        state.selected_patient_record = action.payload;
    },
    selectPatientRecord(state, action) {
      state.selected_patient_record = action.payload;
    },
    setShowNewForm(state, action) {
      state.showNewForm = action.payload;
    },
  },
});

export const patientRecordsActions = patientRecordsSlice.actions;

export default patientRecordsSlice;

import React from "react";
import PrintedForm from "../components/PrintedComponents/PrintedForm";
import { useSelector } from "react-redux";

function Printed() {
  const selected_patient = useSelector(
    (state) => state.patients.selected_patient
  );

  return <div>{selected_patient && <PrintedForm />}</div>;
}

export default Printed;

import { NavLink } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";
import logo from "../images/logo_report.png";

import classes from "./MainNavigation.module.css";
import SelectClinic from "./ClinicsComponents/SelectClinic";
import { useDispatch, useSelector } from "react-redux";
import { confirm } from "../util/Confirmation";
import { logout } from "../store/login-actions";

function MainNavigation() {
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();

  const handleLogout = async (one_patch_form) => {
    if (await confirm("Tem certeza que deseja fazer logout?")) {
      dispatch(logout(token));
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="sm"
      bg={process.env.REACT_APP_ENV === "staging" ? "dark" : "light"}
      variant="light"
    >
      <Container>
        <Navbar.Brand>
          <img src={logo} alt="logo" className={classes.logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Nav>
            <SelectClinic />
            <NavLink
              to="patients"
              className={({ isActive }) =>
                isActive ? classes.active : classes.menuItem
              }
            >
              Pacientes
            </NavLink>
            <NavLink
              to="patient_record"
              className={({ isActive }) =>
                isActive ? classes.active : classes.menuItem
              }
            >
              Prontuário
            </NavLink>
            <NavLink
              to="solicitacao"
              className={({ isActive }) =>
                isActive ? classes.active : classes.menuItem
              }
            >
              Solicitações
            </NavLink>
            <NavLink
              to="prescription"
              className={({ isActive }) =>
                isActive ? classes.active : classes.menuItem
              }
            >
              Prescrições
            </NavLink>
            <NavLink
              to="printed"
              className={({ isActive }) =>
                isActive ? classes.active : classes.menuItem
              }
            >
              Impressos
            </NavLink>
            <NavLink
              to="allergens"
              className={({ isActive }) =>
                isActive ? classes.active : classes.menuItem
              }
            >
              Patch/Prick
            </NavLink>
            <NavLink
              to="tic"
              className={({ isActive }) =>
                isActive ? classes.active : classes.menuItem
              }
            >
              TIC
            </NavLink>
            <NavLink
              to="imunoterapia"
              className={({ isActive }) =>
                isActive ? classes.active : classes.menuItem
              }
            >
              Imunoterapia
            </NavLink>
            <NavLink
              className={classes.menuItem}
              onClick={() => handleLogout()}
            >
              Logout
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavigation;

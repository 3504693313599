import classes from "./DateInput.module.css";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

function DateInput({
  label,
  id,
  name = "",
  with_date_picker,
  default_value = "",
  onChangeHandler,
}) {
  const [state, setState] = useState(default_value);

  useEffect(() => {
    setState(default_value);
  }, [default_value]);

  function updateHandler(event, setState) {
    const input_text = event.target.value.replaceAll("/", "");
    const cropped = input_text.slice(0, 8);

    var input_formated = "";
    if (cropped.length < 3) {
      input_formated = cropped;
    } else if (cropped.length < 5) {
      input_formated = cropped.slice(0, 2) + "/" + cropped.slice(2);
    } else {
      input_formated =
        cropped.slice(0, 2) +
        "/" +
        cropped.slice(2, 4) +
        "/" +
        cropped.slice(4);
    }

    setState(input_formated);
    if (onChangeHandler) {
      onChangeHandler(input_formated);
    }
  }

  function dateHandler(date, setState) {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const input_formated = dd + "/" + mm + "/" + yyyy;
    setState(input_formated);
    if (onChangeHandler) {
      onChangeHandler(input_formated);
    }
  }

  return (
    <div className={classes.date_container}>
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      <div className={classes.date_input_container}>
        <div className={classes.show_date}>
          <Form.Control
            type="text"
            value={state}
            id={id}
            name={name}
            onChange={(event) => updateHandler(event, setState)}
            placeholder="DD/MM/AAAA"
            required
          ></Form.Control>
        </div>
        {with_date_picker && (
          <div className={classes.date_picker}>
            <ReactDatePicker
              onChange={(event) => dateHandler(event, setState)}
              customInput={<FontAwesomeIcon icon={faCalendar} />}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DateInput;

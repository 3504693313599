import { createSlice } from "@reduxjs/toolkit";

const patientsSlice = createSlice({
  name: "patients",
  initialState: {
    all_patients: [],
    selected_patient: "",
    showNewForm: false,
    showEditForm: false,
    patient_to_edit: "",
    need_fetch: true,
    cpf_typing: "",
    phone_typing: "",
    name_typing: "",
  },
  reducers: {
    setAllPatients(state, action) {
      state.all_patients = action.payload;
      state.need_fetch = false;
    },
    addOnePatient(state, action) {
      state.all_patients.unshift(action.payload);
    },
    removeOnePatient(state, action) {
      const idx = state.all_patients.findIndex(
        (patient) => patient.id === action.payload.id
      );
      state.all_patients.splice(idx, 1);
    },
    updateOnePatient(state, action) {
      const idx = state.all_patients.findIndex(
        (patient) => patient.id === action.payload.id
      );
      state.all_patients.splice(idx, 1, action.payload);
      if (state.selected_patient.id === action.payload.id)
        state.selected_patient = action.payload;
    },
    selectPatient(state, action) {
      state.selected_patient = action.payload;
    },
    setShowNewForm(state, action) {
      state.showNewForm = action.payload;
    },
    setPacientToEdit(state, action) {
      state.patient_to_edit = action.payload;
    },
    setShowEditForm(state, action) {
      state.showEditForm = action.payload;
    },
    typeCPF(state, action) {
      state.cpf_typing = action.payload;
    },
    typePhone(state, action) {
      state.phone_typing = action.payload;
    },
    typeName(state, action) {
      state.name_typing = action.payload;
    },
  },
});

export const patientsActions = patientsSlice.actions;

export default patientsSlice;

function readMeasurementInputs(test_data) {
  const measurementInputs = test_data.sensitizers.map((sensitizer_data) => {
    var measurement_1;
    var measurement_2;
    // eslint-disable-next-line
    eval(
      "measurement_1 = event.target.patch_" +
        test_data.identifier +
        sensitizer_data.measurement_label +
        "_measurement_1.value;"
    );

    // eslint-disable-next-line
    eval(
      "measurement_2 = event.target.patch_" +
        test_data.identifier +
        sensitizer_data.measurement_label +
        "_measurement_2.value;"
    );

    return {
      measurement_label: sensitizer_data.measurement_label,
      patch_test_info_identifier: sensitizer_data.patch_test_info_identifier,
      measurement_1: measurement_1,
      measurement_2: measurement_2,
    };
  });

  return measurementInputs;
}

export function handleSubmit(event, data_array, formDataHandler) {
  event.preventDefault();

  const measurementInputsArray = data_array.map((test_data) =>
    readMeasurementInputs(test_data)
  );

  const measurements_array = [];
  measurementInputsArray.forEach((measurementInputs) =>
    measurementInputs.forEach((measurementInputs) =>
      measurements_array.push({
        patch_sensitizer_info_label:
          measurementInputs.measurement_label,
        patch_test_info_identifier:
          measurementInputs.patch_test_info_identifier,
        first: measurementInputs.measurement_1,
        second: measurementInputs.measurement_2,
      })
    )
  );
  const dataToSend = {
    patch_form: {
      clinic_id: parseInt(event.target.patch_clinic.value),
      application: event.target.patch_application.value,
      first_measurement: event.target.patch_batch1.value,
      second_measurement: event.target.patch_batch2.value,
      city: event.target.patch_city.value,
      signature_date: event.target.patch_signature_date.value,
      requester: event.target.patch_requester.value,
    },
    patch_measurements: measurements_array,
  };

  formDataHandler(dataToSend);
}

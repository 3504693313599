export const data = {
  identifier: "apt4",
  subtitle: "BATERIA ATÓPICA - INALANTES",
  form_title: "ATÓPICA-4",
  number: "(4 SUBSTÂNCIAS)",
  initialShow: false,
    sensitizers: [
    {
      measurement_label: "AT1",
      patch_test_info_identifier:"apt4",
    },
    {
      measurement_label: "AT2",
      patch_test_info_identifier:"apt4",
    },
    {
      measurement_label: "AT3",
      patch_test_info_identifier:"apt4",
    },
    {
      measurement_label: "AT4",
      patch_test_info_identifier:"apt4",
    },
  ],
};
export const data = {
  identifier: "standard",
  subtitle: "BATERIA PADRÃO BRASILEIRO",
  form_title: "PADRÃO BRASILEIRO",
  number: "(30 SUBSTÂNCIAS)",
  initialShow: true,
  sensitizers: [
    {
      measurement_label: "P1",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P2",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P3",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P4",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P5",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P6",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P7",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P8",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P9",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P10",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P11",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P12",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P13",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P14",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P15",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P16",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P17",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P18",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P19",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P20",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P21",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P22",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P23",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P24",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P25",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P26",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P27",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P28",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P29",
      patch_test_info_identifier: "standard",
    },
    {
      measurement_label: "P30",
      patch_test_info_identifier: "standard",
    },
  ],
};

import classes from "./ImonutorapiaForm.module.css";
import { Button } from "react-bootstrap";

import { Form } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { imunoActions } from "../../../store/imuno-slice";
import {
  fetchOneSummary,
  updateImmunotherapy,
} from "../../../store/imuno-actions";
import {
  formatDatefromBr,
  formatDatetoBr,
  formatLeftZeros,
} from "../../../util/stringFormaters";
import SelectDiagnosis from "./SelectDiagnosis";
import DateInput from "../../AllergensComponents/PatchTestComponents/header_footer/DateInput";

function ImonutorapiaForm(props) {
  const dispatch = useDispatch();
  const selected_immunotherapy = useSelector(
    (state) => state.imuno.selected_immunotherapy
  );
  const signature_date_temp = useSelector(
    (state) => state.imuno.signature_date_temp
  );
  const token = useSelector((state) => state.login.token);
  const clinics = useSelector((state) => state.clinic.clinics);
  const selected_clinic = useSelector((state) => state.clinic.selected_clinic);

  function handleSubmit(event) {
    event.preventDefault();
    const immunotherapy_to_send = {
      patient_id: selected_immunotherapy.pacient_id,
      clinic: event.target.clinic.value,
      ige_total: event.target.totalIgE.value,
      ige_specific: event.target.especificIgE.value,
      eosinofilos_perc: event.target.eosinofilosPerc.value,
      eosinofilos_mm: event.target.eosinofilosmm.value,
      others: event.target.others.value,
      prick_summary: event.target.prickTest.value,
      patch_summary: event.target.patchTest.value,
      primary_diagnosis: event.target.primaryDiagnosis.value,
      secondary_diagnosis: event.target.secondaryDiagnosis.value,
      immunotheray_composition: event.target.therapyComposition.value,
      dilution_volume: event.target.volume.value,
      sublingual_drops: event.target.drops.value,
      city: event.target.city.value,
      signature_date: formatDatefromBr(event.target.signature_date.value),
    };
    dispatch(imunoActions.setImmunotherapyToSend(immunotherapy_to_send));
    
    dispatch(
      updateImmunotherapy(
        immunotherapy_to_send,
        selected_immunotherapy.id,
        token
      )
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="patient">Nome:</Form.Label>
          <Form.Control
            type="text"
            id="patient"
            placeholder="Nome do Paciente"
            value={selected_immunotherapy.name}
            onChange={() => {}}
            disabled
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="dn">DN:</Form.Label>
          <Form.Control
            type="text"
            id="dn"
            placeholder="Data de Nascimento"
            value={formatDatetoBr(
              selected_immunotherapy.birthday === null
                ? ""
                : selected_immunotherapy.birthday
            )}
            onChange={() => {}}
            disabled
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="gender">Gênero:</Form.Label>
          <Form.Control
            type="text"
            id="gender"
            placeholder="Gênero"
            value={
              selected_immunotherapy.gender === null
                ? ""
                : selected_immunotherapy.gender.slice(0, 1)
            }
            onChange={() => {}}
            disabled
          ></Form.Control>
        </div>
      </div>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="responsable">Responsável:</Form.Label>
          <Form.Control
            type="text"
            id="responsable"
            placeholder="Responsável"
            value={
              selected_immunotherapy.responsable_name === null
                ? ""
                : selected_immunotherapy.responsable_name
            }
            onChange={() => {}}
            disabled
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="relative_degree">Grau de Parentesco:</Form.Label>
          <Form.Control
            type="text"
            id="relative_degree"
            placeholder="Grau de Parentesco"
            value={
              selected_immunotherapy.responsable_degree === null
                ? ""
                : selected_immunotherapy.responsable_degree
            }
            onChange={() => {}}
            disabled
          ></Form.Control>
        </div>
      </div>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="cpf">CPF RESP/PCTE:</Form.Label>
          <Form.Control
            type="text"
            id="cpf"
            placeholder="CPF"
            value={selected_immunotherapy.cpf}
            onChange={() => {}}
            disabled
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="contact">CONTATO:</Form.Label>
          <Form.Control
            type="text"
            id="contact"
            placeholder="Contato"
            value={
              selected_immunotherapy.contact === null
                ? ""
                : selected_immunotherapy.contact
            }
            onChange={() => {}}
            disabled
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="proc">PROCEDÊNCIA:</Form.Label>
          <Form.Control
            type="text"
            id="proc"
            placeholder="Procedência"
            value={
              selected_immunotherapy.origin === null
                ? ""
                : selected_immunotherapy.origin
            }
            onChange={() => {}}
            disabled
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="clinic">Clinica:</Form.Label>
          <Form.Select
            id="clinic"
            onChange={(e) => {
              dispatch(imunoActions.setClinic(null));
            }}
            value={
              selected_immunotherapy.clinic
                ? selected_immunotherapy.clinic
                : selected_clinic.id
            }
          >
            {clinics.map((clinic, index) => (
              <option key={index} value={clinic.id}>
                {clinic.acronym + " - " + clinic.name}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="totalIgE">IgE Total (UI/mL):</Form.Label>
          <Form.Control
            type="text"
            id="totalIgE"
            placeholder="IgE Total (UI/mL):"
            value={selected_immunotherapy.ige_total}
            onChange={(e) => dispatch(imunoActions.setIgeTotal(e.target.value))}
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="especificIgE">IgE Específico (kU/L):</Form.Label>
          <Form.Control
            type="text"
            id="especificIgE"
            placeholder="IgE Específico (kU/L):"
            value={selected_immunotherapy.ige_specific}
            onChange={(e) =>
              dispatch(imunoActions.setIgeSpecific(e.target.value))
            }
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="eosinofilosPerc">Eosinófilos %:</Form.Label>
          <Form.Control
            type="text"
            id="eosinofilosPerc"
            placeholder="%"
            value={selected_immunotherapy.eosinofilos_perc}
            onChange={(e) =>
              dispatch(imunoActions.setEosinofilosPerc(e.target.value))
            }
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="eosinofilosmm">Eosinófilos /mm:</Form.Label>
          <Form.Control
            type="text"
            id="eosinofilosmm"
            placeholder="/mm"
            value={selected_immunotherapy.eosinofilos_mm}
            onChange={(e) =>
              dispatch(imunoActions.setEosinofilosMm(e.target.value))
            }
          ></Form.Control>
        </div>
        <div>
          <Form.Label htmlFor="others">Outros:</Form.Label>
          <Form.Control
            type="text"
            id="others"
            placeholder="Outros"
            value={selected_immunotherapy.others}
            onChange={(e) => dispatch(imunoActions.setOthers(e.target.value))}
          ></Form.Control>
        </div>
      </div>
      <div className={classes.row}>
        <Form.Label className={classes.label} htmlFor="prickTest">
          Prick Test (mm):
        </Form.Label>
        <Form.Control
          className={classes.input_field}
          as="textarea"
          id="prickTest"
          rows={1}
          placeholder="Prick Test (mm)"
          value={selected_immunotherapy.prick_summary}
          onChange={(e) =>
            dispatch(imunoActions.setPrickSummary(e.target.value))
          }
        ></Form.Control>
        <Button
          onClick={(e) => {
            dispatch(fetchOneSummary(selected_immunotherapy.patient_id, token));
          }}
        >
          Carregar Alérgenos
        </Button>
      </div>
      <div className={classes.row}>
        <Form.Label className={classes.label} htmlFor="patchTest">
          Patch Test:
        </Form.Label>
        <Form.Control
          className={classes.input_field}
          as="textarea"
          id="patchTest"
          rows={1}
          placeholder="Patch Test"
          value={selected_immunotherapy.patch_summary}
          onChange={(e) =>
            dispatch(imunoActions.setPatchSummary(e.target.value))
          }
        ></Form.Control>
      </div>
      <div className={classes.diagnosis}>
        Diagnósticos
        <SelectDiagnosis />
        <div className={classes.row}>
          <Form.Label className={classes.label} htmlFor="primaryDiagnosis">
            DIAGNÓSTICO PRIMÁRIO:
          </Form.Label>
          <Form.Control
            className={classes.input_field}
            as="textarea"
            id="primaryDiagnosis"
            rows={3}
            placeholder="Diagnóstico Primário"
            value={selected_immunotherapy.primary_diagnosis}
            onChange={(e) =>
              dispatch(imunoActions.setPrimaryDiagnosis(e.target.value))
            }
          ></Form.Control>
        </div>
        <div className={classes.row}>
          <Form.Label className={classes.label} htmlFor="secondaryDiagnosis">
            DIAGNÓSTICO SECUNDÁRIO:
          </Form.Label>
          <Form.Control
            className={classes.input_field}
            as="textarea"
            id="secondaryDiagnosis"
            rows={3}
            placeholder="Diagnóstico Secundário"
            value={selected_immunotherapy.secondary_diagnosis}
            onChange={(e) =>
              dispatch(imunoActions.setSecondaryDiagnosis(e.target.value))
            }
          ></Form.Control>
        </div>
      </div>
      <div className={classes.row}>
        <Form.Label className={classes.label} htmlFor="therapyComposition">
          COMPOSIÇÃO DA IMUNOTERAPIA:
        </Form.Label>
        <Form.Select
          className={classes.input_field}
          id="therapyComposition"
          value={selected_immunotherapy.immunotheray_composition}
          onChange={(e) =>
            dispatch(imunoActions.setImmunotherayComposition(e.target.value))
          }
        >
          <option value=""></option>
          <option value="ITA1 - Dermatophagoides pteronyssinus 70% + Blomia tropicalis 30%">
            ITA1 - Dermatophagoides pteronyssinus 70% + Blomia tropicalis 30%
          </option>
          <option value="ITA2 - Dermatophagoides pteronyssinus 60% + Blomia tropicalis 40%">
            ITA2 - Dermatophagoides pteronyssinus 60% + Blomia tropicalis 40%
          </option>
          <option value="ITA3 - Dermatophagoides pteronyssinus 60% + Blomia tropicalis 40%">
            ITA3 - Dermatophagoides pteronyssinus 60% + Blomia tropicalis 40%
          </option>
          <option value="ITA6 - Dermatophagoides pteronyssinus 70% + Blomia tropicalis 30%">
            ITA6 - Dermatophagoides pteronyssinus 70% + Blomia tropicalis 30%
          </option>
        </Form.Select>
      </div>
      <div className={classes.row}>
        <Form.Label className={classes.label} htmlFor="volume">
          DILUIÇÃO E VOLUME DOS FRASCOS:
        </Form.Label>
        <Form.Select
          className={classes.input_field}
          id="volume"
          value={selected_immunotherapy.dilution_volume}
          onChange={(e) =>
            dispatch(imunoActions.setDilutionVolume(e.target.value))
          }
        >
          <option value=""></option>
          <option value="1:10.000 (6mL) + 1:1.000 (6mL) + 1:100 (6mL) + 1:10 (por 3 a 5 anos). Reavaliar a partir do segundo frasco de Manutenção.">
            1:10.000 (6mL) + 1:1.000 (6mL) + 1:100 (6mL) + 1:10 (por 3 a 5
            anos). Reavaliar a partir do segundo frasco de Manutenção.
          </option>
          <option value="1:25.000 (6mL) + 1:10.000 (6mL) + 1:1.000 (6mL) + 1:100 (6mL) + 1:10 (por 3 a 5 anos). Reavaliar a partir do segundo frasco de Manutenção.">
            1:25.000 (6mL) + 1:10.000 (6mL) + 1:1.000 (6mL) + 1:100 (6mL) + 1:10
            (por 3 a 5 anos). Reavaliar a partir do segundo frasco de
            Manutenção.
          </option>
        </Form.Select>
      </div>
      <div className={classes.row}>
        <Form.Label className={classes.label} htmlFor="drops">
          Quantidade de Gotas Sublinguais:
        </Form.Label>
        <Form.Control
          type="text"
          id="drops"
          className={classes.drops}
          placeholder="Gotas"
          value={formatLeftZeros(selected_immunotherapy.sublingual_drops, 2)}
          onChange={(e) =>
            dispatch(imunoActions.setSublingualDrops(e.target.value))
          }
        ></Form.Control>
      </div>
      <div className={classes.row}>
        <div>
          <Form.Label htmlFor="city">Cidade:</Form.Label>
          <Form.Control
            type="text"
            id="city"
            placeholder="Cidade"
            value={selected_immunotherapy.city}
            onChange={(e) => dispatch(imunoActions.setCity(e.target.value))}
          ></Form.Control>
        </div>
        <DateInput
          label="Data"
          id="signature_date"
          with_date_picker={true}
          default_value={signature_date_temp}
          onChangeHandler={(value) =>
            dispatch(imunoActions.setSignatureDateTemp(value))
          }
        />
      </div>
      <div className={classes.button_container}>
        <button className="btn btn-primary my-3" type="submit">
          Gerar Laudo
        </button>
      </div>
    </Form>
  );
}
export default ImonutorapiaForm;
